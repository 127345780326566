import { CircularProgress } from '@mui/material'
import React from 'react'

const Spinner = () => {
  return (
    <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9998,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                }}
              >
                <CircularProgress
                  size={100}
                  thickness={3}
                  color="primary"
                  style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    fontSize: '18px',
                  }}
                >
                  <span className="text-white display-5">Loading...</span>
                </div>
              </div>
            </div>
          </div>
  )
}

export default Spinner
import React from 'react'
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Aside = ({showToggleSideMenu, path}) => {

    return (
        <aside className={`bg-menu-theme ${showToggleSideMenu ? 'layout-menu menu menu-vertical' : 'layout-menu-horizontal menu menu-horizontal container-fluid flex-grow-0'}`}>
            <div className="container-xxl d-flex h-100 flex-column p-0 px-4">
                <ul className="menu-inner">
                    <li className={path == '/dashboard' ? 'menu-item active' : 'menu-item'} >
                        <Nav.Link as={Link} to="/dashboard" className="menu-link">
                        <i className='bx bxs-dashboard' ></i> Dashboard 
                        </Nav.Link>
                    </li>
                    <li className={path == '/dashboard/products' ? 'menu-item active' : 'menu-item'}>
                        <Nav.Link as={Link} to="/dashboard/products" className="menu-link">
                        <i className='bx bx-tab'></i> Products
                        </Nav.Link>
                    </li>
                    <li className={path == '/dashboard/transactions' ? 'menu-item active' : 'menu-item'} >
                        <Nav.Link as={Link} to="/dashboard/transactions" className="menu-link">
                        <i className='bx bx-receipt' ></i> Transactions 
                        </Nav.Link>
                    </li>
                    <li className={path == '/dashboard/notifications' ? 'menu-item active' : 'menu-item'}>
                        <Nav.Link as={Link} to="/dashboard/notifications" className="menu-link">
                        <i className='bx bx-bell'></i> Notifications 
                        </Nav.Link>
                    </li>
                    
                    {/* <li className={path == '/dashboard/transactions/completed' ? 'menu-item active' : 'menu-item'}>
                        <Nav.Link as={Link} to="/dashboard/transactions/completed" className="menu-link">
                        <i className='bx bx-check-square'></i> Completed
                        </Nav.Link>
                    </li> */}
                    
                    
                </ul>
            </div>
        </aside>
    )
}

export default Aside
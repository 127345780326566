// import React, {useState, useMemo, useEffect} from 'react'
// import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
// import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
// import Table from '../components/Table';
// import SearchComponent from '../Transactions/SearchComponent';
// import ActionDropdownComponent from '../Transactions/ActionDropdownComponent';
// import Dialog from '../components/Dialog';
// import Badge from '../components/Badge';
// import { dateFullFormat, currencyFormat } from '../utils/utils'
// import Button from '../components/Button';
// import { BlobProvider, Document, Page } from '@react-pdf/renderer';
// import Docs from '../Transactions/Docs';
// import { toast } from 'react-toastify';
// import { useSearchParams, useNavigate } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
// import ContentComponent from '../components/ContentComponent';
// import moment from 'moment';
// import FilterTransactions from '../Transactions/FilterTransactions';
// import Spinner from '../components/Spinner';
  
// const TransactionContainer = () => {
    
//     // const data = {
//     //     email: 'skitband@yahoo.com',
//     //     orderId: 'XXXCCC123',
//     //     name: 'John Doe',
//     //     item: 'Dell',
//     //     shipment_address: 'Al Jazeera',
//     //     qty: 1,
//     //     amount: 2400,
//     //     outstandingBalance: 3000,
//     //     shipment_status: 'Delivering',
//     // }

//     let navigate = useNavigate();
//     const [searchParams] = useSearchParams();
//     const orderId = searchParams.get("orderId");
//     const [searchValue, setSearchValue] = useState('');
//     const [selectedRow, setSelectedRow] = useState([]);
//     const [showDialog, setShowDialog] = useState(false);
//     const [transactions, setTransactions] = useState([]);
//     const [serial, setSerial] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [btnLoading, setBtnLoading] = useState(false);
//     const [showSpinner, setShowSpinner] = useState(false);
//     const [shipment_status, setShipmentStatus] = useState('');
//     const [dataCounts, setDataCounts] = useState([]);
//     const [counts, setCounts] = useState([]);
//     const [filterKey, setFilterKey] = useState('');
    
//     const columns = [
//         {
//             name: 'Transaction ID',
//             selector: row => row.order_id,
//         },
//         {
//             name: 'Reference Number',
//             selector: row => row.reference_number,
//         },
//         {
//             name: 'QID',
//             selector: row => row.qid,
//         },
//         {
//             name: 'Email',
//             selector: row => row.email,
//         },
//         {
//             name: 'Mobile',
//             selector: row => row.mobile_number,
//         },
//         {
//             name: 'Amount',
//             selector: row => row.amount,
//             sortable: true,
//             cell: row => (
//                 <span style={{ textAlign: 'right' }}>
//                     {currencyFormat(row.amount)}
//                 </span>
//                 ),
//             },
//         {
//             name: 'Payment Status',
//             selector: row => row.transaction_status,
//             cell: row => {
//                 if (!row.transaction_status || row.transaction_status.trim() === '') {
//                     return <Badge variant="bg-label-warning" title="Pending" />;
//                 } else if (row.transaction_status === 'Paid') {
//                     return <Badge variant="bg-label-primary" title="Paid" />;
//                 } else if (row.transaction_status === 'Pending') {
//                     return <Badge variant="bg-label-warning" title="Pending" />;
//                 } else {
//                     return '';
//                 }
//             },
//         },
//         {
//             name: 'Payment Type',
//             selector: row => row.transaction_type,
//             cell: row => {
//                 if (!row.transaction_type || row.transaction_type.trim() === '') {
//                     return <Badge variant="bg-label-warning" title="Pending" />;
//                 } else if (row.transaction_type === 'Online Payment') {
//                     return <Badge variant="bg-label-primary" title="Online Payment" />;
//                 } else if (row.transaction_type === 'Pay in Cash') {
//                     return <Badge variant="bg-label-secondary" title="Onsite Payment" />;
//                 } else {
//                     return '';
//                 }
//             },
//         },
//         {
//             name: 'Delivery Type',
//             selector: row => row.transaction_type,
//             cell: row => {
//                 if (!row.deliveryType || row.deliveryType.trim() === '') {
//                     return <Badge variant="bg-label-warning" title="Pending" />;
//                 } else if (row.deliveryType === 'Store Pickup') {
//                     return <Badge variant="bg-label-danger" title="Store Pickup" />;
//                 } else if (row.deliveryType === 'Delivery') {
//                     return <Badge variant="bg-label-success" title="Delivery" />;
//                 } else {
//                     return '';
//                 }
//             },
//         },
//         {
//             name: 'Shipment Status',
//             selector: row => {
//                 const status = row.shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : row.shipment_status;
//                 return <span >{status.toUpperCase()}</span>;
//             },
//         },
//         {
//             name: 'Date',
//             selector: row => row.transDateTime,
//             sortable: true,
//             cell: row => dateFullFormat(row.transDateTime),
//         },
//         // {
//         //     name: '',
//         //     cell: row => <ActionDropdownComponent size="small" row={row} showDialog={setShowDialog} />,
//         //     button: true,
//         //     width: '56px',
//         // },
//     ];

//     const getTransactions = async () => {
//         try {
//             setShowSpinner(true);
//             const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetAll_transactionsc`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }}
//             )
//             if (!response.ok) {
//                 throw new Error(`An error has occured: ${response.status}`);
//             }
//             const data = await response.json();
//             const filteredData = data.filter(item => item.transaction_status !== "Failed" && item.transaction_status !== "Unpaid" && item.transaction_status.trim() !== "" && item.qid.trim() !== "12345678906");
            
//             filteredData.sort((a, b) => moment(b.transDateTime).diff(moment(a.transDateTime)));

//             let shipmentStatusFilter;

//             if (filterKey && filterKey.trim() !== '') {
//                 shipmentStatusFilter = filteredData.filter(item => item.shipment_status === filterKey || item.transaction_type === filterKey);
//             } else {
//                 shipmentStatusFilter = filteredData;
//             }

//             setTransactions(shipmentStatusFilter);
//             setDataCounts(filteredData);
//             setShowSpinner(false);
//         } catch (error) {
//             console.error(error);
//             setLoading(false);
//             setShowSpinner(false);
//         }
//     }

//     const fetchTransaction = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetTransactionsc?qid=&order_id=${orderId}`);
//             if (!response.ok) {
//                 throw new Error('Failed to fetch data');
//             }
//             const data = await response.json();
//             if (data?.length <= 0) {
                
//                 navigate('/dashboard/transactions');
//                 return
//             }
//             customRow(data[0]);
//         }catch (err) {
//             console.error(err);
//         }
//     }

//     const subHeaderComponentMemo = useMemo(() => {
// 		return (
// 			<SearchComponent onFilter={e => setSearchValue(e.target.value)} filterText={searchValue} title="" />
// 		);
// 	}, [searchValue, transactions]);

//     useEffect(() => {
//         getTransactions();
//     }, [filterKey]);

//     useEffect(() => {
//         if (orderId) {
//             fetchTransaction();
//         }
//     }, [orderId]);

//     const customRow = row => {
//         setSelectedRow(row)
//         setShowDialog(true)
//         setSerial(row.serial_no)
//         setShipmentStatus(row.shipment_status)
//         // console.log(row)
//     };

//     const handleCloseDialog = () => {
//         setShowDialog(false)
//         getTransactions()
//     };

//     const filteredTransactions = useMemo(() => {
//         let filteredData = transactions;
    
//         if (filterKey === "completed") {
//             filteredData = filteredData.filter(item => item?.shipment_status === "Tablet Received by Owner");
//         }
    
//         if (searchValue) {
//             const searchQuery = searchValue.toUpperCase();
    
//             filteredData = filteredData.filter(item => 
//                 item?.order_id?.toString().includes(searchQuery) ||
//                 item?.reference_number?.toString().includes(searchQuery) ||
//                 item?.email?.toString().includes(searchQuery) ||
//                 item?.qid?.toString().includes(searchQuery) ||
//                 item?.amount?.toString().includes(searchQuery)
//             );
//         }
    
//         return filteredData;
//     }, [transactions, filterKey, searchValue]);
    

//     const handleUpdateSerial = (value) => {
//         setSerial(value);
//     }

//     const handleChangeShipmentStatus = (value) => {
//         setShipmentStatus(value);
//         handleUpdateTransaction(value);
        
//     }

//     const handleNotifyForFullPayment = async () => {
        
//         setBtnLoading(true);
//         try {
//             const formData = new FormData();
//             formData.append('email', selectedRow['email']);
//             // formData.append('email', 'skitband@yahoo.com');
//             formData.append('orderId', selectedRow['order_id']);
//             formData.append('cname', selectedRow['name']);
//             formData.append('item', selectedRow['item']);
//             formData.append('shipment_address', selectedRow['pickup_address']);
//             formData.append('qty', selectedRow['quantity']);
//             formData.append('amount', selectedRow['amount']);
//             formData.append('TXNAMOUNT', selectedRow['amount']);
//             formData.append('outstandingBalance', selectedRow['outstandingBalance']);

//             const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/sendreminder.php`, {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             if (data['success']) {
//                 toast.success('Email Sent Successfully', {
//                     position: "top-center",
//                     autoClose: 2000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: false,
//                     draggable: false,
//                     progress: undefined,
//                 });
//             }
//         } catch (error) {
//             console.error('Error sending Notif:', error);
//         }
//         setBtnLoading(false);
//     };
    
//     const handleNotifyToSetPickupAddress = async () => {
//         setBtnLoading(true);
//         try {
//             const formData = new FormData();
//             formData.append('email', selectedRow['email']);
//             // formData.append('email', 'skitband@yahoo.com');
//             formData.append('orderId', selectedRow['orderId']);
    
//             const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/sendlocations.php`, {
//                 method: 'POST',
//                 body: formData,
//             });
//             const data = await response.json();
//             if (data['success']) {
//                 toast.success('Email Sent Successfully', {
//                     position: "top-center",
//                     autoClose: 2000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: false,
//                     draggable: false,
//                     progress: undefined,
//                 });
//             }
//         } catch (error) {
//             console.error('Error sending Notif:', error);
//         }
//         setBtnLoading(false);
//     }

//     const handleNotifyForPickup = async () => {
//         const confirmed = window.confirm('Are you sure you want to notify for pickup?');
//         // return
//         if (confirmed) {
//             setBtnLoading(true);
//             try {
//                 const formData = new FormData();
//                 formData.append('email', selectedRow['email']);
//                 formData.append('orderId', selectedRow['order_id']);
//                 formData.append('cname', selectedRow['name']);
//                 formData.append('item', selectedRow['item']);
//                 formData.append('shipment_address', selectedRow['pickup_address']);

//                 const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/sendpickup.php`, {
//                     method: 'POST',
//                     body: formData,
//                 });
//                 if(response.ok) {
//                     toast.success('Email Sent Successfully', {
//                         position: "top-center",
//                         autoClose: 2000,
//                         hideProgressBar: false,
//                         closeOnClick: true,
//                         pauseOnHover: false,
//                         draggable: false,
//                         progress: undefined,
//                     });
//                 }
//             } catch (error) {
//                 console.error('Error sending Notif:', error);
//             }
//             setBtnLoading(false);
//         }
//     }

//     const handleUpdateTransaction = async (value) => {
//         const confirmed = window.confirm('Are you sure you want to update transaction?');
        
//         if (confirmed) {
//             setShowSpinner(true);
//             const formData = [
//                 {
//                     order_id: selectedRow?.order_id,
//                     shipment_status: value,
//                 }
//             ];
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(formData),
//                 });
//                 if (response.ok) {
//                     getTransactions();
//                     // setShowDialog(false);
//                 } else {
//                     console.error('Failed to update transaction');
//                 }
//             } catch (error) {
//                 console.error('Error updating transaction:', error);
//             }
//         }
//         setShowSpinner(false);
        
//     };

//     const handleUpdateSerialTransaction = async (value) => {
//         const confirmed = window.confirm('Are you sure you want to update transaction?');
//         // console.log(value)
//         // return
//         if (confirmed) {
//             setShowSpinner(true);
//             const formData = [
//                 {
//                     order_id: selectedRow?.order_id,
//                     serial_no: value
//                 }
//             ];
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(formData),
//                 });
//                 if (response.ok) {
//                     getTransactions();
//                     // setShowDialog(false);
//                 } else {
//                     console.error('Failed to update transaction');
//                 }
//             } catch (error) {
//                 console.error('Error updating transaction:', error);
//             }
//         }
//         setShowSpinner(false);
        
//     };

//     const handleUpdateInvoiceNoTransaction = async (value) => {
//         const confirmed = window.confirm('Are you sure you want to update transaction?');
//         // console.log(value)
//         // return
//         if (confirmed) {
//             setShowSpinner(true);
//             const formData = [
//                 {
//                     order_id: selectedRow?.order_id,
//                     notes: value
//                 }
//             ];
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(formData),
//                 });
//                 if (response.ok) {
//                     getTransactions();
//                     // setShowDialog(false);
//                 } else {
//                     console.error('Failed to update transaction');
//                 }
//             } catch (error) {
//                 console.error('Error updating transaction:', error);
//             }
//         }
//         setShowSpinner(false);
        
//     };

//     const convertArrayOfObjectsToCSV = array => {
//         let result;
    
//         const columnDelimiter = ',';
//         const lineDelimiter = '\n';
//         const keys = Object.keys(filteredTransactions[0]);
    
//         result = '';
//         result += keys.join(columnDelimiter);
//         result += lineDelimiter;
    
//         array.forEach(item => {
//             let ctr = 0;
//             keys.forEach(key => {
//                 if (ctr > 0) result += columnDelimiter;
    
//                 result += item[key];
                
//                 ctr++;
//             });
//             result += lineDelimiter;
//         });
    
//         return result;
//     }

//     const downloadCSV = filteredTransactions => {
//         const link = document.createElement('a');
//         let csv = convertArrayOfObjectsToCSV(filteredTransactions);
//         if (csv == null) return;
    
//         const filename = 'transactions.csv';
    
//         if (!csv.match(/^data:text\/csv/i)) {
//             csv = `data:text/csv;charset=utf-8,${csv}`;
//         }
    
//         link.setAttribute('href', encodeURI(csv));
//         link.setAttribute('download', filename);
//         link.click();
//     }

//     const handleFilter = (filter) => {
//         setFilterKey(filter);
//     }

//     useMemo(() => {
//         const receivedCount = dataCounts.filter(transaction => transaction.shipment_status === 'Order Received').length;
//         const preparingCount = dataCounts.filter(transaction => transaction.shipment_status === 'Preparing Item').length;
//         const deliveringCount = dataCounts.filter(transaction => transaction.shipment_status === 'Delivering').length;
//         const completedCount = dataCounts.filter(transaction => transaction.shipment_status === 'Tablet Received by Owner').length;
//         const onsiteCount = dataCounts.filter(transaction => transaction.transaction_type === 'Pay in Cash').length;
//         const onlineCount = dataCounts.filter(transaction => transaction.transaction_type === 'Online Payment').length;
//         const completedStorePU = dataCounts.filter(transaction => transaction.shipment_status === 'Picked up').length;
//         setCounts([receivedCount, preparingCount, deliveringCount, completedCount, onsiteCount, onlineCount, completedStorePU]);
//     }, [dataCounts]);

//     const handleSetPickupAddress = async (value) => {
//         const confirmed = window.confirm('Are you sure you want to update pickup address?');
//         // console.log(value)
//         // return
//         if (confirmed) {
//             const formData = [
//                 {
//                     order_id: selectedRow?.order_id,
//                     pickup_address: value
//                 }
//             ];
    
//             try {
//                 const response = await fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(formData),
//                 });
//                 if (response.ok) {
//                     getTransactions();
//                     // console.log(response);
//                 } else {
//                     console.error('Failed to update transaction');
//                 }
//             } catch (error) {
//                 console.error('Error updating transaction:', error);
//             }
//         }
//     }
    

//     return (
//         <>
//         {showSpinner && (
//           <Spinner />
//         )}
//         <section className="section-py">
//             <div className="card">
//                 <div className="card-header d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
//                     <div className="ms-n2">
//                         <h4 className="card-title mb-0">Transactions</h4>
//                     </div>
                    
//                     <div className="d-flex align-items-md-center justify-content-md-end mt-2 mt-md-0">
//                         <div className="dt-action-buttons pt-0 w-100">
//                             <div className="dt-buttons btn-group flex-wrap">
//                                 <div className="btn-group">
//                                 <Button
//                                     customclass="btn btn-label-primary w-auto" 
//                                     title={<span>
//                                         Export to excel <i className="bx bx-import ms-2" />
//                                     </span>}
//                                     action={() => downloadCSV(filteredTransactions)}
//                                 />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
                    
//                 </div>
//                 <FilterTransactions
//                     counts={counts}
//                     onFilter={handleFilter}
//                     filterKey={filterKey}
//                 />
//                 <div className="dataTables_wrapper dt-bootstrap5 no-footer px-4">
//                     <Table 
//                         title="Transactions List" 
//                         cols={columns}
//                         data={filteredTransactions} 
//                         classN={"dt-transactions"}
//                         subHeader
//                         subHeaderComponent={subHeaderComponentMemo}
//                         customRow={customRow}
//                         isLoading={loading}
//                     />
//                 </div>
//             </div>
//         </section>
//         {showDialog && <Dialog 
//             show={showDialog} 
//             setShow={handleCloseDialog} 
//             title="Other Details" 
//             data={selectedRow} 
//             content={
//                 <ContentComponent 
//                     data={selectedRow}
//                     serial={selectedRow?.serial_no}
//                     setSerialNo={handleUpdateSerial}
//                     updateSerialTransaction={handleUpdateSerialTransaction}
//                     updateTransaction={handleUpdateTransaction}
//                     isBtnLoading={btnLoading}
//                     notifyForFullPayment={handleNotifyForFullPayment}
//                     notifyForPickup={handleNotifyForPickup}
//                     notifyToSetPickupAddress={handleNotifyToSetPickupAddress}
//                     shipment_status={shipment_status}
//                     setShipmentStatus={handleChangeShipmentStatus}
//                     invoiceNo={selectedRow?.notes}
//                     updateInvoiceNoTransaction={handleUpdateInvoiceNoTransaction}
//                     filterKey={filterKey}
//                     setPickupAddress={handleSetPickupAddress}
//                 />} 
//             />}
//         </>
//     )
// }

// export default TransactionContainer



import React, {useState, useMemo, useEffect} from 'react'
import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
import Table from '../components/Table';
import SearchComponent from '../Transactions/SearchComponent';
import ActionDropdownComponent from '../Transactions/ActionDropdownComponent';
import Dialog from '../components/Dialog';
import Badge from '../components/Badge';
import { dateFullFormat, currencyFormat } from '../utils/utils'
import Button from '../components/Button';
import { BlobProvider, Document, Page } from '@react-pdf/renderer';
import Docs from '../Transactions/Docs';
import { toast } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ContentComponent from '../components/ContentComponent';
import moment from 'moment';
import FilterTransactions from '../Transactions/FilterTransactions';
import Spinner from '../components/Spinner';
  
const TransactionContainer = () => {
    
    // const data = {
    //     email: 'skitband@yahoo.com',
    //     orderId: 'XXXCCC123',
    //     name: 'John Doe',
    //     item: 'Dell',
    //     shipment_address: 'Al Jazeera',
    //     qty: 1,
    //     amount: 2400,
    //     outstandingBalance: 3000,
    //     shipment_status: 'Delivering',
    // }

    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    const [searchValue, setSearchValue] = useState('');
    const [selectedRow, setSelectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [serial, setSerial] = useState('');
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [shipment_status, setShipmentStatus] = useState('');
    const [dataCounts, setDataCounts] = useState([]);
    const [counts, setCounts] = useState([]);
    const [filterKey, setFilterKey] = useState('');
    
    const columns = [
        {
            name: 'Transaction ID',
            selector: row => row.order_id,
        },
        {
            name: 'Reference Number',
            selector: row => row.reference_number,
        },
        {
            name: 'QID',
            selector: row => row.qid,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile_number,
        },
        {
            name: 'Amount',
            selector: row => row.total_amount,
            sortable: true,
            cell: row => (
                <span style={{ textAlign: 'right' }}>
                    {currencyFormat(row.total_amount)}
                </span>
                ),
            },
        {
            name: 'Payment Status',
            selector: row => row.transaction_status,
            cell: row => {
                if (!row.transaction_status || row.transaction_status.trim() === '') {
                    return <Badge variant="bg-label-warning" title="Pending" />;
                } else if (row.transaction_status === 'Paid') {
                    return <Badge variant="bg-label-primary" title="Paid" />;
                } else if (row.transaction_status === 'Pending') {
                    return <Badge variant="bg-label-warning" title="Pending" />;
                } else {
                    return '';
                }
            },
        },
        {
            name: 'Payment Type',
            selector: row => row.transaction_type,
            cell: row => {
                if (!row.transaction_type || row.transaction_type.trim() === '') {
                    return <Badge variant="bg-label-warning" title="Pending" />;
                } else if (row.transaction_type === 'Online Payment') {
                    return <Badge variant="bg-label-primary" title="Online Payment" />;
                } else if (row.transaction_type === 'Pay in Cash') {
                    return <Badge variant="bg-label-secondary" title="Onsite Payment" />;
                } else {
                    return '';
                }
            },
        },
        {
            name: 'Delivery Type',
            selector: row => row.transaction_type,
            cell: row => {
                if (!row.deliveryType || row.deliveryType.trim() === '') {
                    return <Badge variant="bg-label-warning" title="Pending" />;
                } else if (row.deliveryType === 'Store Pickup') {
                    return <Badge variant="bg-label-danger" title="Store Pickup" />;
                } else if (row.deliveryType === 'Delivery') {
                    return <Badge variant="bg-label-success" title="Delivery" />;
                } else {
                    return '';
                }
            },
        },
        // {
        //     name: 'Shipment Status',
        //     selector: row => {
        //         const status = row.shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : row.shipment_status;
        //         return <span >{status.toUpperCase()}</span>;
        //     },
        // },
        {
            name: 'Shipment Status',
            selector: row => {
                let status;
                if (!row.shipment_status) {
                    status = 'Status Unknown';
                } else if (row.shipment_status === 'Tablet Received by Owner') {
                    status = 'Package Received by Owner';
                } else {
                    status = row.shipment_status;
                }
                return <span>{status.toUpperCase()}</span>;
            },
        },        
        {
            name: 'Date',
            selector: row => row.transDateTime,
            sortable: true,
            cell: row => dateFullFormat(row.transDateTime),
        },
        // {
        //     name: '',
        //     cell: row => <ActionDropdownComponent size="small" row={row} showDialog={setShowDialog} />,
        //     button: true,
        //     width: '56px',
        // },
    ];

    const getTransactions = async () => {
        try {
            setShowSpinner(true);
            const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetCarabia_transactions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }}
            )
            if (!response.ok) {
                throw new Error(`An error has occured: ${response.status}`);
            }
            const data = await response.json();
            const filteredData = data.filter(item => item.transaction_status !== "Failed" && item.transaction_status !== "Unpaid" && item.transaction_status.trim() !== "" && item.qid.trim() !== "12345678906");
            
            filteredData.sort((a, b) => moment(b.transDateTime).diff(moment(a.transDateTime)));

            let shipmentStatusFilter;

            if (filterKey && filterKey.trim() !== '') {
                shipmentStatusFilter = filteredData.filter(item => item.shipment_status === filterKey || item.transaction_type === filterKey);
            } else {
                shipmentStatusFilter = filteredData;
            }

            setTransactions(shipmentStatusFilter);
            setDataCounts(filteredData);
            setShowSpinner(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setShowSpinner(false);
        }
    }

    const fetchTransaction = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetTransactionsc?qid=&order_id=${orderId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            if (data?.length <= 0) {
                
                navigate('/dashboard/transactions');
                return
            }
            customRow(data[0]);
        }catch (err) {
            console.error(err);
        }
    }

    const subHeaderComponentMemo = useMemo(() => {
		return (
			<SearchComponent onFilter={e => setSearchValue(e.target.value)} filterText={searchValue} title="" />
		);
	}, [searchValue, transactions]);

    useEffect(() => {
        getTransactions();
    }, [filterKey]);

    useEffect(() => {
        if (orderId) {
            fetchTransaction();
        }
    }, [orderId]);

    const customRow = row => {
        setSelectedRow(row)
        setShowDialog(true)
        setSerial(row.serial_no)
        setShipmentStatus(row.shipment_status)
        // console.log(row)
    };

    const handleCloseDialog = () => {
        setShowDialog(false)
        getTransactions()
    };

    const filteredTransactions = useMemo(() => {
        let filteredData = transactions;
    
        if (filterKey === "completed") {
            filteredData = filteredData.filter(item => item?.shipment_status === "Tablet Received by Owner");
        }
    
        if (searchValue) {
            const searchQuery = searchValue.toUpperCase();
    
            filteredData = filteredData.filter(item => 
                item?.order_id?.toString().includes(searchQuery) ||
                item?.reference_number?.toString().includes(searchQuery) ||
                item?.email?.toString().includes(searchQuery) ||
                item?.qid?.toString().includes(searchQuery) ||
                item?.amount?.toString().includes(searchQuery)
            );
        }
    
        return filteredData;
    }, [transactions, filterKey, searchValue]);
    

    const handleUpdateSerial = (value) => {
        setSerial(value);
    }

    const handleChangeShipmentStatus = (value) => {
        setShipmentStatus(value);
        handleUpdateTransaction(value);
        
    }

    const handleNotifyForFullPayment = async () => {
        
        setBtnLoading(true);
        try {
            const formData = new FormData();
            formData.append('email', selectedRow['email']);
            // formData.append('email', 'skitband@yahoo.com');
            formData.append('orderId', selectedRow['order_id']);
            formData.append('cname', selectedRow['name']);
            formData.append('item', selectedRow['item']);
            formData.append('shipment_address', selectedRow['pickup_address']);
            formData.append('qty', selectedRow['quantity']);
            formData.append('amount', selectedRow['amount']);
            formData.append('TXNAMOUNT', selectedRow['amount']);
            formData.append('outstandingBalance', selectedRow['outstandingBalance']);

            const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/sendreminder.php`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data['success']) {
                toast.success('Email Sent Successfully', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error sending Notif:', error);
        }
        setBtnLoading(false);
    };
    
    const handleNotifyToSetPickupAddress = async () => {
        setBtnLoading(true);
        try {
            const formData = new FormData();
            formData.append('email', selectedRow['email']);
            // formData.append('email', 'skitband@yahoo.com');
            formData.append('orderId', selectedRow['orderId']);
    
            const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/sendlocations.php`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data['success']) {
                toast.success('Email Sent Successfully', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error sending Notif:', error);
        }
        setBtnLoading(false);
    }

    const handleNotifyForPickup = async () => {
        const confirmed = window.confirm('Are you sure you want to notify for pickup?');
        // return
        if (confirmed) {
            setBtnLoading(true);
            try {
                const formData = new FormData();
                formData.append('email', selectedRow['email']);
                formData.append('orderId', selectedRow['order_id']);
                formData.append('cname', selectedRow['name']);
                formData.append('item', selectedRow['item']);
                formData.append('shipment_address', selectedRow['pickup_address']);

                const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/sendpickup.php`, {
                    method: 'POST',
                    body: formData,
                });
                if(response.ok) {
                    toast.success('Email Sent Successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                }
            } catch (error) {
                console.error('Error sending Notif:', error);
            }
            setBtnLoading(false);
        }
    }

    const handleUpdateTransaction = async (value) => {
        const confirmed = window.confirm('Are you sure you want to update transaction?');
        
        if (confirmed) {
            setShowSpinner(true);
            const formData = [
                {
                    order_id: selectedRow?.order_id,
                    shipment_status: value,
                }
            ];
            try {
                const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    getTransactions();
                    // setShowDialog(false);
                } else {
                    console.error('Failed to update transaction');
                }
            } catch (error) {
                console.error('Error updating transaction:', error);
            }
        }
        setShowSpinner(false);
        
    };

    // const handleUpdateSerialTransaction = async (value) => {
    //     const confirmed = window.confirm('Are you sure you want to update transaction?');
    //     // console.log(value)
    //     // return
    //     if (confirmed) {
    //         setShowSpinner(true);
    //         const formData = [
    //             {
    //                 order_id: selectedRow?.order_id,
    //                 serial_no: value
    //             }
    //         ];
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify(formData),
    //             });
    //             if (response.ok) {
    //                 getTransactions();
    //                 // setShowDialog(false);
    //             } else {
    //                 console.error('Failed to update transaction');
    //             }
    //         } catch (error) {
    //             console.error('Error updating transaction:', error);
    //         }
    //     }
    //     setShowSpinner(false);
        
    // };
    const handleUpdateSerialTransaction = async (value, itemId) => {
        const confirmed = window.confirm('Are you sure you want to update transaction?');
        if (!confirmed) return;
    
        setShowSpinner(true);
    
        const formData = [{
            order_id: selectedRow?.order_id,
            items: [
                {
                    itemId: itemId,
                    serial_no: value
                }
            ]
        }];
    
        try {
            const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                getTransactions();
            } else {
                console.error('Failed to update transaction');
            }
        } catch (error) {
            console.error('Error updating transaction:', error);
        }
    
        setShowSpinner(false);
    };    

    const handleUpdateInvoiceNoTransaction = async (value) => {
        const confirmed = window.confirm('Are you sure you want to update transaction?');
        // console.log(value)
        // return
        if (confirmed) {
            setShowSpinner(true);
            const formData = [
                {
                    order_id: selectedRow?.order_id,
                    notes: value
                }
            ];
            try {
                const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    getTransactions();
                    // setShowDialog(false);
                } else {
                    console.error('Failed to update transaction');
                }
            } catch (error) {
                console.error('Error updating transaction:', error);
            }
        }
        setShowSpinner(false);
        
    };

    const convertArrayOfObjectsToCSV = array => {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredTransactions[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }

    const downloadCSV = filteredTransactions => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(filteredTransactions);
        if (csv == null) return;
    
        const filename = 'transactions.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const handleFilter = (filter) => {
        setFilterKey(filter);
    }

    useMemo(() => {
        const receivedCount = dataCounts.filter(transaction => transaction.shipment_status === 'Order Received').length;
        const preparingCount = dataCounts.filter(transaction => transaction.shipment_status === 'Preparing Item').length;
        const deliveringCount = dataCounts.filter(transaction => transaction.shipment_status === 'Delivering').length;
        const completedCount = dataCounts.filter(transaction => transaction.shipment_status === 'Tablet Received by Owner').length;
        const onsiteCount = dataCounts.filter(transaction => transaction.transaction_type === 'Pay in Cash').length;
        const onlineCount = dataCounts.filter(transaction => transaction.transaction_type === 'Online Payment').length;
        const completedStorePU = dataCounts.filter(transaction => transaction.shipment_status === 'Picked up').length;
        setCounts([receivedCount, preparingCount, deliveringCount, completedCount, onsiteCount, onlineCount, completedStorePU]);
    }, [dataCounts]);

    const handleSetPickupAddress = async (value) => {
        const confirmed = window.confirm('Are you sure you want to update pickup address?');
        // console.log(value)
        // return
        if (confirmed) {
            const formData = [
                {
                    order_id: selectedRow?.order_id,
                    pickup_address: value
                }
            ];
    
            try {
                const response = await fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    getTransactions();
                    // console.log(response);
                } else {
                    console.error('Failed to update transaction');
                }
            } catch (error) {
                console.error('Error updating transaction:', error);
            }
        }
    }
    

    return (
        <>
        {showSpinner && (
          <Spinner />
        )}
        <section className="section-py">
            <div className="card">
                <div className="card-header d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                    <div className="ms-n2">
                        <h4 className="card-title mb-0">Transactions</h4>
                    </div>
                    
                    <div className="d-flex align-items-md-center justify-content-md-end mt-2 mt-md-0">
                        <div className="dt-action-buttons pt-0 w-100">
                            <div className="dt-buttons btn-group flex-wrap">
                                <div className="btn-group">
                                <Button
                                    customclass="btn btn-label-primary w-auto" 
                                    title={<span>
                                        Export to excel <i className="bx bx-import ms-2" />
                                    </span>}
                                    action={() => downloadCSV(filteredTransactions)}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <FilterTransactions
                    counts={counts}
                    onFilter={handleFilter}
                    filterKey={filterKey}
                />
                <div className="dataTables_wrapper dt-bootstrap5 no-footer px-4">
                    <Table 
                        title="Transactions List" 
                        cols={columns}
                        data={filteredTransactions} 
                        classN={"dt-transactions"}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        customRow={customRow}
                        isLoading={loading}
                    />
                </div>
            </div>
        </section>
        {showDialog && <Dialog 
            show={showDialog} 
            setShow={handleCloseDialog} 
            title="Other Details" 
            data={selectedRow} 
            content={
                <ContentComponent 
                    data={selectedRow}
                    serial={selectedRow?.serial_no}
                    setSerialNo={handleUpdateSerial}
                    updateSerialTransaction={handleUpdateSerialTransaction}
                    updateTransaction={handleUpdateTransaction}
                    isBtnLoading={btnLoading}
                    notifyForFullPayment={handleNotifyForFullPayment}
                    notifyForPickup={handleNotifyForPickup}
                    notifyToSetPickupAddress={handleNotifyToSetPickupAddress}
                    shipment_status={shipment_status}
                    setShipmentStatus={handleChangeShipmentStatus}
                    invoiceNo={selectedRow?.notes}
                    updateInvoiceNoTransaction={handleUpdateInvoiceNoTransaction}
                    filterKey={filterKey}
                    setPickupAddress={handleSetPickupAddress}
                />} 
            />}
        </>
    )
}

export default TransactionContainer
import React, {useState, useMemo, useEffect} from 'react'
import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
import Table from '../components/Table';
import { dateTimeFullFormat } from '../utils/utils'
import CircularProgress from '@mui/material/CircularProgress';
import FilterComponent from '../Transactions/SearchComponent';
import Filters from '../Notifications/Filters';
import moment from 'moment';
import Spinner from '../components/Spinner';

const NotificationsContainer = () => {

    const [searchValue, setSearchValue] = useState('');
    const [dataCounts, setDataCounts] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [filterKey, setFilterKey] = useState('');
    const [counts, setCounts] = useState([]);

    const columns = [
        
        {
            name: 'Order',
            selector: row => row.orderId,
        },
        {
            name: 'Type',
            selector: row => row.notifType,
            cell: row => row.notifType != null ? row.notifType : 'new order' ,
        },
        {
            name: 'Title',
            selector: row => row.title,
        },
       
        {
            name: 'Date',
            selector: row => row.created_at,
            sortable: true,
            cell: row => dateTimeFullFormat(row.created_at),
        },
    ];

    // const getNotifications = async () => {
      
    //     try {
    //         setShowSpinner(true);
    //         const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/notifications.php`);
    //         if (!response.ok) {
    //             throw new Error('Failed to fetch data');
    //         }
    //         const data = await response.json();
    //         const sortData = data.data.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
    //         let filteredData;
    //         if (filterKey && filterKey.trim() !== '') {
    //             filteredData = sortData.filter(item => item.notifType === filterKey);
    //         } else {
    //             filteredData = sortData.filter(item => item.notifType !== 'forpickup');
    //         }
    //         setDataCounts(sortData);
    //         setTransactions(filteredData);
    //         setShowSpinner(false);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setShowSpinner(false);
    //     }
    // };

    const getNotifications = async () => {
        try {
            setShowSpinner(true);
            const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/notifications.php`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const sortData = data.data.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
            
            // Remove duplicate order IDs
            const uniqueOrderIds = [];
            const filteredData = sortData.filter(item => {
                if (!uniqueOrderIds.includes(item.orderId)) {
                    uniqueOrderIds.push(item.orderId);
                    return true;
                }
                return false;
            });
    
            let notificationsData;
            if (filterKey && filterKey.trim() !== '') {
                notificationsData = filteredData.filter(item => item.notifType === filterKey);
            } else {
                notificationsData = filteredData.filter(item => item.notifType !== 'forpickup');
            }
            setDataCounts(sortData);
            setTransactions(notificationsData);
            setShowSpinner(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setShowSpinner(false);
        }
    };

    useEffect(() => {
      getNotifications();
    }, [filterKey]);

    const filteredTransactions = transactions?.filter((item) => {
        if (item?.orderId?.toString().includes(searchValue.toUpperCase())) {
            return item;
        }
    })

    const subHeaderComponentMemo = useMemo(() => {
		return (
			<FilterComponent onFilter={e => setSearchValue(e.target.value)} filterText={searchValue} title="" />
		);
	}, [searchValue, transactions]);

    const handleFilter = (filter) => {
        setFilterKey(filter);
    }

    // useMemo(() => {
    //   const emptyCount = dataCounts.filter(transaction => !transaction.notifType).length;
    //   const forPickupCount = dataCounts.filter(transaction => transaction.notifType === 'forpickup').length;
    //   setCounts([emptyCount, forPickupCount]);
    // }, [dataCounts]);
    useMemo(() => {
        // Filter out duplicate order IDs
        const uniqueOrders = new Set(dataCounts.map(transaction => transaction.orderId));
        const uniqueOrderCount = uniqueOrders.size;
    
        const emptyCount = dataCounts.filter(transaction => !transaction.notifType).length;
        const forPickupCount = dataCounts.filter(transaction => transaction.notifType === 'forpickup').length;
        setCounts([uniqueOrderCount, forPickupCount]);
    }, [dataCounts]);
    

    return (
        <>
        {showSpinner && (
          <Spinner />
        )}
        <section className="section-py">
            <div className="card">
                <div className="card-header">
                    <h4>Notifications</h4>
                </div>
                <Filters 
                  counts={counts}
                  onFilter={handleFilter}
                  filterKey={filterKey}
                />
                <div className="dataTables_wrapper dt-bootstrap5 no-footer px-4">
                    <Table 
                        title="Transactions List" 
                        cols={columns}
                        data={filteredTransactions} 
                        classN={"dt-transactions"}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        isLoading={loading}
                    />
                </div>
            </div>
        </section>
        </>
    )
}

export default NotificationsContainer
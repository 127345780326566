import * as Yup from 'yup';

const MAX_FILE_SIZE = 1024000;

export const validationSchemaRegister = Yup.object().shape({
  qid: Yup.string()
    .length(11, 'QID number must be exactly 11 characters')
    .required('QID number is required'),
  cname: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  deliveryAddress: Yup.string().required('Delivery address is required'),
  mobile: Yup.string()
    .matches(/^\d{8}$/, 'Mobile number must be exactly 8 digits')
    .required('Mobile number is required'),
});


export const validationSchemaProduct = Yup.object().shape({
  id: Yup.string().required('Product ID is required'),
  name: Yup.string().required('Name is required'),
  price: Yup.number().required('Price is required'),
  desc: Yup.string().required('Description is required'),
  qty: Yup.number().required('Stocks is required'),
  category: Yup.string().required('Category is required'),
  specs: Yup.string(),
  package: Yup.string(),
  inclusion: Yup.string(),
  warranty: Yup.string(),
  // image: Yup.mixed()
  //   .test('fileSize', 'File size is too large (max 2MB)', (value) => {
  //   if (value) {
  //     console.log(value.size)
  //       return value && value.size <= MAX_FILE_SIZE;
  //   }
  //   return true;
  // }),
});
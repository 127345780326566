import React from 'react'

const Button = (props) => {

    const { title, icon, action, customclass, isDisabled, isLoading = false, type } = props

    return (
        <>
            {isLoading ? <button className={customclass} type={type} disabled={isDisabled}>
                <span className="spinner-border me-1" role="status" aria-hidden="true"></span>
                Loading...
            </button> : <button type={type} onClick={action} disabled={isDisabled} className={customclass}><i className={icon}></i> {title}</button>}
        </>
    )
}

export default Button
import React from 'react'
import man from '../assets/illustrations/man-with-laptop-light.png'
import { Link } from 'react-router-dom'

export const Welcome = ({accountsData, counts}) => {
    // console.log(accountsData)
    const name = accountsData && accountsData.length > 0 ? accountsData.name : '';
  return (
    <div className="card">
        <div className="d-flex align-items-end row">
        <div className="col-sm-7">
            <div className="card-body">
            <h5 className="card-title text-primary">
                Welcome {accountsData.name} 🎉
            </h5>
            <p className="mb-4">
                You have <span className="fw-medium">+{counts}</span> more sales
                today. Check your transactions table now.
            </p>
            <Link to="/dashboard/transactions" className="btn btn-sm btn-label-primary">
                View All Transactions
            </Link>
            </div>
        </div>
        <div className="col-sm-5 text-center text-sm-left">
            <div className="card-body pb-0 px-0 px-md-4">
            <img
                src={man}
                height={140}
                alt="View Badge User"
                data-app-dark-img="illustrations/man-with-laptop-dark.png"
                data-app-light-img="illustrations/man-with-laptop-light.png"
            />
            </div>
        </div>
        </div>
    </div>
  )
}
import React, { useState, useMemo, useEffect } from 'react';
import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
import productImg from '../assets/products/default.png';
import Table from '../components/Table';
import FilterComponent from '../Transactions/SearchComponent';
import Dialog from '../components/Dialog';
import { dateFullFormat, currencyFormat } from '../utils/utils';
import { toast } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ProductComponent from '../components/ProductComponent';
import { useFormik } from 'formik';
import { validationSchemaProduct } from '../utils/FormValidationSchema';
import Spinner from '../components/Spinner';
import moment from 'moment';

const ProductsContainer = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId');
    const [searchValue, setSearchValue] = useState('');
    const [selectedRow, setSelectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [productQty, setProductQty] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [showProductDialog, setShowProductDialog] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            width: '50px',
            sortable: true,
        },
        {
            name: 'Image',
            selector: (row) => row.image,
            cell: (row) => {
                return <img src={row.image === '' ? productImg : row.image} className="w-px-100" />;
            },
            width: '120px',
        },
        {
            name: 'Name',
            selector: (row) => row.Name,
            width: '500px',
        },
        {
            name: 'Price',
            selector: (row) => row.price,
            sortable: true,
            cell: (row) => <span style={{ textAlign: 'right' }}>{currencyFormat(row.price)}</span>,
        },
        {
            name: 'Quantity',
            selector: (row) => row.qty,
            cell: (row) => {
                return <span className="text-center">{row.qty}</span>;
            },
        },
        {
            name: 'Category',
            selector: (row) => row.category === 'Accessories' ? 'Laptop' : row.category,
        },          
        {
            name: 'Date Added',
            selector: (row) => moment(row.createdOn).format('YYYY-MM-DD'),
        },
    ];

    const handleCloseDialog = () => {
        setShowDialog(false);
        getProducts();
    };

    const handleCloseProductDialog = () => {
        setShowProductDialog(false);
        getProducts();
    };

    const getProducts = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetAll_itemc`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error(`An error has occured: ${response.status}`);
            }
            const data = await response.json();
            const jsonData = data.filter((item) => item.id !== '' && item.id !== null);
            jsonData.sort((a, b) => moment(b.createdOn).diff(moment(a.createdOn)));
            setProducts(jsonData);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const filteredProducts = useMemo(() => {
        let filteredData = products;

        if (searchValue) {
            const searchQuery = searchValue.toLowerCase();

            filteredData = filteredData.filter((item) => item?.Name?.toLowerCase().includes(searchQuery));
        }

        return filteredData;
    }, [products, searchValue]);

    const subHeaderComponentMemo = useMemo(() => {
        return <FilterComponent onFilter={(e) => setSearchValue(e.target.value)} filterText={searchValue} title="" />;
    }, [searchValue, products]);

    const customRow = (row) => {
        setSelectedRow(row);
        setShowDialog(true);
    };

    useEffect(() => {
        getProducts();
    }, []);

    const handleUpdateProductQty = (val) => {
        setProductQty(val);
    };

    const handleUpdateProductPrice = (val) => {
        setProductPrice(val);
    };

    const handleUpdateProductImage = async (id, imageBase64) => {
        try {
            const formData = [{ id: id, image: imageBase64 }];
            const response = await fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/update_itemImagec', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                toast.success('Product Image Updated Successfully', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                getProducts();
            } else {
                console.error('Failed to update product image');
            }
        } catch (error) {
            console.error('Error updating product image:', error);
        }
    };

    const handleUpdateProductSpecs = async (id, specs) => {
        try {
            const formData = [{ id: id, specs: specs }];
            const response = await fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/update_itemSpecc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                toast.success('Product Specifications Updated Successfully', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                getProducts();
            } else {
                console.error('Failed to update product specifications');
            }
        } catch (error) {
            console.error('Error updating product specifications:', error);
        }
    };

    const handleUpdateProduct = async (flag) => {
        const confirmed = window.confirm('Are you sure you want to update product?');
        if (confirmed) {
            setShowSpinner(true);
            setBtnLoading(true);

            try {
                if (flag === 'prc') {
                    var formData = [
                        {
                            Id: selectedRow?.id,
                            price: productPrice,
                        },
                    ];
                    var url = `${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_itempricec`;
                } else {
                    var formData = [
                        {
                            Id: selectedRow?.id,
                            qty: productQty,
                        },
                    ];
                    var url = `${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_itemQtyc`;
                }
                // return
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    toast.success('Product Update Successfully', {
                        position: 'top-center',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                    });
                    getProducts();
                } else {
                    console.error('Failed to update transaction');
                }
            } catch (error) {
                console.error('Error updating transaction:', error);
                setBtnLoading(false);
                setShowSpinner(false);
            }
        }
        setShowSpinner(false);
        setBtnLoading(false);
    };

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            image: '',
            price: '',
            desc: '',
            qty: '',
            category: '',
            specs: '',
            package: '',
            inclusion: '',
            warranty: '',
        },
        validationSchema: validationSchemaProduct,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setShowSpinner(true);
            try {
                const formData = {
                    id: values.id,
                    name: values.name,
                    image: values.image,
                    price: parseFloat(values.price),
                    desc: values.desc,
                    originalPrice: parseFloat(values.price),
                    qty: parseInt(values.qty),
                    category: values.category,
                    specs: values.specs,
                    Package: values.package,
                    Inclusion: values.inclusion,
                    Warranty: values.warranty,
                };

                const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/insertItemc`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify([formData]),
                });

                if (!response.ok) {
                    setSubmitting(false);
                    setShowSpinner(false);
                    throw new Error('Failed to submit form data');
                }
                toast.success('Product Added Successfully', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                });
                setShowProductDialog(false);
                setShowSpinner(false);
                getProducts();
            } catch (error) {
                console.error('Error submitting form data:', error.message);
                setSubmitting(false);
                setShowSpinner(false);
            }
            setSubmitting(false);
            setShowSpinner(false);
        },
    });

    return (
        <>
            {showSpinner && <Spinner />}
            <section className="section-py">
                <div className="card">
                    <div className="card-header flex-column flex-row">
                        <div className="head-label">
                            <h4 className="card-title mb-0">Products</h4>
                        </div>
                        <div className="dt-action-buttons text-end pt-3">
                            <div className="dt-buttons btn-group flex-wrap">
                                <button
                                    className="btn btn-secondary btn-primary"
                                    type="button"
                                    onClick={() => setShowProductDialog(true)}
                                >
                                    <span>
                                        <i className="bx bx-plus me-sm-1" />
                                        <span className="d-none d-sm-inline-block">Add New Product</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="dataTables_wrapper dt-bootstrap5 no-footer px-4">
                        <Table
                            title="Products List"
                            cols={columns}
                            data={filteredProducts}
                            classN={'dt-products'}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            customRow={customRow}
                            isLoading={loading}
                        />
                    </div>
                </div>
            </section>
            {showDialog && (
                <Dialog
                    show={showDialog}
                    setShow={handleCloseDialog}
                    data={selectedRow}
                    content={
                        <ProductComponent
                            data={selectedRow}
                            updateProduct={handleUpdateProduct}
                            updateProductImage={handleUpdateProductImage}
                            updateProductSpecs={handleUpdateProductSpecs}
                            isBtnLoading={btnLoading}
                            updateProductQty={handleUpdateProductQty}
                            updateProductPrice={handleUpdateProductPrice}
                            formik={formik} 
                        />
                    }
                />
            )}
            {showProductDialog && (
                <Dialog
                    show={showProductDialog}
                    setShow={handleCloseProductDialog}
                    content={<ProductComponent isAddProduct={true} formik={formik} />}
                />
            )}
        </>
    );
};

export default ProductsContainer;

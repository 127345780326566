import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Nav, Offcanvas, Modal, Button } from 'react-bootstrap';
import companyLogo from './assets/newcalogo.jpg';
import dell from './assets/products/dell.jpg';
import keyboard from './assets/products/keyboard.png';
import mslogo from './assets/microsoft.svg';
import pen from './assets/products/pen.png';
import AddtoCart from './components/AddtoCart';
import Checkout from './components/Checkout';
import Products from './components/Products';
import { useMsal } from '@azure/msal-react';
import TrackOrders from './components/TrackOrders';
import NotFound from './components/NotFound';
import ThankYou from './components/ThankYou';
import ThankYouOffline from './components/ThankYouOffline';
import Footer from './components/Footer';
import Faqs from './components/Faqs';
import SignIn from './components/SignIn';
import './Product.css';
import { ReactComponent as NavbarBackground } from './assets/Cloudys.svg';
import translations from './translations/translations.json';
import qatarFlag from './assets/qatar.png'; // Import the Qatar flag image
import usFlag from './assets/us.png'; // Import the US flag image
import VerifyStaffIDModal from './components/VerifyStaffIDModal'; // Import the modal

const ProductsCatalog = () => {
  const [language, setLanguage] = useState('en');
  const { ar: arTranslations, en: enTranslations } = translations;

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  let navigate = useNavigate();
  let location = useLocation();
  const { instance } = useMsal();

  const [showModal, setShowModal] = useState(true); // Initially show the modal
  const [isVerified, setIsVerified] = useState(false); // Track verification status
  const [cart, setCart] = useState([]);
  const [showToggleSideMenu, setShowToggleSideMenu] = useState(false);
  const [tablets, setTablets] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/GetAll_itemc', {})
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        setIsLoading(false);
        return response.json();
      })
      .then((data) => {
        const tabletsWithIntegerIds = data.map((tablet) => ({
          ...tablet,
          id: parseInt(tablet.id),
        }));

        setIsLoading(false);
        setTablets(tabletsWithIntegerIds);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(true);
        setIsLoading(false);
      });
  }, []);

  const handlePurchase = (tablet) => {
    handleAddToCart(tablet);
  };

  const handleAddToCart = (tablet) => {
    const isAlreadyInCart = cart.some((item) => item.id === tablet.id);

    if (!isAlreadyInCart) {
      // If not in cart, add it
      addToCartAndUpdateCartState(tablet);
    } else {
      // If already in cart, just navigate to the cart page directly
      navigateToCart();
      setShowModal(false);
    }
  };

  const addToCartAndUpdateCartState = (tablet) => {
    setCart([...cart, tablet]);
    setShowModal(false);
    navigateToCart();
  };

  const navigateToCart = () => {
    navigate('/cart', { state: { cart: [...cart] } });
  };

  const handleLogin = async () => {
    const loginResponse = await instance.loginPopup();
    if (loginResponse && loginResponse.account) {
      navigate('/dashboard');
    }
  };

  const accessories = tablets.filter((tablet) => tablet.category === 'Accessories');
  const Others = tablets.filter((tablet) => tablet.category === 'Others');
  const tabletsFiltered = tablets.filter((tablet) => tablet.category === 'Tablet');

  const handleShowSideNav = () => {
    setShowToggleSideMenu(true);
  };

  const handleCloseSideNav = () => {
    setShowToggleSideMenu(false);
  };

  const sharedTablet = tabletsFiltered.find((tablet) => tablet.id === 1);

  const handleVerify = (staffID) => {
    // Add your staff ID verification logic here
    if (staffID) {
      setIsVerified(true);
      setShowModal(false);
    }
  };

  // Only show the modal if the user is not on the /signin route and is not verified
  const shouldShowModal = showModal && !isVerified && location.pathname !== '/signin';

  return (
    <main>
      <VerifyStaffIDModal
        show={shouldShowModal}
        handleClose={() => setShowModal(false)}
        handleVerify={handleVerify}
      />
      <div className={`content ${shouldShowModal ? 'blurred' : ''}`}>
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
          <div className="layout-container">
            <div className="navbar-wrapper">
              <nav className="layout-navbar shadow-none py-0 bg-primary ">
                <div className="container">
                  <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4 border-light-subtle">
                    <div className="navbar-brand app-brand demo d-flex py-0 me-4">
                      <button
                        className="navbar-toggler border-0 px-0 me-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={handleShowSideNav}
                      >
                        <i className="tf-icons bx bx-menu bx-sm align-middle" />
                      </button>
                      <a href="/" className="animation1">
                        <img src={companyLogo} alt="Computer Arabia" />
                      </a>
                    </div>
                    <div
                      className="collapse navbar-collapse landing-nav-menu"
                      id="navbarSupportedContent"
                    >
                      <button
                        className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <i className="tf-icons bx bx-x bx-sm" />
                      </button>
                      <ul className="navbar-nav me-auto">
                        <li className="nav-item">
                          <Link className="nav-link" to="/">
                            {language === 'en' ? enTranslations['Home'] : arTranslations['Home']}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link fw-large" aria-current="page" to="track-order">
                            {language === 'en' ? enTranslations['Track Order'] : arTranslations['Track Order']}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="landing-menu-overlay d-lg-none" />
                    <ul className="navbar-nav flex-row align-items-center ms-auto position-absolute top-20 end-0">
                      <li className="nav-item">
                        <Link to="/cart" className="btn text-nowrap d-inline-block">
                          <span className="tf-icons bx bx-sm bx-cart text-primary"></span>
                          <span className="badge rounded-pill bg-danger text-white badge-notifications text-subtitle-1">
                            {cart?.length}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="tagline-wrapper">
              {location.pathname === '/' && (
                <section className="company-taglines main-container">
                  <div className="container">
                    <h2 className="company-heading">
                      <span>{language === 'en' ? enTranslations['Welcome to'] : arTranslations['Welcome to']}</span>
                      <span>{language === 'en' ? enTranslations['Computer Arabia'] : arTranslations['Computer Arabia']}</span>
                    </h2>
                  </div>
                </section>
              )}
            </div>
            <div className="layout-page main-container">
              <div className="content-wrapper">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Products
                        accessories={accessories}
                        Others={Others}
                        sharedTablet={sharedTablet}
                        language={language}
                        handlePurchase={handlePurchase}
                        handleAddToCart={handleAddToCart}
                        handleLanguageChange={handleLanguageChange}
                        error={error}
                        isLoading={isLoading}
                      />
                    }
                  />
                  <Route
                    path="/cart"
                    element={<AddtoCart tablets={tablets} cart={cart} setCart={setCart} handlePurchase={handlePurchase} />}
                  />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route exact path="/thankyou" element={<ThankYou />} />
                  <Route exact path="/thankyouR" element={<ThankYouOffline />} />
                  <Route path="/track-order" element={<TrackOrders />} />
                  <Route path="/signin" element={<SignIn />} />
                </Routes>
                <Faqs language={language} handleLanguageChange={handleLanguageChange} />
                <Footer />
                <div className="content-backdrop fade" />
              </div>
            </div>
          </div>
        </div>
        <Offcanvas show={showToggleSideMenu} onHide={handleCloseSideNav}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <a href="#" className="app-brand-link gap-2">
                <span className="app-brand-logo">
                  <img src={companyLogo} alt="logo" width={50} />
                </span>
                <span className="app-brand-text menu-text fw-bold text-dark">Computer Arabia</span>
              </a>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="d-block">
              <Nav.Link as={Link} to="/" onClick={() => setShowToggleSideMenu(false)}>
                <i className="bx bx-home"></i> Home
              </Nav.Link>
              <Nav.Link as={Link} to="/cart" onClick={() => setShowToggleSideMenu(false)}>
                <i className="bx bx-cart-alt"></i> Cart
              </Nav.Link>
              <Nav.Link as={Link} to="/track-order" onClick={() => setShowToggleSideMenu(false)}>
                <i className="bx bx-package"></i> Track Order
              </Nav.Link>
              <div className="ml-auto">
                <Nav.Link as={Link} to="/contact-us" onClick={() => setShowToggleSideMenu(false)}>
                  <i className="bx bx-phone"></i> Contact Us
                </Nav.Link>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </main>
  );
};

export default ProductsCatalog;

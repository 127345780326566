import React, { useEffect, useState, useRef } from 'react';
import { Container, Button } from '@mui/material';
import { Modal, Form } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import ccdc from '../assets/ccdc.png';
import logo from '../assets/logoo.png';
import '../AddtoCart.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';

const AddtoCart = ({ cart = [], setCart }) => {
  const [deliveryOption, setDeliveryOption] = useState('delivery');
  const [idNumber, setIdNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [otp, setOTP] = useState(['', '', '', '']);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [customerInfo, setCustomerInfo] = useState(null);
  const [generatedOTP, setGeneratedOTP] = useState('');
  const [itemQuantities, setItemQuantities] = useState({});
  const initialCart = cart.map(item => ({
    ...item,
    qty: 1 
  }));
  const [currentCart, setCurrentCart] = useState(initialCart);
  const [showMainModal, setShowMainModal] = useState(false);
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const [showExistingCustomerModal, setShowExistingCustomerModal] = useState(false);
  const InitialValues = {
    qid: "",
    cname: "",
    email: "",
    deliveryAddress: "",
    mobile: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
    useEffect(() => {
      const updatedQuantities = {};
      currentCart.forEach(item => {
        updatedQuantities[item.id.toString()] = item.qty;
      });
      setItemQuantities(updatedQuantities);
    }, [currentCart]);
    
    const updateQuantity = (cartId, newQty) => {
      const updatedCart = cart.map(item =>
        item.id === cartId ? { ...item, qty: newQty } : item
      );
      setCart(updatedCart);
    };
  
  const totalPrice = currentCart.reduce((total, item) => total + item.price, 0);

  const handleCheckout = () => {
    // setShowMainModal(true);
    setShowExistingCustomerModal(true);
  };

  const handleNewCustomer = () => {
    setShowMainModal(false);
    setShowNewCustomerModal(true);
  };

  const handleExistingCustomer = () => {
    setShowMainModal(false);
    setShowExistingCustomerModal(true);
  };

  const removeItem = (itemId) => {
    const updatedItemQuantities = { ...itemQuantities };
    delete updatedItemQuantities[itemId];
    // console.log('updatedItemQuantities',updatedItemQuantities);
    // console.log('item',itemId);
    setItemQuantities(updatedItemQuantities);
    setCurrentCart(currentCart.filter((item) => item.id !== itemId));
    setCart(cart.filter((item) => item.id !== itemId));
  };

  const clearCart = () => {
    setCart([]);
  };

  const handleSubmit = async (e) => {
    const totalAmount = deliveryOption === 'delivery' ? totalPriceWithDelivery : totalPrice;
    navigate('/checkout', { state: { cart: currentCart, customerInfo: customerInfo, totalAmount: totalAmount, deliveryOption: deliveryOption } });
  };
  
  const increaseQuantity = async (itemId) => {
    try {
      const response = await axios.get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetAll_itemc`);
      const items = response.data;
      const item = items.find((item) => String(item.id) === String(itemId));
  
      if (!item) {
        toast.error('Item not found', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        return;
      }
  
      const updatedQuantities = {
        ...itemQuantities,
        [itemId]: (itemQuantities[itemId] || 0) + 1,
      };
  
      if (updatedQuantities[itemId] > item.qty) {
        toast.warning('Exceeds available stock', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        return;
      }
  
      // Update item quantity in the cart
      setItemQuantities(updatedQuantities);
  
      const origPrice = item.originalPrice;
  
      const updatedCart = currentCart.map((cartItem) => {
        if (cartItem.id === itemId) {
          return {
            ...cartItem,
            price: origPrice * updatedQuantities[itemId],
            qty: updatedQuantities[itemId],
          };
        }
        return cartItem;
      });
  
      setCurrentCart(updatedCart);
  
    } catch (error) {
      console.error('Error fetching item details:', error);
      toast.error('Failed to fetch item details', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  };
  

  const decreaseQuantity = (itemId) => {
    const updatedQuantities = {
      ...itemQuantities,
      [itemId]: Math.max((itemQuantities[itemId] || 1) - 1, 0),
    };

    if(itemQuantities[itemId] <= 1) {
      toast.info('Item removed from cart', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      removeItem(itemId)
      return
    }
  
    setItemQuantities(updatedQuantities);

    const origPrice = cart.find(item => item.id === itemId)?.originalPrice;
  
    const updatedCart = currentCart.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          price: origPrice * updatedQuantities[itemId],
          qty: updatedQuantities[itemId],
        };
      }
      return item;
    });
  
    setCurrentCart(updatedCart);
  };

  const handleDeliveryOptionChange = (option) => {
    setDeliveryOption(option);
  };

  const deliveryCharges = deliveryOption === 'delivery' ? 50 : 0;
  const totalPriceWithDelivery = totalPrice + deliveryCharges;

  return (
    <section className="section-py container">
      <div className="container">
          <h4 className="py-3 mb-4">
           My Cart Items: {cart.length}
          </h4>
          <div className="bg-white p-4 py-5 rounded-3 shadow-sm">
          {cart.length === 0 ? (
            <h5>Your Cart is Empty</h5>
          ) : (
            <div>
              <div className="row">
                <div className="col-xl-8 mb-3 mb-xl-0">
                  <ul className="list-group mb-3">
                    {Object.entries(itemQuantities).map(([itemId, quantity]) => {
                      const item = currentCart.find((item) => item.id === parseInt(itemId));
                      return (
                        <li className="list-group-item p-4" key={itemId}>
                          <div className="d-flex gap-3">
                            <div className="flex-shrink-0 d-flex align-items-center">
                              <img src={item?.image} alt={item?.name} className="w-px-100" />
                            </div>
                            <div className="flex-grow-1">
                              <div className="row">
                                <div className="col-md-8">
                                  <p className="me-3">
                                    <a href="#" className="text-body">
                                      {item?.Name}
                                    </a>
                                  </p>
                                  <div className="text-muted mb-2 d-flex flex-wrap">
                                    <span className="me-1">Sold by:</span>
                                    <a href="javascript:void(0)" className="me-3"> {/* https://carabia.com/ */}
                                      Computer Arabia
                                    </a>
                                    <span className="badge bg-label-success">In Stock</span>
                                  </div>
                                  <div
                                    className="read-only-ratings mb-3"
                                    data-rateyo-read-only="true"
                                  />
                                  <div className="quantity-control">
                                    <button
                                      className="btn btn-danger btn-sm me-2"
                                      onClick={() => decreaseQuantity(parseInt(itemId))}
                                    >
                                      -
                                    </button>
                                  <strong>  <span className="quantity">{quantity}</span> </strong>
                                    <button
                                      className="btn btn-primary btn-sm ms-2"
                                      onClick={() => increaseQuantity(parseInt(itemId))}
                                    >
                                      +
                                    </button>
                                  </div>
                                  <strong> ({`${quantity}x`}) {item?.name} </strong>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-md-end">
                                    <button
                                      type="button"
                                      className="btn-close btn-pinned"
                                      aria-label="Close"
                                      onClick={() => removeItem(parseInt(itemId))}
                                    />
                                    <div className="my-2 my-md-4 mb-md-5">
                                      <ul className="list-unstyled">
                                        {currentCart
                                          .filter((i) => i.id === parseInt(itemId))
                                          .map((cartItem, index) => (
                                            <li key={index}>
                                              {cartItem.type === 'downpayment'
                                                ? 'Price:'
                                                : 'Price:'}{' '}
                                              <span className="text-primary"> {cartItem.originalPrice}</span>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* Cart right */}
                <div className="col-xl-4">
                  <div className="border rounded p-4 mb-3 pb-3">
                    {/* Gift wrap */}
                    <div className="bg-lighter rounded p-3">
                      <p className="fw-medium mb-2">Use Debit Card or Credit Card</p>
                      <p className="mb-2">
                        Please use debit card or credit card for faster and smooth transaction
                      </p>
                      <a href="javascript:void(0)" className="fw-medium">
                        {/* Mastercard/VISA */}
                        <img src={ccdc} alt="card" className="w-px-150" />
                      </a>
                    </div>
                    <hr className="mx-n4" />

                    <h6>Price Details</h6>
                    <dl className="row mb-0">
                      <dt className="col-6 fw-normal">Cart Total</dt>
                      <dd className="col-6 text-end"> {totalPrice.toFixed(2)}</dd>
                      <dt className="col-6 fw-normal">Delivery Charges</dt>
                      <dd className="col-6 text-end">

                        {deliveryOption === 'delivery' ? (
                          <span>+QAR 50</span>
                        ) : (
                          <span className="badge bg-label-success ms-1">Free</span>
                        )}
                      </dd>
                    </dl>
                    <div className="mb-3">
                        <label className="me-3">
                        <input
                            type="radio"
                            value="delivery"
                            checked={deliveryOption === 'delivery'}
                            onChange={() => handleDeliveryOptionChange('delivery')}
                        />{' '}
                        Delivery (+QAR 50)
                        </label>
                        <label>
                        <input
                            type="radio"
                            value="pickup"
                            checked={deliveryOption === 'pickup'}
                            onChange={() => handleDeliveryOptionChange('pickup')}
                        />{' '}
                        Pickup (Free)
                        </label>
                    </div>
                    {/* <hr className="mx-n4" />
                    <dl className="row mb-0">
                      <dt className="col-6">Total Amount</dt>
                      <dd className="col-6 fw-medium text-end mb-0">QAR {totalPriceWithDelivery.toFixed(2)}</dd>
                    </dl> */}
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-success btn-next" onClick={handleSubmit}>
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AddtoCart;
import React, {useState, useEffect} from 'react'

const Filters = (props) => {

    const {counts, onFilter, filterKey} = props;

    return (
        <div className="card mx-4 mb-4">
            <div className="card-widget-separator-wrapper">
                <div className="card-body card-widget-separator py-3">
                    <div className="row gy-4 gy-sm-1">
                        <div className={`col-sm-6 col-lg-3 p-3 me-2 filter-item-notif cursor-pointer ${filterKey == '' ? 'active' : '' }`} onClick={() => onFilter('')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[0]}</h3>
                                <p className="mb-0">New Order</p>
                            </div>
                            <div className="avatar me-sm-4">
                                <span className="avatar-initial rounded bg-label-primary">
                                <i className="bx bx-bell bx-sm text-primary" />
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none me-4" />
                        </div>
                        <div className={`col-sm-6 col-lg-3 p-3 me-2 filter-item-notif cursor-pointer ${filterKey == 'forpickup' ? 'active' : '' }`} onClick={() => onFilter('forpickup')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[1]}</h3>
                                <p className="mb-0">Notify for Pickup</p>
                            </div>
                            <div className="avatar me-lg-4">
                                <span className="avatar-initial rounded bg-label-success">
                                <i className='bx bx-message-rounded-error bx-sm text-success'  />
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filters
// import React, {useState, useEffect} from 'react'
// import axios from 'axios';
// import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
// import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
// import Badge from '../components/Badge';
// import { dateFullFormat, currencyFormat } from '../utils/utils'
// import Button from '../components/Button';
// import { BlobProvider } from '@react-pdf/renderer';
// import Docs from '../Transactions/Docs';

// const ContentComponent = ({data, serial, updateSerialTransaction, invoiceNo, updateInvoiceNoTransaction, isBtnLoading, notifyForFullPayment, notifyForPickup, shipment_status, setShipmentStatus, filterKey, pickupAddress, setPickupAddress}) => {
    
//     const [dropdownOpen, setDropdownOpen] = useState(false);
//     const [newSerialNo, setNewSerialNo] = useState(serial);
//     const [newInvoiceNo, setNewInvoiceNo] = useState(invoiceNo);
//     const [newPickupLocation, setNewPickupLocation] = useState(pickupAddress);
//     const [paymentUpdated, setPaymentUpdated] = useState(false);
//     const [shipmentUpdated, setShipmentUpdated] = useState(false);
//     const [datas, setDatas] = useState(null);

//     // const updatePaymentStatusToPaid = () => {
//     //     axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', [{
//     //         order_id: data.order_id,
//     //         transaction_status: "Paid",
//     //         shipment_status: "Picked up"
//     //     }])
//     //     .then(response => {
//     //         console.log('Payment status updated:', response.data);
//     //         setPaymentUpdated(true);
//     //     })
//     //     .catch(error => {
//     //         console.error('Error updating payment status:', error);
//     //     });
//     // };

//   useEffect(() => {
//     fetchData();
//   }, [data.order_id]);

//   const fetchData = () => {
//     axios
//       .get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetTransactionsc?qid=&order_id=${data.order_id}`)
//       .then((response) => {
//         setDatas(response.data);
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   };

//     const updatePaymentStatusToPaid = () => {
//         const orderId = data.order_id;
    
//         axios.get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetTransactionsc?qid=&order_id=${orderId}`)
//             .then(transactionResponse => {
//                 const items = transactionResponse.data; 
    
//                 const inventoryChecks = items.map(item => {
//                     return axios.get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetItemBy_itemidc?id=${item.itemId}`)
//                         .then(inventoryResponse => {
//                             const inventoryItem = inventoryResponse.data[0];
//                             if (inventoryItem.qty >= item.quantity) {
//                                 return { ...item, isAvailable: true, newQty: inventoryItem.qty - item.quantity };
//                             } else {
//                                 return { ...item, isAvailable: false };
//                             }
//                         });
//                 });
    
//                 return Promise.all(inventoryChecks);
//             })
//             .then(itemsWithAvailability => {
//                 const itemsToUpdate = itemsWithAvailability.filter(item => item.isAvailable);
    
//                 if (itemsToUpdate.length === itemsWithAvailability.length) {
//                     const updatePayload = itemsToUpdate.map(item => ({
//                         Id: item.itemId,
//                         qty: item.newQty 
//                     }));
    
//                     return axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_itemQtyc', updatePayload);
//                 } else {
//                     throw new Error('One or more items do not have sufficient stock.');
//                 }
//             })
//             .then(() => {
//                 return axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', [{
//                     order_id: orderId,
//                     transaction_status: "Paid",
//                     shipment_status: "Picked up"
//                 }]);
//             })
//             .then(response => {
//                 // console.log('Payment status updated:', response.data);
//                 setPaymentUpdated(true);
//             })
//             .catch(error => {
//                 // console.error('Error processing the transaction:', error);
//             });
//     };

//     const updateShipmentStatusStorePU = () => {
//         axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', [{
//             order_id: data.order_id,
//             shipment_status: "Picked up"
//         }])
//         .then(response => {
//             // console.log('Shipment status updated:', response.data);
//             setShipmentUpdated(true);
//         })
//         .catch(error => {
//             // console.error('Error updating payment status:', error);
//         });
//     };

//     const handleNewPickupLocation = (e) => {
//         setNewPickupLocation(e.target.value);
//         setPickupAddress(e.target.value);
//     }

//     const shipments_label = [
//         {
//             label: 'Order Received',
//             value: 'Order Received',
//         },
//         {
//             label: 'Preparing Item',
//             value: 'Preparing Item',
//         },
//         {
//             label: 'Delivering',
//             value: 'Delivering',
//         },
//         {
//             label: 'Item Received by Owner',
//             value: 'Tablet Received by Owner',
//         },
//     ]

//     const schools_locations = [
//         {
//             label: 'Al Jazeera Academy',
//             value: 'Al Jazeera Academy',
//         },
//         {
//             label: 'Al Maha Academy for Boys',
//             value: 'Al Maha Academy for Boys',
//         },
//         {
//             label: 'Al Maha Academy for Girls',
//             value: 'Al Maha Academy for Girls',
//         },
//     ]

//     return(
//         <>
//         {datas ? (
//         <div className="row mb-3">
//             <div className="col-12 col-lg-8 mb-3">
//                 <h4 className="mt-2">ORDER ID: {data?.order_id}</h4>
//             </div>
//             <div className="col-12">
//                 <ul className="list-group list-group-horizontal-md">
//                     <li className="list-group-item flex-fill p-3 px-2 text-heading">
//                     <h6 className="d-flex align-items-center gap-1">
//                         <i className='bx bx-user-pin'></i> Customer Details
//                     </h6>
//                     <ul className="text-wrap">
//                         <li>QID: {datas[0].qid} </li>
//                         <li>Staff ID: {datas[0].sid} </li>
//                         <li>Name: {datas[0].name}</li>
//                         <li className="text-break">Email: <a href={`mailto:${datas[0].email}`}>{datas[0].email}</a></li>
//                         <li>Mobile: {datas[0].mobile_number}</li>
//                     </ul>
//                     </li>
//                     <li className="list-group-item flex-fill p-3 text-heading">
//                         <h6 className="d-flex align-items-center gap-1">
//                             <i className='bx bx-receipt'></i> Other Details
//                         </h6>
//                         <ul>
//                             <li>Transaction ID: {datas[0].order_id}</li>
//                             <li>Transaction Date: {dateFullFormat(datas[0].transDateTime)}</li>
//                             <li>Transaction Type: {datas[0].transaction_type === 'Online Payment' ? 'Online Payment' : 'Cash Payment'}</li>
//                             <li>Transaction Status: {datas[0]?.transaction_status}</li>
//                             <li>Shipment Status: {shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : shipment_status}</li>
//                             <li>
//                                 Pickup Location: {newPickupLocation}
//                                 {datas[0].pickup_address !== '' ? datas[0].pickup_address : <select className="form-select-sm form-select" onChange={(e) => handleNewPickupLocation(e)}>
//                                 {schools_locations?.map((school, index) => (
//                                         <option value={school.value} key={index}>{school.label}</option>
//                                     ))}
//                                 </select>}
                                
//                             </li>
//                             <li className="remarks" style={{color:"maroon", fontStyle:"italic"}}>Remarks: {datas[0].remarks}</li>
//                         </ul>
//                     </li>
//                 </ul>
//             </div>
//         </div>
//           ) : (
//             <p>Loading...</p>
//           )}
//         {datas && (
//         <div className="row">
//             <div className="col-xl-8 mb-3 mb-xl-0">
//                 <ul className="list-group">
//                     <li className="list-group-item p-4">
//                         <div className="d-flex gap-3">
//                             <div className="flex-grow-1">
//                                 <div className="row">
//                                     <div className="col-md-8">
//                                         <div className="text-muted mb-1 d-flex flex-wrap">
//                                             <a href="#" className="me-3">
//                                                 Computer Arabia
//                                             </a>
//                                         </div>
//                                         <p>{datas[0].quantity}x {datas[0].item}</p>
                                        
//                                         {/* <Badge variant="bg-label-success" title="In Stock" /> */}
//                                         {datas[0].transaction_status.toUpperCase() === 'PAID' && (
//                                             <>
//                                             <div className="input-group mt-3">
//                                                 {datas[0].serial_no === '' ? (
//                                                     <>
//                                                         <input
//                                                             type="text"
//                                                             className="form-control form-control-sm"
//                                                             placeholder="Serial No."
//                                                             value={datas[0].serial}
//                                                             onChange={(e) => setNewSerialNo(e.target.value)}
//                                                         />
//                                                         <Button customclass="btn btn-primary btn-sm" title={<i className='bx bx-save'></i>} action={() => updateSerialTransaction(newSerialNo)} />
//                                                     </>
//                                                 ) : (
//                                                     <span className="text-muted">Serial No.: {datas[0].serial_no}</span>
//                                                 )}
//                                             </div>
//                                             <div className="input-group mt-3">
//                                                 {datas[0].notes.trim() === '' ? (
//                                                     <>
//                                                         <input
//                                                             type="text"
//                                                             className="form-control form-control-sm"
//                                                             placeholder="Invoice No."
//                                                             value={newInvoiceNo}
//                                                             onChange={(e) => setNewInvoiceNo(e.target.value)}
//                                                         />
//                                                         <Button customclass="btn btn-primary btn-sm" title={<i className='bx bx-save'></i>} action={() => updateInvoiceNoTransaction(newInvoiceNo)} />
//                                                     </>
//                                                 ) : (
//                                                     <span className="text-muted">Invoice No.: {datas[0].notes}</span>
//                                                 )}
//                                             </div>
//                                             </>
//                                         )}
//                                         {/* <div className="mt-3">
//                                             <textarea className="form-control form-control-sm" placeholder="Notes"
//                                                 value={data?.notes}
//                                                 onChange={(e) => updateNotes(e.target.value)} 
//                                             />
//                                         </div> */}
//                                         <button className="btn btn-primary me-2 text-white mt-2" onClick={fetchData}><i className="bx bx-refresh me-1"></i>Reload </button>
//                                     </div>
//                                     <div className="col-md-4">
//                                         <div className="text-md-end">
//                                             <div className="my-2 my-lg-4">
//                                                 <span className="text-primary text-end">{currencyFormat(datas[0].amount)}</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <i><p>Note: After clicking the save button for invoice no. and serial no. please click the reload before printing the invoice </p></i>
//                                 </div>
//                             </div>
//                         </div>
//                     </li>
//                 </ul>
//             </div>
//             <div className="col-xl-4">
//                 <div className="border rounded p-4 pb-3">
//                     <h6>
//                         <i className='bx bx-money' ></i> Price Details
//                     </h6>
//                     <dl className="row mb-0">
//                         <dt className="col-6 fw-normal">Order Total</dt>
//                         <dd className="col-6 text-end pr-0">{currencyFormat(datas[0].amount)}</dd>
//                         <dt className="col-sm-6 fw-normal">Delivery Charges</dt>
//                         {datas[0].deliveryType === 'Delivery' ? (
//                             <dd className="col-sm-6 text-end pr-0">
//                             <Badge variant="bg-label-success ms-1" title="50 QAR" />
//                             </dd>
//                         ) : (
//                             <dd className="col-sm-6 text-end pr-0">
//                             <Badge variant="bg-label-success ms-1" title="Free" />
//                             </dd>
//                         )}
//                     </dl>
//                     <hr className="mx-n4" />
//                     <dl className="row mb-0">
//                         <dt className="col-6">Total</dt>
//                         <dd className="col-6 fw-medium text-end mb-0">{currencyFormat(datas[0].amount)}</dd>
//                     </dl>
//                 </div>
//             </div>
//             <div >
//                 <div className="d-flex align-items-start justify-content-start mt-3">
//                     {datas[0].transaction_status === 'Paid' && datas[0].deliveryType === 'Store Pickup' && datas[0].transaction_type === 'Pay in Cash' && (
//                         <>
//                             <BlobProvider document={<Docs data={datas[0]} serial={datas[0].serial_no} invNo={datas[0].notes} />}>
//                             {({ url }) => (
//                                 <a className="btn btn-warning me-2 text-white" href={url} target="_blank">
//                                 <i className="bx bx-printer me-1"></i>Print Invoice
//                                 </a>
//                             )}
//                             </BlobProvider>
//                         </>
//                         )}
//                         {datas[0].transaction_status === 'Pending' && datas[0].deliveryType === 'Store Pickup' && datas[0].transaction_type === 'Pay in Cash' && (
//                                         <>
//                                             <button className="btn btn-danger me-2 text-white" onClick={updatePaymentStatusToPaid}>
//                                                 <i className="bx bx-money me-1"></i>Paid
//                                             </button>
//                                             {paymentUpdated && <span>Payment status updated!</span>}
//                                         </>
//                         )}
//                     {datas[0].transaction_type === 'Downpayment' && datas[0].outstandingBalance !== 0 && (
//                         <Button customclass="btn btn-primary me-2" title="Notify for Full Payment" icon="bx bx-bell me-1" action={notifyForFullPayment} isLoading={isBtnLoading}/>
//                     )}
//                 </div>
//             </div>
//             <div className="d-flex align-items-center justify-content-end mt-3">
//                 {!filterKey && (
//                     <>
//                         <div className="ms-3">
//                             {/* {data?.transaction_type !== 'Online Payment' && data?.pickup_address !== '' && (
//                                 <button className="btn btn-success me-2" onClick={notifyForPickup} disabled={isBtnLoading}>
//                                     <i className="bx bx-bell me-1"></i> Notify Pickup
//                                 </button>
//                             )} */}
//                         </div>
//                         <div className="btn-group ms-3">
//                         {data?.transaction_type === 'Online Payment' && data?.pickup_address !== '' && (
//                             <button type="button" className={`btn btn-primary dropdown-toggle ${dropdownOpen ? 'show' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
//                                 <i className='bx bxs-package me-2'></i> {shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : shipment_status}
//                             </button>
//                             )}
//                             <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 40px)' }}>
//                                 {shipments_label.map((item, index) => (
//                                     <li key={index} onClick={() => setDropdownOpen(false)}>
//                                         <a href="#" className="dropdown-item d-flex align-items-center" onClick={() => setShipmentStatus(item.value)}>
//                                             {item.label}
//                                         </a>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </div>
//                         {/* {datas[0].transaction_type === 'Online Payment' && datas[0].deliveryType === 'Store Pickup' ? (
//                             <>
//                             <button type="button" className="btn btn-primary" onClick={() => updateShipmentStatusStorePU()}>
//                                 Pickup
//                             </button>
//                              {shipmentUpdated && <span>Shipment status updated!</span>}
//                              </>
//                         ) : (
//                             <div className="btn-group ms-3">
//                                 {datas[0].transaction_type === 'Online Payment' && datas[0].pickup_address !== '' && (
//                                     <button type="button" className={`btn btn-primary dropdown-toggle ${dropdownOpen ? 'show' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
//                                         <i className='bx bxs-package me-2'></i> {shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : shipment_status}
//                                     </button>
//                                 )}
//                                 <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 40px)' }}>
//                                     {shipments_label.map((item, index) => (
//                                         <li key={index} onClick={() => setDropdownOpen(false)}>
//                                             <a href="#" className="dropdown-item d-flex align-items-center" onClick={() => setShipmentStatus(item.value)}>
//                                                 {item.label}
//                                             </a>
//                                         </li>
//                                     ))}
//                                 </ul>
//                             </div>
//                         )} */}

//                     </>
//                 )}
//             </div>
//         </div>
//            )}
//         </>
//     )
// }

// export default ContentComponent


import React, {useState, useEffect} from 'react'
import axios from 'axios';
import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
import Badge from '../components/Badge';
import { dateFullFormat, currencyFormat } from '../utils/utils'
import Button from '../components/Button';
import { BlobProvider } from '@react-pdf/renderer';
import Docs from '../Transactions/Docs';

const ContentComponent = ({data, serial, updateSerialTransaction, invoiceNo, updateInvoiceNoTransaction, isBtnLoading, notifyForFullPayment, notifyForPickup, shipment_status, setShipmentStatus, filterKey, pickupAddress, setPickupAddress}) => {
    
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [newSerialNo, setNewSerialNo] = useState({});
    const [newInvoiceNo, setNewInvoiceNo] = useState(invoiceNo);
    const [newPickupLocation, setNewPickupLocation] = useState(pickupAddress);
    const [paymentUpdated, setPaymentUpdated] = useState(false);
    const [shipmentUpdated, setShipmentUpdated] = useState(false);
    const [datas, setDatas] = useState();


    useEffect(() => {
        setDatas(data.items);
    }, [data]);

    const handleSerialNoChange = (itemId, value) => {
        setNewSerialNo(prevState => ({
            ...prevState,
            [itemId]: value
        }));
    };
    
    const handleUpdateSerialTransaction = (itemId) => {
        const serialNo = newSerialNo[itemId];
        if (serialNo) {
            updateSerialTransaction(serialNo, itemId);
        } else {
            alert('Please enter a serial number.');
        }
    };

    // const updatePaymentStatusToPaid = () => {
    //     axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', [{
    //         order_id: data.order_id,
    //         transaction_status: "Paid",
    //         shipment_status: "Picked up"
    //     }])
    //     .then(response => {
    //         console.log('Payment status updated:', response.data);
    //         setPaymentUpdated(true);
    //     })
    //     .catch(error => {
    //         console.error('Error updating payment status:', error);
    //     });
    // };

  useEffect(() => {
    fetchData();
  }, [data.order_id]);

  const fetchData = () => {
    axios
      .get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetCarabia_transactions_ByOrderID?orderid=${data.order_id}`)
      .then((response) => {
        setDatas(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

    const updatePaymentStatusToPaid = () => {
        const orderId = data.order_id;
    
        axios.get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetCarabia_transactions_ByOrderID?orderid=${orderId}`)
            .then(transactionResponse => {
                const items = transactionResponse.data; 
    
                const inventoryChecks = items[0].Items.map(item => {
                    return axios.get(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetItemBy_itemidc?id=${item.itemId}`)
                        .then(inventoryResponse => {
                            const inventoryItem = inventoryResponse.data[0];
                            if (inventoryItem.qty >= item.quantity) {
                                return { ...item, isAvailable: true, newQty: inventoryItem.qty - item.quantity };
                            } else {
                                return { ...item, isAvailable: false };
                            }
                        });
                });
    
                return Promise.all(inventoryChecks);
            })
            .then(itemsWithAvailability => {
                const itemsToUpdate = itemsWithAvailability.filter(item => item.isAvailable);
    
                if (itemsToUpdate.length === itemsWithAvailability.length) {
                    const updatePayload = itemsToUpdate.map(item => ({
                        Id: item.itemId,
                        qty: item.newQty 
                    }));
    
                    return axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_itemQtyc', updatePayload);
                } else {
                    throw new Error('One or more items do not have sufficient stock.');
                }
            })
            .then(() => {
                return axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', [{
                    order_id: orderId,
                    transaction_status: "Paid",
                    shipment_status: "Picked up"
                }]);
            })
            .then(response => {
                // console.log('Payment status updated:', response.data);
                setPaymentUpdated(true);
            })
            .catch(error => {
                // console.error('Error processing the transaction:', error);
            });
    };

    const updateShipmentStatusStorePU = () => {
        axios.post('https://iss.apps.taallumgroup.com:8085/api/issapi/update_transc', [{
            order_id: data.order_id,
            shipment_status: "Picked up"
        }])
        .then(response => {
            // console.log('Shipment status updated:', response.data);
            setShipmentUpdated(true);
        })
        .catch(error => {
            // console.error('Error updating payment status:', error);
        });
    };

    const handleNewPickupLocation = (e) => {
        setNewPickupLocation(e.target.value);
        setPickupAddress(e.target.value);
    }

    const shipments_label = [
        {
            label: 'Order Received',
            value: 'Order Received',
        },
        {
            label: 'Preparing Item',
            value: 'Preparing Item',
        },
        {
            label: 'Delivering',
            value: 'Delivering',
        },
        {
            label: 'Item Received by Owner',
            value: 'Tablet Received by Owner',
        },
    ]

    const schools_locations = [
        {
            label: 'Al Jazeera Academy',
            value: 'Al Jazeera Academy',
        },
        {
            label: 'Al Maha Academy for Boys',
            value: 'Al Maha Academy for Boys',
        },
        {
            label: 'Al Maha Academy for Girls',
            value: 'Al Maha Academy for Girls',
        },
    ]

    return(
        <>
        {datas ? (
        <div className="row mb-3">
            <div className="col-12 col-lg-8 mb-3">
                <h4 className="mt-2">ORDER ID: {data?.order_id}</h4>
            </div>
            <div className="col-12">
                <ul className="list-group list-group-horizontal-md">
                    <li className="list-group-item flex-fill p-3 px-2 text-heading">
                    <h6 className="d-flex align-items-center gap-1">
                        <i className='bx bx-user-pin'></i> Customer Details
                    </h6>
                    <ul className="text-wrap">
                        <li>QID: {datas[0].qid} </li>
                        <li>Staff ID: {datas[0].sid} </li>
                        <li>Name: {datas[0].name}</li>
                        <li className="text-break">Email: <a href={`mailto:${datas[0].email}`}>{datas[0].email}</a></li>
                        <li>Mobile: {datas[0].mobile_number}</li>
                    </ul>
                    </li>
                    <li className="list-group-item flex-fill p-3 text-heading">
                        <h6 className="d-flex align-items-center gap-1">
                            <i className='bx bx-receipt'></i> Other Details
                        </h6>
                        <ul>
                            <li>Transaction ID: {datas[0].order_id}</li>
                            <li>Transaction Date: {dateFullFormat(datas[0].transDateTime)}</li>
                            <li>Transaction Type: {datas[0].transaction_type === 'Online Payment' ? 'Online Payment' : 'Cash Payment'}</li>
                            <li>Transaction Status: {datas[0]?.transaction_status}</li>
                            <li>Shipment Status: {shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : shipment_status}</li>
                            <li>
                                Pickup Location: {newPickupLocation}
                                {datas[0].pickup_address !== '' ? datas[0].pickup_address : <select className="form-select-sm form-select" onChange={(e) => handleNewPickupLocation(e)}>
                                {schools_locations?.map((school, index) => (
                                        <option value={school.value} key={index}>{school.label}</option>
                                    ))}
                                </select>}
                                
                            </li>
                            <li className="remarks" style={{color:"maroon", fontStyle:"italic"}}>Remarks: {datas[0].remarks ? datas[0].remarks : "No Remarks"}</li>
                            {/* <li className="WithOS" style={{color:"maroon", fontStyle:"italic"}}>With OS: {data.Items[0].WithOS ? data.Items[0].WithOS : "no" }</li> */}
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
          ) : (
            <p>Loading...</p>
          )}
        {datas && (
        <div className="row">
            <div className="col-xl-8 mb-3 mb-xl-0">
                <ul className="list-group">
                    <li className="list-group-item p-4">
                        <div className="d-flex gap-3">
                            <div className="flex-grow-1">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-muted mb-1 d-flex flex-wrap">
                                            <a href="#" className="me-3">
                                                Computer Arabia
                                            </a>
                                        </div>
                                        {console.log(datas[0].Items)}
                                        {datas[0].Items && datas[0].Items.map((item, index) => (
                                            <div key={index} className="mb-3">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p>{item.quantity}x {item.itemname}</p>
                                                    {item.serial_no === '' ? (
                                                        <div className="d-flex align-items-center">
                                                              <input
                                                                type="text"
                                                                className="form-control form-control-sm me-2"
                                                                placeholder="Serial No."
                                                                value={newSerialNo[item.itemId] || ''}
                                                                onChange={(e) => handleSerialNoChange(item.itemId, e.target.value)}
                                                            />
                                                            <Button
                                                                customclass="btn btn-primary btn-sm"
                                                                title={<i className='bx bx-save'></i>}
                                                                action={() => handleUpdateSerialTransaction(item.itemId)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <span className="text-muted">Serial No.: {item.serial_no}</span>
                                                    )}
                                                </div>
                                                {/* <p className="text-primary mb-0">{currencyFormat(item.amount)}</p>
                                                <p className="text-danger mb-0">With Operating System: {item.WithOS}</p> */}
                                                 <p className="text-primary mb-0">
                                                    {currencyFormat(item.amount)}{' '}
                                                    {item.category === 'Accessories' && (
                                                    <span className="text-danger" style={{ marginLeft: '155px' }}>
                                                        With Operating System: {item.WithOS ? item.WithOS : "No"}
                                                    </span>
                                                    )}
                                                </p>
                                            </div>
                                        ))}
                                        {/* <Badge variant="bg-label-success" title="In Stock" /> */}
                                        {datas[0].transaction_status.toUpperCase() === 'PAID' && (
                                            <>
                                            <div className="input-group mt-3">
                                                {datas[0].notes.trim() === '' ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder="Invoice No."
                                                            value={newInvoiceNo}
                                                            onChange={(e) => setNewInvoiceNo(e.target.value)}
                                                        />
                                                        <Button customclass="btn btn-primary btn-sm" title={<i className='bx bx-save'></i>} action={() => updateInvoiceNoTransaction(newInvoiceNo)} />
                                                    </>
                                                ) : (
                                                    <span className="text-muted">Invoice No.: {datas[0].notes}</span>
                                                )}
                                            </div>
                                            </>
                                        )}
                                        {/* <div className="mt-3">
                                            <textarea className="form-control form-control-sm" placeholder="Notes"
                                                value={data?.notes}
                                                onChange={(e) => updateNotes(e.target.value)} 
                                            />
                                        </div> */}
                                        <button className="btn btn-primary me-2 text-white mt-2" onClick={fetchData}><i className="bx bx-refresh me-1"></i>Reload </button>
                                    </div>
                                   
                                    <i><p>Note: After clicking the save button for invoice no. and serial no. please click the reload before printing the invoice </p></i>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="col-xl-4">
                <div className="border rounded p-4 pb-3">
                    <h6>
                        <i className='bx bx-money' ></i> Price Details
                    </h6>
                    <dl className="row mb-0">
                        <dt className="col-6 fw-normal">Order Total</dt>
                        <dd className="col-6 text-end pr-0">{currencyFormat(datas[0].total_amount)}</dd>
                        <dt className="col-sm-6 fw-normal">Delivery Charges</dt>
                        {datas[0].deliveryType === 'Delivery' ? (
                            <dd className="col-sm-6 text-end pr-0">
                            <Badge variant="bg-label-success ms-1" title="50 QAR" />
                            </dd>
                        ) : (
                            <dd className="col-sm-6 text-end pr-0">
                            <Badge variant="bg-label-success ms-1" title="Free" />
                            </dd>
                        )}
                    </dl>
                    <hr className="mx-n4" />
                    <dl className="row mb-0">
                        <dt className="col-6">Total</dt>
                        {datas[0].deliveryType === 'Delivery' ? (
                        <dd className="col-6 fw-medium text-end mb-0">{currencyFormat(datas[0].total_amount + 50)}</dd>
                        ) : (
                        <dd className="col-6 fw-medium text-end mb-0">{currencyFormat(datas[0].total_amount)}</dd>
                        )}
                    </dl>
                </div>
            </div>
            <div >
                <div className="d-flex align-items-start justify-content-start mt-3">
                    {datas[0].transaction_status === 'Paid' && datas[0].deliveryType === 'Store Pickup' && datas[0].transaction_type === 'Pay in Cash' && (
                        <>
                            <BlobProvider document={<Docs data={datas[0]} serial={datas[0].serial_no} invNo={datas[0].notes} />}>
                            {({ url }) => (
                                <a className="btn btn-warning me-2 text-white" href={url} target="_blank">
                                <i className="bx bx-printer me-1"></i>Print Invoice
                                </a>
                            )}
                            </BlobProvider>
                        </>
                        )}
                        {datas[0].transaction_status === 'Pending' && datas[0].deliveryType === 'Store Pickup' && datas[0].transaction_type === 'Pay in Cash' && (
                                        <>
                                            <button className="btn btn-danger me-2 text-white" onClick={updatePaymentStatusToPaid}>
                                                <i className="bx bx-money me-1"></i>Paid
                                            </button>
                                            {paymentUpdated && <span>Payment status updated!</span>}
                                        </>
                        )}
                    {datas[0].transaction_type === 'Downpayment' && datas[0].outstandingBalance !== 0 && (
                        <Button customclass="btn btn-primary me-2" title="Notify for Full Payment" icon="bx bx-bell me-1" action={notifyForFullPayment} isLoading={isBtnLoading}/>
                    )}
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3">
                {!filterKey && (
                    <>
                        <div className="ms-3">
                            {/* {data?.transaction_type !== 'Online Payment' && data?.pickup_address !== '' && (
                                <button className="btn btn-success me-2" onClick={notifyForPickup} disabled={isBtnLoading}>
                                    <i className="bx bx-bell me-1"></i> Notify Pickup
                                </button>
                            )} */}
                        </div>
                        {/* <div className="btn-group ms-3">
                        {data?.transaction_type === 'Online Payment' && data?.pickup_address !== '' && (
                            <button type="button" className={`btn btn-primary dropdown-toggle ${dropdownOpen ? 'show' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
                                <i className='bx bxs-package me-2'></i> {shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : shipment_status}
                            </button>
                            )}
                            <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 40px)' }}>
                                {shipments_label.map((item, index) => (
                                    <li key={index} onClick={() => setDropdownOpen(false)}>
                                        <a href="#" className="dropdown-item d-flex align-items-center" onClick={() => setShipmentStatus(item.value)}>
                                            {item.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                        {datas[0].transaction_type === 'Online Payment' && datas[0].deliveryType === 'Store Pickup' ? (
                            <>
                            <button type="button" className="btn btn-danger" onClick={() => updateShipmentStatusStorePU()}>
                                Pickup
                            </button>
                             {shipmentUpdated && <span>Shipment status updated!</span>}
                             </>
                        ) : (
                            <div className="btn-group ms-3">
                                {datas[0].transaction_type === 'Online Payment' && datas[0].pickup_address !== '' && (
                                    <button type="button" className={`btn btn-primary dropdown-toggle ${dropdownOpen ? 'show' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
                                        <i className='bx bxs-package me-2'></i> {shipment_status === 'Tablet Received by Owner' ? 'Package Received by Owner' : shipment_status}
                                    </button>
                                )}
                                <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 40px)' }}>
                                    {shipments_label.map((item, index) => (
                                        <li key={index} onClick={() => setDropdownOpen(false)}>
                                            <a href="#" className="dropdown-item d-flex align-items-center" onClick={() => setShipmentStatus(item.value)}>
                                                {item.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                    </>
                )}
            </div>
        </div>
           )}
        </>
    )
}

export default ContentComponent
// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import ProductsCatalog from './ProductsCatalog';
// import LoginForm from './components/SignIn'; // Import your login form component
// import Dashboard from './pages/Dashboard';
// import './assets/vendor/fonts/boxicons.css';
// import './assets/vendor/fonts/fontawesome.css';
// import './assets/vendor/fonts/flag-icons.css';
// import './assets/vendor/css/rtl/core.css';
// import './assets/vendor/css/rtl/theme-default.css';
// import './assets/css/app-logistics-dashboard.css';
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';

// const root = createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Router basename={"/computer-arabia"}>
//       <Routes>
//         <Route path="/*" element={<ProductsCatalog />} />
//         <Route path="/dashboard/*" element={<Dashboard />} />
//       </Routes>
//     </Router>
//   </React.StrictMode>
// );

// reportWebVitals();

// index.js

import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ProductsCatalog from './ProductsCatalog';
import Dashboard from './pages/Dashboard';
import SignIn from './components/SignIn';
import { ToastContainer } from 'react-toastify';

import './assets/vendor/fonts/boxicons.css';
import './assets/vendor/fonts/fontawesome.css';
import './assets/vendor/fonts/flag-icons.css';
import './assets/vendor/css/rtl/core.css';
import './assets/vendor/css/rtl/theme-default.css';
import './assets/css/app-logistics-dashboard.css';
import 'react-toastify/dist/ReactToastify.css';

const root = createRoot(document.getElementById('root'));

const isAuthenticated = () => {
  // Check if the user is authenticated
  // For testing purposes, I'm using local storage to simulate authentication status
  const authenticated = localStorage.getItem('loggedIn') === 'true';
  if (authenticated) {
    // console.log('User is authenticated!');
  }
  return authenticated;
};


const Routing = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // console.log("UseEffect: Checking authentication status");
    setAuthenticated(isAuthenticated());
  }, []);

  // console.log("Rendering Routing...", authenticated); // Add this line to log the value of authenticated

  return (
    <React.StrictMode>
      <Router>
        <Routes>
        <Route path="/*" element={<ProductsCatalog />} />
          <Route path="/dashboard/*" element={authenticated ? <Dashboard /> : <ProductsCatalog />} />
        </Routes>
        <ToastContainer />
      </Router>
    </React.StrictMode>
  );
};

root.render(<Routing />);

reportWebVitals();
import moment from 'moment';
import React, {useState, useEffect} from 'react'
import ReactApexChart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';

const OrderChart = ({data, isLoading, filterKey, filterMonth}) => {

  const [filteredOnlinePayment, setFilteredOnlinePayment] = useState([]);
  const [filteredPayInCash, setFilteredPayInCash] = useState([]);

  const daysInMonth = moment().month(filterMonth).daysInMonth();
  const categories = Array.from({ length: daysInMonth }, (_, index) =>
    moment().month(filterMonth).date(index + 1).format('D MMM')
  );

  const chartColors = {
      line: {
        series1: '#ffab00',
        series2: '#40aaf2',
        series3: '#7367f029',
      },
  };
  const shipmentConfig = {
      series: [
          {
            name: 'Online Payment',
            type: 'column',
            data: filteredOnlinePayment
          },
          {
            name: 'Pay in Cash',
            type: 'line',
            data: filteredPayInCash
          }
      ],
      options: {
        chart: {
          height: 150,
          type: 'line',
          stacked: false,
          parentHeightOffset: 0,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: false
          }
        },
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColors: chartColors.line.series2,
          hover: {
            size: 6
          },
          borderRadius: 4
        },
        stroke: {
          curve: 'smooth',
          width: [0, 3],
          lineCap: 'round'
        },
        legend: {
          show: true,
          position: 'bottom',
          markers: {
            width: 8,
            height: 8,
            offsetX: -3
          },
          height: 40,
          offsetY: 10,
          itemMargin: {
            horizontal: 10,
            vertical: 0
          },
          fontSize: '15px',
          fontWeight: 400,
          labels: {
            colors: '##566a7f',
            useSeriesColors: false
          },
          offsetY: 10
        },
        colors: [chartColors.line.series1, chartColors.line.series2],
        fill: {
          opacity: [1, 1]
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            startingShape: 'rounded',
            endingShape: 'rounded',
            borderRadius: 4
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: categories,
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Online Payment',
          },
          tickAmount: 5,
          min: 0,
          max: 20
        }, {
          opposite: true,
          title: {
            text: 'Pay in Cash'
          }
        }]
      }
  };

  useEffect(() => {
    const filteredOnlinePaymentData = {};
    const filteredPayInCashData = {};
    
    // Initialize data structures for each day of the month
    for (let i = 1; i <= 31; i++) {
      filteredOnlinePaymentData[i] = 0;
      filteredPayInCashData[i] = 0;
    }

    // Count occurrences for each day
    data.forEach(item => {
      const dayOfMonth = moment(item.transDateTime).add(1, 'day').date(); // Add 1 day
      if (item.transaction_type === 'Online Payment' && moment(item.transDateTime).format('MM') === filterKey) {
        filteredOnlinePaymentData[dayOfMonth]++;
      } else if (item.transaction_type === 'Pay in Cash' && moment(item.transDateTime).format('MM') === filterKey) {
        filteredPayInCashData[dayOfMonth]++;
      }
    });

    // Convert count data to array format
    const onlinePaymentDataArray = Object.keys(filteredOnlinePaymentData).map(day => filteredOnlinePaymentData[day]);
    const payInCashDataArray = Object.keys(filteredPayInCashData).map(day => filteredPayInCashData[day]);

    // Update state with count data
    setFilteredOnlinePayment(onlinePaymentDataArray);
    setFilteredPayInCash(payInCashDataArray);
  }, [data, filterKey]);

  return (
      <div className="shipmentStatisticsChart">
          {isLoading ? <Spinner variant="primary" /> : (
            <ReactApexChart
                options={shipmentConfig.options}
                series={shipmentConfig.series}
                type="line"
                height={366}
            />
        )}  
          
      </div>
  )
}

export default OrderChart;


// //-----------------------pie chart-------------------------//

// import moment from 'moment';
// import React, { useState, useEffect } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import { Spinner } from 'react-bootstrap';

// const OrderChart = ({ data, isLoading, filterKey, filterMonth }) => {
//   const [dailySalesOnlinePayment, setDailySalesOnlinePayment] = useState({});
//   const [dailySalesPayInCash, setDailySalesPayInCash] = useState({});

//   const daysInMonth = moment().month(filterMonth).daysInMonth();
//   const categories = Array.from({ length: daysInMonth }, (_, index) =>
//     moment().month(filterMonth).date(index + 1).format('D MMM')
//   );

//   const chartColors = ['#ffab00', '#40aaf2'];

//   const barGraphConfig = {
//     series: [
//       {
//         name: 'Online Payment',
//         data: Object.values(dailySalesOnlinePayment)
//       },
//       {
//         name: 'Pay in Cash',
//         data: Object.values(dailySalesPayInCash)
//       }
//     ],
//     options: {
//       chart: {
//         height: 350,
//         type: 'bar'
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: '50%'
//         }
//       },
//       xaxis: {
//         categories: categories
//       },
//       colors: chartColors,
//       legend: {
//         position: 'bottom'
//       }
//     }
//   };

//   useEffect(() => {
//     const dailySalesOnline = {};
//     const dailySalesCash = {};

//     data.forEach(item => {
//       const dayOfMonth = moment(item.transDateTime).date();

//       if (item.transaction_type === 'Online Payment' && moment(item.transDateTime).format('MM') === filterKey) {
//         dailySalesOnline[dayOfMonth] = (dailySalesOnline[dayOfMonth] || 0) + 1;
//       } else if (item.transaction_type === 'Pay in Cash' && moment(item.transDateTime).format('MM') === filterKey) {
//         dailySalesCash[dayOfMonth] = (dailySalesCash[dayOfMonth] || 0) + 1;
//       }
//     });

//     setDailySalesOnlinePayment(dailySalesOnline);
//     setDailySalesPayInCash(dailySalesCash);
//   }, [data, filterKey]);

//   return (
//     <div className="barGraph">
//       {isLoading ? (
//         <Spinner variant="primary" />
//       ) : (
//         <ReactApexChart
//           options={barGraphConfig.options}
//           series={barGraphConfig.series}
//           type="bar"
//           height={350}
//         />
//       )}
//     </div>
//   );
// };

// export default OrderChart;

import React, { useEffect, useState } from 'react';
import '../assets/css/spinkit.css';
import { Nav, Offcanvas } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { Link, Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import companyLogo from '../assets/logoo.png';
import Footer from '../components/Footer';
import NavMenu from '../components/Nav';
import Aside from '../Dashboard/Aside';
import '../Product.css';
import BreadCrumb from '../Dashboard/BreadCrumb';
import DashboardContainer from '../container/DashboardContainer';
import TransactionContainer from '../container/Transactions';
import NotificationsContainer from '../container/Notifications';
import { ToastContainer, toast } from 'react-toastify';
import { io } from "socket.io-client";
import ProductsContainer from '../container/ProductsContainer';

const socket = io("https://carabiashop.onrender.com");
// const socket = io("http://localhost:5000");

const Dashboard = () => {
  const { instance } = useMsal();
  let navigate = useNavigate();
  let location = useLocation();
  const [avatarUrl, setAvatarUrl] = useState(null);

  const [ showToggleUserMenu, setShowToggleUserMenu ] = useState(false);
  const [ showToggleSideMenu, setShowToggleSideMenu ] = useState(false);
  const [ showToggleNotifMenu, setShowToggleNotifMenu ] = useState(false);
  const [ notifications, setNotifications] = useState([]);
  const storedAccountData = localStorage.getItem('accountData');
  const accounts = storedAccountData ? JSON.parse(storedAccountData) : null;
// const socket = io("https://computer-arabia-laptop-ordering.onrender.com");
  const socket = io("https://carabiashop.onrender.com");
  
  useEffect(() => {
    socket.on("getNotification", (data) => {
      setNotifications((prev) => [...prev, data]);
    });

    return () => {
      socket.off("getNotification");
    };
  }, []);

  const handleToggleUserMenu = () => {
    setShowToggleUserMenu(!showToggleUserMenu);
  };

  const handleToggleSideMenu = () => {
    setShowToggleSideMenu(true);
  };

  const handleToggleNotifMenu = () => {
    setShowToggleNotifMenu(!showToggleNotifMenu);
  };

  // const handleLogout = async () => {
  //   try {
  //     await instance.logoutPopup()
  //     navigate('/');
  //   } catch (error) {
  //     console.error('Logout error:', error);
  //   }
  // };

  const handleLogout = async () => {
    try {
      // Clear localStorage
      localStorage.clear();
  
      // Clear cache
      if (caches) {
        await caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
      }
  
      // Logout
      // await instance.logoutPopup();
  
      // Navigate to '/'
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };
  

  const handleReadNotif = (orderId) => {
    const updatedNotifications = notifications.map((notification) => {
        if (notification.orderId === orderId) {
            return { ...notification, read: true };
        }
        return notification;
    });

    setNotifications(updatedNotifications);
    handleToggleNotifMenu()
    // navigate(`/dashboard/transactions?orderId=${orderId}`)
    navigate(`/dashboard/transactions`)
  };

  // useEffect(() => {
  //   const getAvatar = async () => {
  //     if (accounts.length > 0) {
  //       const accessTokenRequest = {
  //         scopes: ["user.read"],
  //         account: accounts[0], // Assuming only one account is used
  //       };

  //       try {
  //         const response = await instance.acquireTokenSilent(accessTokenRequest);
  //         const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
  //           headers: {
  //             Authorization: `Bearer ${response.accessToken}`,
  //           },
  //         });
  //         const blob = await graphResponse.blob();
  //         const imageUrl = URL.createObjectURL(blob);
  //         setAvatarUrl(imageUrl);
  //       } catch (error) {
  //         console.error("Error getting profile avatar:", error);
  //       }
  //     }
  //   };

  //   getAvatar();
  // }, [instance, accounts]);

  // if (accounts.length === 0) {
  //   return '';
  // }

  

  // console.log('accounts', accounts)
  

  return (
    
    <main>
    <div>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <NavMenu 
            showToggleNotifMenu={showToggleNotifMenu}
            showToggleUserMenu={showToggleUserMenu}
            toggleSideMenu={handleToggleSideMenu}
            toggleNotifMenu={handleToggleNotifMenu}
            toggleUserMenu={handleToggleUserMenu}
            logout={handleLogout} 
            accountData={accounts}
            notifications={notifications}
            updateNotif={handleReadNotif}
            avatarUrl={avatarUrl}
          />
          <div className="layout-page">
            <div className="content-wrapper">
              <Aside showToggleSideMenu={showToggleSideMenu} path={location.pathname}/>
              <div className="container-xxl flex-grow-1 container-p-y overflow-hidden">
                {/* <BreadCrumb title={location.pathname}/> */}
                <div className="row">
                  <Routes>
                    <Route path="/" element={<DashboardContainer accountsData={accounts}/>} />
                    <Route path="/products" element={<ProductsContainer />} />
                    <Route path="/transactions" element={<TransactionContainer />} />
                    <Route path="/notifications" element={<NotificationsContainer />} />
                  </Routes>
                </div>

              </div>
              <Footer />
              <div className="content-backdrop fade" />
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={showToggleSideMenu} onHide={() => setShowToggleSideMenu(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
            <a href="#" className="app-brand-link gap-2">
              <span className="app-brand-logo">
                <img src={companyLogo} alt="logo" width={50}/>
              </span>
              <span className="app-brand-text menu-text fw-bold text-dark">Computer Arabia</span>
            </a>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Nav className="d-block">
              {/* Dashboards */}
              <li className="menu-item active">
                <Nav.Link as={Link} to="/" className="menu-link" onClick={() => setShowToggleSideMenu(false)}>
                  <i className='bx bxs-dashboard' ></i> Dashboard 
                </Nav.Link>
              </li>
              <li className="menu-item active">
                <Nav.Link as={Link} to="/dashboard/products" className="menu-link" onClick={() => setShowToggleSideMenu(false)}>
                <i className='bx bx-tab'></i> Products
                </Nav.Link>
              </li>
              <li className="menu-item">
                <Nav.Link as={Link} to="/dashboard/transactions" className="menu-link" onClick={() => setShowToggleSideMenu(false)}>
                <i className='bx bx-receipt' ></i> Transactions 
                </Nav.Link>
              </li>
              {/* <li className="menu-item">
                <Nav.Link as={Link} to="/dashboard/notifications" className="menu-link" onClick={() => setShowToggleSideMenu(false)}>
                <i className='bx bxs-package'></i> Notifications
                </Nav.Link>
              </li> */}
              {/* <li className="menu-item">
                <Nav.Link as={Link} to="/dashboard/transactions/completed" className="menu-link" onClick={() => setShowToggleSideMenu(false)}>
                <i className='bx bx-check-square'></i> Completed
                </Nav.Link>
              </li> */}
          </Nav>
          </Offcanvas.Body>
      </Offcanvas>
    </div>
    </main>
  
  );
};

export default Dashboard;
// import React, { useState } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';

// const VerifyStaffIDModal = ({ show, handleClose, handleVerify }) => {
//   const [staffID, setStaffID] = useState('');

//   const handleInputChange = (e) => {
//     setStaffID(e.target.value);
//   };

//   const handleSubmit = () => {
//     handleVerify(staffID);
//   };

//   return (
//     <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
//       <Modal.Header>
//         <Modal.Title>Verify Staff ID</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Form>
//           <Form.Group controlId="formStaffID">
//             <Form.Label>Staff ID</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter your Staff ID"
//               value={staffID}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="primary" onClick={handleSubmit}>
//           Verify
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default VerifyStaffIDModal;

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../LockModal.css'; // Import the CSS file

const VerifyStaffIDModal = ({ show, handleClose, handleVerify }) => {
  const [staffID, setStaffID] = useState('');
  const [isValid, setIsValid] = useState(true);

  const staffData = [
    {
      "staffID": "456784",
      "name": "John Doe",
      "position": "Manager"
    },
    {
      "staffID": "678902",
      "name": "Jane Smith",
      "position": "Developer"
    },
    {
      "staffID": "543215",
      "name": "Alice Johnson",
      "position": "Designer"
    },
    {
      "staffID": "987654",
      "name": "Bob Brown",
      "position": "Tester"
    }
  ];

  const verifyStaffID = () => {
    const staff = staffData.find((item) => item.staffID === staffID);
    if (staff) {
      handleVerify(staffID); // Pass the verified staff ID to the parent component
      handleClose();
    } else {
      setIsValid(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom-modal" style={{marginTop:"100px"}}>
    <Modal.Header className="text-center">
      <Modal.Title style={{ margin: "0 auto", fontWeight: "Bold" }} >
        This website is for Qatar Energy Employees only.
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5 className="text-muted text-center" style={{fontWeight: "Bold", marginTop:"-20px"}}>Please enter your Staff ID to proceed</h5>
      <Form>
        <Form.Group controlId="formStaffID">
          {/* <Form.Label>Staff ID</Form.Label> */}
          <Form.Control
            type="text"
            placeholder="Enter your staff ID"
            value={staffID}
            onChange={(e) => {
              setStaffID(e.target.value);
              setIsValid(true);
            }}
            isInvalid={!isValid}
          />
          <Form.Control.Feedback type="invalid">
            Invalid Staff ID. Please try again.
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
      <Button variant="primary" onClick={verifyStaffID}>
        Verify
      </Button>
    </Modal.Footer>
  </Modal>  
  );
};

export default VerifyStaffIDModal;

// import React, { useState, useEffect } from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import { publicIpv4 } from 'public-ip';
// import ipRangeCheck from 'ip-range-check';
// import '../LockModal.css'; // Import the CSS file

// const VerifyStaffIDModal = ({ show, handleClose, handleVerify }) => {
//   const [isAllowed, setIsAllowed] = useState(null);

//   useEffect(() => {
//     if (show) {
//       verifyIP();
//     }
//   }, [show]);

//   const verifyIP = async () => {
//     try {
//       const clientIP = await publicIpv4();
//       console.log('Client IP:', clientIP);
//       const allowedIPRange = "176.202.110.121/24"; 

//       const isIPAllowed = ipRangeCheck(clientIP, allowedIPRange);
//       setIsAllowed(isIPAllowed);
//       if (isIPAllowed) {
//         handleVerify(); 
//         handleClose(); 
//       }
//     } catch (error) {
//       console.error('Error verifying IP:', error);
//       setIsAllowed(false);
//     }
//   };

//   return (
//     <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} dialogClassName="custom-modal" style={{ marginTop: "100px" }}>
//       <Modal.Header className="text-center">
//         <Modal.Title style={{ margin: "0 auto", fontWeight: "bold" }}>
//           This website is for Qatar Energy Employees only.
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         {isAllowed === null ? (
//           <h5 className="text-muted text-center" style={{ fontWeight: "bold", marginTop: "-20px" }}>
//             Verifying your access...
//           </h5>
//         ) : (
//           !isAllowed && (
//             <h5 className="text-muted text-center" style={{ fontWeight: "bold", marginTop: "-20px" }}>
//               Access denied. Your IP address is not allowed.
//             </h5>
//           )
//         )}
//       </Modal.Body>
//       {/* No footer needed since modal closes automatically */}
//     </Modal>
//   );
// };

// export default VerifyStaffIDModal;

import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

const PieChart = ({ data, isLoading, filterKey }) => {
  const [productSales, setProductSales] = useState({});

  const chartColors = ['#ffab00', '#40aaf2'];

  const pieChartConfig = {
    series: Object.values(productSales),
    options: {
      chart: {
        type: 'pie',
        height: 350
      },
      labels: Object.keys(productSales),
      colors: chartColors,
      legend: {
        position: 'bottom'
      }
    }
  };

  useEffect(() => {
    console.log("Data:", data); 
    
    const productSalesData = {};
  
    data.forEach(transaction => {
      transaction.Items.forEach(item => {
        const productName = item.itemname ? item.itemname : "Unknown Product";
        const transDateTime = moment(transaction.transDateTime); 
        
        if (transDateTime.format('MM') === filterKey) {
          productSalesData[productName] = (productSalesData[productName] || 0) + 1;
        }
      });
    });
  
    console.log("Product Sales Data:", productSalesData); 
  
    setProductSales(productSalesData);
  }, [data, filterKey]);

  return (
    <div className="pieChart">
      {isLoading ? (
        <Spinner variant="primary" />
      ) : (
        <ReactApexChart
          options={pieChartConfig.options}
          series={pieChartConfig.series}
          type="pie"
          height={250}
        />
      )}
    </div>
  );
};

export default PieChart;

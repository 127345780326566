import React, {useState, useEffect} from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { io } from "socket.io-client";
import NotFound from './NotFound';

const ThankYou = () => {
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    const transaction_number = searchParams.get("transactionNumber");

    // const updateTransaction = async () => {
    //     const formData = [
    //         {
    //             order_id: orderId,
    //             shipment_status: 'Order Receieved',
    //             reference_number: transaction_number
    //         }
    //     ];

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(formData),
    //         });
    //         if (response.ok) {
    //             // console.log(response);
    //         } else {
    //             console.error('Failed to update transaction');
    //         }
    //     } catch (error) {
    //         console.error('Error updating transaction:', error);
    //     }
    // };

    const updateTransaction = async () => {
        try {
            // Fetch transaction status
            const transactionInfoResponse = await fetch(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetCarabia_transactions_ByOrderID?orderid=${orderId}`);
            const transactionInfo = await transactionInfoResponse.json();
    
            // Check if transaction info is available
            if (transactionInfo && transactionInfo.length > 0) {
                const transactionStatus = transactionInfo[0].shipment_status;
    
                // Check if transaction status is 'Pending'
                if (transactionStatus === '') {
                    // Proceed to update transaction
                    const formData = [
                        {
                            order_id: orderId,
                            shipment_status: 'Order Received',
                            reference_number: transaction_number
                        }
                    ];
    
                    const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/update_transc`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                    });
    
                    if (!response.ok) {
                        console.error('Failed to update transaction');
                    }
                } else {
                    console.log('Transaction status is not Pending, skipping update.');
                }
            } else {
                console.error('Transaction information not found.');
            }
        } catch (error) {
            console.error('Error updating transaction:', error);
        }
    };    

    const saveNotifications = async () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
        const postData = [
            {
                orderId: orderId,
                title: 'New Order Confirmation',
                notifId: randomString,
            }
        ];
        try {
            const response = await fetch(`${process.env.REACT_APP_PHP_URL}/test-email/notifications.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                },
            body: JSON.stringify(postData),
            });

            const data = await response.json();
            if (data['success']) {
                // console.log('Notifications Success', data);
            }
            // console.log('Response from server:', data);
        } catch (err) {
            console.error('Error:', err);
        }
    }

    useEffect(() => {
        if (!orderId) {
            return; 
        }
        const socket = io("https://carabiashop.onrender.com");
        // const socket = io("http://localhost:5000");
        const currentTimestamp = new Date().toLocaleString();
        socket.emit("sendNotification", {
            title: 'New Order Confirmation',
            orderId: orderId,
            dateTime: currentTimestamp,
            read: false,
        });
        return () => {
            saveNotifications()
            socket.disconnect();
        };
    }, [orderId]);

    useEffect(() => {
        if (!orderId) {
            return; 
        }
        updateTransaction();
    }, []);

    if (!orderId) {
        return <NotFound />;
    }

    return (
        <section className="section-py">
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row text-center justify-content-center gap-sm-0 gap-3">
                    <div className="col-12">
                        <h4 className='mb-4'>Thank you for your purchasing</h4>
                        <p>We have received your order <b>{orderId}</b>, please check your email for receipt details.</p>
                        <p>You will be issued with an electronic invoice.</p>
                        <p>
                            <Link to="/track-order">Track your order here</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ThankYou;
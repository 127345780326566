import React, { useState } from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 16px;
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
`;

const ProgressBar = styled.div`
  position: relative;
  background-color: #f0f0f0;
  height: 50px;
  width: 100%;
  border-radius: 10px;
`;

const ProgressStep = styled.div`
  position: absolute;
  height: 100%;
  background-color: #00c1ff;
  transition: width 0.4s ease;
  border-radius: 10px;
`;

const ProgressIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
`;

const ProgressIcon = styled.div`
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
`;

const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ProgressLabel = styled.span`
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #666;
`;

function TrackOrders() {
  const [orderId, setOrderId] = useState('');
  const [shipmentStatus, setShipmentStatus] = useState('');
  const [error, setError] = useState(null);
  const [showNotFound, setShowNotFound] = useState(false); 

  const handleChange = (event) => {
    setOrderId(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetCarabia_transactions_ByOrderID?orderid=${orderId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      if (data.length > 0) {
        const status = data[0].shipment_status;
        setShipmentStatus(status);
        setShowNotFound(false); 
      } else {
        setShipmentStatus('No data found');
        setShowNotFound(true); 
      }
      setError(null);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch data. Please try again.');
    }
  };

  const calculateProgress = () => {
    switch (shipmentStatus) {
      case 'Order Received':
        return '25%';
      case 'Preparing Item':
        return '50%';
      case 'Delivering':
        return '75%';
      case 'Tablet Received by Owner':
        return '100%';
      default:
        return '0%';
    }
  };

  return (
    <MainContainer>
      <center>
        <form onSubmit={handleSubmit}>
          <input style={{ margin: "20px" }} className="form-control w-50" type="text" value={orderId} onChange={handleChange} placeholder="Enter Order ID" />
          <button style={{ margin: "20px" }} className="btn btn-success w-40" type="submit"><i className="bx bx-search-alt me-1" /> Track Order </button>
        </form>
      </center>
      {error && <div>{error}</div>}
      <center>{showNotFound && <div>No Order Found</div>} </center>
      <ProgressContainer>
        <ProgressBar>
          <ProgressStep style={{ width: calculateProgress() }} />
          <ProgressIconContainer>
            <ProgressIcon active={shipmentStatus === 'Order Received'}>🛒</ProgressIcon>
            <ProgressIcon active={shipmentStatus === 'Preparing Item'}>📦</ProgressIcon>
            <ProgressIcon active={shipmentStatus === 'Delivering'}>🚚</ProgressIcon>
            <ProgressIcon active={shipmentStatus === 'Tablet Received by Owner'}>📱</ProgressIcon>
          </ProgressIconContainer>
        </ProgressBar>
        <ProgressLabels>
          <ProgressLabel>Order Received</ProgressLabel>
          <ProgressLabel>Preparing Item</ProgressLabel>
          <ProgressLabel>Delivering to Client</ProgressLabel>
          <ProgressLabel>Package Recieved</ProgressLabel>
        </ProgressLabels>
      </ProgressContainer>
    </MainContainer>
  );
}

export default TrackOrders;

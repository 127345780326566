import moment from "moment";

export const dateFullFormat = (date) => {
    return moment(date).format('MMMM DD, YYYY')
}

export const dateTimeFullFormat = (date) => {
    return moment(date).format('MMMM DD, YYYY hh:mm A')
}

export const currencyFormat = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'QAR',
    }).format(amount)
}

export const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Product.css';
import { CircularProgress } from '@mui/material';
import TAG from '../assets/book_now.png';
import translations from '../translations/translations.json';
import { useInView } from 'react-intersection-observer';

const Products = (props) => {
  const { ar: arTranslations, en: enTranslations } = translations;
  const { sharedTablet, sharedTablet2, accessories, Others, handlePurchase, handleBookNow, handleAddToCart, error, isLoading, language, handleLanguageChange } = props;
  const [showTabletSpecs, setShowTabletSpecs] = useState(false);
  const [accessoryCollapse, setAccessoryCollapse] = useState({});
  const productsSectionRef = useRef(null);
  const { ref: productsInViewRef, inView: productsInView } = useInView({ triggerOnce: false });

  const handleViewProductsClick = () => {
    if (productsSectionRef.current) {
      window.scrollTo({
        top: productsSectionRef.current.offsetTop - 100, 
        behavior: 'smooth',
      });
    }
  };

  const toggleCollapse = (accessoryId) => {
    setAccessoryCollapse((prevCollapse) => ({
      ...prevCollapse,
      [accessoryId]: !prevCollapse[accessoryId],
    }));
  };

  // useEffect(() => {
  //   window.scrollTo(0, -500);
  // }, []);

  useEffect(() => {
    if (productsInView && productsSectionRef.current) {
      productsSectionRef.current.classList.add('animate__animated', 'animate__zoomIn');
    }
  }, [productsInView]);

  if (isLoading) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          textAlign: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100px',
            height: '100px',
          }}
        >
          <CircularProgress
            size={100}
            thickness={3}
            color="primary"
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              fontSize: '18px',
            }}
          >
            <span className="text-white display-5">{'Loading...'}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {error && sharedTablet === undefined && sharedTablet2 === undefined ? (
        <div role="alert">
          <center>
            <div className="misc-wrapper m-4">
              <h2 className="mb-2 mx-2">Oops! :(</h2>
              <p className="mb-4 mx-2">😖 Failed to get data from the server. Please try again later.</p>
            </div>
          </center>
        </div>
      ) : (
        <>
          <section className="section-py">
            <div className="container p-3 mt-4">
              <div className="row">
                <div className="mb-4 col-md-7 p-4">
                  <center className="mt-4 animation2">
                    <img src={TAG} width={'100%'} />
                  </center>
                </div>
                <div className="mb-4 col-md-5 p-4" style={{ animation: "fadeInUp 1s ease-in-out" }}>
                  <center className="mt-4 animation2">
                    <h1 style={{ animation: "slideInDown 1s ease-in-out" }}>With Greater Space & Greater Services</h1><br/>
                    <h3 style={{ animation: "slideInDown 1s ease-in-out" }}>We offer a broad range of information technology products and services to companies across sectors</h3>
                    <button className="btn-custom mt-4" style={{ animation: "fadeInUp 1s ease-in-out" }} onClick={handleViewProductsClick}>
                      View Products
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </section>
          <section id="products-section" ref={(el) => {
            productsSectionRef.current = el;
            productsInViewRef(el);
          }} className="section-py pt-4 mb-4" style={{ marginTop: '100px' }}>
            <div className="container pt-4 mb-4">
              <h3 className="text-center mb-4">{language === 'en' ? enTranslations['Products'] : arTranslations['Products']}</h3>
              <div className="row">
                {accessories.map((accessory) => (
                  <div className={`col-lg-6 mb-2 ${productsInView ? 'animate__animated animate__zoomIn' : ''}`} key={accessory.id}>
                    <div className="card h-100 border shadow-none">
                      <div className="card-body d-flex justify-content-between flex-wrap-reverse">
                        <div className="row">
                          <div className="col-md-8 col-8">
                            <h4 className="text-dark mb-4">
                              {accessory.Name}
                            </h4>
                            <span className="mb-1"> Get now for as low as</span>
                            <h4 className="text-bold text-primary">
                              QAR {accessory.price.toFixed(2)}
                            </h4>
                          </div>
                          <div className="col-md-4 col-4">
                            <img
                              className="img-fluid scaleX-n1-rtl"
                              src={accessory.image}
                              alt={accessory.Name}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="specs">
                              <ul className="list-unstyled">
                                {accessory.specs.split(', ').map((item, index) => (
                                  <li key={index}>
                                    {item.trim()}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <button className="btn btn-success" onClick={() => handlePurchase(accessory)}>
                              <i className="bx bx-credit-card me-2" />
                              {language === 'en' ? enTranslations['Purchase'] : arTranslations['Purchase']}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section className="section-py pt-4 mb-4">
            <div className="container pt-4 mb-4">
              {Others.length > 0 && (
                <h3 className="text-center mb-4">Other Products and Accessories</h3>
              )}
              <div className="row">
                {Others.map((other) => (
                  <div className={`col-lg-6 mb-2 ${productsInView ? 'animate__animated animate__zoomIn' : ''}`} key={other.id}>
                    <div className="card h-100 border shadow-none">
                      <div className="card-body d-flex justify-content-between flex-wrap-reverse">
                        <div className="row">
                          <div className="col-md-8 col-8">
                            <h4 className="text-dark mb-4">
                              {other.Name}
                            </h4>
                            <span className="mb-1"> Get now for as low as</span>
                            <h4 className="text-bold text-primary">
                              QAR {other.price.toFixed(2)}
                            </h4>
                          </div>
                          <div className="col-md-4 col-4">
                            <img
                              className="img-fluid scaleX-n1-rtl"
                              src={other.image}
                              alt={other.Name}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="specs">
                              <ul className="list-unstyled">
                                {other.specs.split(', ').map((item, index) => (
                                  <li key={index}>
                                    {item.trim()}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <button className="btn btn-success" onClick={() => handlePurchase(other)}>
                              <i className="bx bx-credit-card me-2" />
                              {language === 'en' ? enTranslations['Purchase'] : arTranslations['Purchase']}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Products;
import React from 'react'

const SearchComponent = ({ searchValue, onFilter }) => {
  return (
    <>
        <div className="row">
            <div className="col-12 d-flex justify-content-center justify-content-end p-0">
                <div className="input-group input-group-merge">
                    <span className="input-group-text">
                        <i className="bx bx-search" />
                    </span>
                    <input
                        type="search"
                        className="form-control"
                        aria-label="Search Input"
                        value={searchValue}
                        onChange={onFilter}
                        placeholder="Search"
                    />
                </div>
            </div>
        </div>
	</>
  )
}

export default SearchComponent
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Checkout.css'; 
import qatarFlag from '../assets/qatar.png'; // Import the Qatar flag image
import MapComponent from '../components/MapComponent';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cart, totalAmount, deliveryOption } = location.state || {};
  const [orderNumber, setOrderNumber] = useState('');
  const [qid, setQid] = useState('');
  const [sid, setSid] = useState('');
  const [qidError, setQidError] = useState('');
  const [sidError, setSidError] = useState('');
  const [name, setName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [paymentOption, setPaymentOption] = useState('payNow');
  const [sendViaEmail, setSendViaEmail] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [accessoryCheckboxes, setAccessoryCheckboxes] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  let merchantid = '6746953';
  let merchantkey = encodeURIComponent('vXMX87BoyYy55x+P'); // Taallum Group Test Merchant
  let orderid = orderNumber;

  useEffect(() => {
    const generateOrderNumber = () => {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';
      let orderNumber = 'CA'; // Start the order number with 'CA'
      
      // Generate 3 random letters
      for (let i = 0; i < 3; i++) {
        orderNumber += letters.charAt(Math.floor(Math.random() * letters.length));
      }
      
      // Generate 5 random numbers
      for (let i = 0; i < 5; i++) {
        orderNumber += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }
      
      return orderNumber;
    };

    const initialOrderNumber = generateOrderNumber();
    setOrderNumber(initialOrderNumber);
  }, []);

  useEffect(() => {
    // Check if all required fields are filled
    const isValid = qid !== '' && qid.length === 11 && sid !== '' && name !== '' && email !== '' && emailError === '' && mobile !== '' && mobile.length === 8 && mobileError === '' && (deliveryOption !== 'delivery' || deliveryAddress !== '');
    setIsFormValid(isValid);
  }, [qid, sid, name, email, emailError, mobile, mobileError, deliveryOption, deliveryAddress]);

  // Group items by ID and calculate total quantity of each item
  const groupedCart = cart.reduce((acc, item) => {
    const existingItem = acc.find(i => i.id === item.id);
    if (existingItem) {
      existingItem.qty += item.qty;
    } else {
      acc.push({ ...item, isAccessory: item.category === 'Accessories' }); // Add isAccessory property based on category
    }
    return acc;
  }, []);

  // const handleAccessoryCheckboxChange = (itemId) => {
  //   setAccessoryCheckboxes({
  //     ...accessoryCheckboxes,
  //     [itemId]: !accessoryCheckboxes[itemId] ? "yes" : "no"
  //   });
  // };

  const handleAccessoryCheckboxChange = (itemId) => {
    setAccessoryCheckboxes({
      ...accessoryCheckboxes,
      [itemId]: !accessoryCheckboxes[itemId] || accessoryCheckboxes[itemId] === "No" ? "Yes" : "No"
    });
  };
  
  const totalOrigPrice = groupedCart.reduce((total, item) => total + item.price, 0);
  const totalPrice = groupedCart.reduce((total, item) => {
    // const itemTotal = item.price;
    const withOSTotal = accessoryCheckboxes[item.id] === "Yes" ? 75 * item.qty : 0; // Add 75 for each item with "With OS"
    return total + item.price + withOSTotal;
  }, 0);
  
  const deliveryCharges = deliveryOption === 'delivery' ? 50 : 0;
  const totalPriceWithDelivery = totalPrice + deliveryCharges;

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const isPhoneValid = (phone) => {
    const phoneRegex = /^[0-9]{8}$/;
    return phoneRegex.test(phone);
  };

  const handleQIDChange = (e) => {
    let qidValue = e.target.value.trim();

    // Limit QID to 11 digits
    if (qidValue.length > 11) {
      qidValue = qidValue.slice(0, 11);
    }
    setQid(qidValue);

    // Check QID length
    if (qidValue.length !== 11) {
      setQidError('QID must be exactly 11 digits');
    } else {
      setQidError('');
    }
  };

  const handleSIDChange = (e) => {
    let sidValue = e.target.value.trim();

    setSid(sidValue);

  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.trim();
    setEmail(emailValue);
    if (!isEmailValid(emailValue)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };
  
  const handlePhoneChange = (e) => {
    let phoneValue = e.target.value.trim();
    setMobile(phoneValue);

    // Check mobile number length
    if (phoneValue.length !== 8) {
      setMobileError('Mobile number must be exactly 8 digits');
    } else {
      setMobileError('');
    }
  };

  const handleAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
  };

  const sendParameters = async () => {
    const merchantKey = merchantkey; 
    const merchantID = merchantid; 
    const orderID = orderNumber; 
    const fTotal = totalPriceWithDelivery.toFixed(2); 
    const FAQID = qid || "";
    const femail = (email.trim() !== "" ? email : "").trim();
    const fmobile = (mobile || "").replace(/\s/g, '');
    const itemname = groupedCart[0]?.Name || "Dell";
    const itemId = groupedCart[0]?.id || "";
    const url = `https://innovatixsystems.com/computer-arabia/computerarabiapay.php?merchantKey=${merchantKey}&merchantID=${merchantID}&orderID=${orderID}&fTotal=${fTotal}&FAQID=${FAQID}&femail=${femail}&fmobile=${fmobile}&itemname=${itemname}&itemId=${itemId}`;
    
    try {
      window.location.href = url;
    } catch (error) {
      console.error('Error occurred while opening URL:', error);
      alert('An error occurred while processing the payment. Please try again later.');
    }
  };  

    const generateReferenceNumber = () => {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const firstTwoLetters = 'CP';
      const digits = Math.floor(Math.random() * 10000000000000).toString().padStart(13, '0');
      return firstTwoLetters + digits;
    };

//   const proceedToPayment = async () => {
//     if (isFormValid) {
//         const uniqueItems = {};
//         cart.forEach(item => {
//             if (!uniqueItems[item.id]) {
//                 uniqueItems[item.id] = item;
//             }
//         });

//         const itemsList = Object.values(uniqueItems).map(item => ({
//             itemId: item.id,
//             itemName: item.Name,
//             category: item.category,
//             quantity: item.qty,
//             originalPrice: item.originalPrice,
//             amount: item.originalPrice * item.qty,
//             WithOS: accessoryCheckboxes[item.id] || "", 
//         }));

//         const referenceNumber = generateReferenceNumber();
//         const transactionData = [{
//             qid: qid,
//             sid: sid,
//             order_id: orderNumber,
//             name: name,
//             email: email,
//             mobile_number: mobile,
//             total_amount: itemsList.reduce((total, item) => total + item.amount, 0),
//             reference_number: deliveryOption === 'pickup' && paymentOption === 'payLater' ? referenceNumber : "",
//             transaction_status: deliveryOption === 'pickup' && paymentOption === 'payLater' ? "Pending" : "",
//             pickup_address: deliveryOption === 'delivery' ? deliveryAddress : 'Pick Up',
//             transaction_type: deliveryOption === 'pickup' && paymentOption === 'payLater' ? "Pay in Cash" : "Online Payment",
//             deliveryType: deliveryOption === 'pickup' && paymentOption === 'payLater' ? "Store Pickup" : (deliveryOption === 'pickup' && paymentOption === 'payNow' ? "Store Pickup" : "Delivery"),
//             items: itemsList,
//             remarks: remarks,
//             agreement : "Yes"
//         }];

//         try {
//             const insertResponse = await fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/insert_carabia_trans', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(transactionData),
//             });

//             if (insertResponse.ok) {
//                 const responseJson = await insertResponse.json();
//                 if (deliveryOption === 'pickup' && document.querySelector('input[name="paymentOption"]:checked')?.value === 'payNow') {
//                     await sendParameters();
//                 } else if (deliveryOption === 'delivery') {
//                     await sendParameters();
//                 } else {
//                     alert('Transaction Success.');
//                     window.location.href = `https://carabiashop.com/thankyouR?orderId=${orderid}`;
//                 }
//             } else {
//                 alert('Transaction data insertion failed. Please try again later.');
//             }
//         } catch (error) {
//             alert('An error occurred while processing the transaction. Please try again later.');
//         }
//     } else {
//         alert('Please fill in all required fields before proceeding to payment.');
//     }
// };
const proceedToPayment = async () => {
  if (isFormValid) {
    const uniqueItems = {};
    cart.forEach(item => {
      if (!uniqueItems[item.id]) {
        uniqueItems[item.id] = item;
      }
    });

    const itemsList = Object.values(uniqueItems).map(item => ({
      itemId: item.id,
      itemName: item.Name,
      category: item.category,
      quantity: item.qty,
      originalPrice: item.originalPrice,
      amount: item.originalPrice * item.qty + (accessoryCheckboxes[item.id] === "Yes" ? 75 * item.qty : 0), // Include the "With OS" cost
      WithOS: accessoryCheckboxes[item.id] || "",
    }));

    const referenceNumber = generateReferenceNumber();
    const totalAmount = itemsList.reduce((total, item) => total + item.amount, 0); // Calculate the new total amount

    const transactionData = [{
      qid: qid,
      sid: sid,
      order_id: orderNumber,
      name: name,
      email: email,
      mobile_number: mobile,
      total_amount: totalAmount, // Use the new total amount
      reference_number: deliveryOption === 'pickup' && paymentOption === 'payLater' ? referenceNumber : "",
      transaction_status: deliveryOption === 'pickup' && paymentOption === 'payLater' ? "Pending" : "",
      pickup_address: deliveryOption === 'delivery' ? deliveryAddress : 'Pick Up',
      transaction_type: deliveryOption === 'pickup' && paymentOption === 'payLater' ? "Pay in Cash" : "Online Payment",
      deliveryType: deliveryOption === 'pickup' && paymentOption === 'payLater' ? "Store Pickup" : (deliveryOption === 'pickup' && paymentOption === 'payNow' ? "Store Pickup" : "Delivery"),
      items: itemsList,
      remarks: remarks,
      agreement: "Yes"
    }];

    try {
      const insertResponse = await fetch('https://iss.apps.taallumgroup.com:8085/api/issapi/insert_carabia_trans', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionData),
      });

      if (insertResponse.ok) {
        const responseJson = await insertResponse.json();
        if (deliveryOption === 'pickup' && document.querySelector('input[name="paymentOption"]:checked')?.value === 'payNow') {
          await sendParameters();
        } else if (deliveryOption === 'delivery') {
          await sendParameters();
        } else {
          alert('Transaction Success.');
          window.location.href = `https://carabiashop.com/thankyouR?orderId=${orderid}`;
        }
      } else {
        alert('Transaction data insertion failed. Please try again later.');
      }
    } catch (error) {
      alert('An error occurred while processing the transaction. Please try again later.');
    }
  } else {
    alert('Please fill in all required fields before proceeding to payment.');
  }
};
  
  const handlePaymentOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  const toggleMapModal = () => {
    setShowMapModal(!showMapModal);
  };

  const handleLocationSelect = (formattedAddress) => {
    // console.log("Selected Address:", formattedAddress); // Debugging statement
    setDeliveryAddress(formattedAddress);
    toggleMapModal();  // Update the deliveryAddress state with the formatted address
  };
  
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBookModal, setShowBookModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Other functions...

  const togglePaymentModal = () => {
    setShowPaymentModal(!showPaymentModal);
    console.log("Toggling Payment Modal");

  };

  const toggleBookModal = () => {
    setShowBookModal(!showBookModal);
    console.log("Toggling Book Modal");
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  
  return (
    <>
    <section className="section-py container">
      <div className="container">
        <h4 className="py-3 mb-4">Checkout Order: {orderNumber}</h4>
        <div className="bg-white p-4 py-5 rounded-3 shadow-sm">
          <div className="row">
            <div className="col-xl-8 mb-3 mb-xl-0">
            <button type="button" className="back-button" onClick={handleBackClick}>
              <i className="fas fa-arrow-left"></i> Back
            </button>
              <h5>Summary of Purchase</h5>
              <ul className="list-group mb-4">
                {groupedCart.map((item, index) => (
                  <li key={item.id} className="list-group-item p-4 checkout-item animate__animated animate__fadeInUp">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img src={item.image} alt={item.Name} className="product-image" />
                        <div className="ms-3">
                          <p className="m-0 product-name">
                            {item.Name}
                          </p>
                          <div className="text-muted product-description">
                            <span>{item.desc}</span>
                          </div>
                        </div>
                      </div>
                      <div className="text-md-end">
                        <span className="product-quantity">{item.qty}x</span>
                        <div className="my-2 product-price">
                          <span className="text-primary">QAR {item.price.toFixed(2)}</span>
                        </div>
                          <button type="button" className="btn btn-sm btn-label-success mt-3 product-category">
                            {item.category === "Accessories" ? "Laptop" : item.category}
                          </button>
                        {item.isAccessory && (
                          <div className="form-check mt-3 animate__animated animate__slideInRight">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`accessory-${item.id}`}
                              checked={accessoryCheckboxes[item.id] === "Yes"} 
                              onChange={() => handleAccessoryCheckboxChange(item.id)}
                            />
                            <label className="form-check-label accessory-label" htmlFor={`accessory-${item.id}`}>
                              <span className="fw-bold">With Operating System</span> 
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-xl-4">
              <div className="border rounded p-4 mb-3 pb-3 bg-lighter">
                <h6>Customer Information</h6>
                <div className="row">
                  <div className="col-12 mt-3">
                    <input className="form-control" type="tel" value={qid} onChange={handleQIDChange} placeholder="QID" />
                    {qidError && <div className="text-danger">{qidError}</div>}
                  </div>
                  <div className="col-12 mt-3">
                    <input className="form-control" type="tel" value={sid} onChange={handleSIDChange} placeholder="Staff ID" />
                    {sidError && <div className="text-danger">{sidError}</div>}
                  </div>
                  <div className="col-12 mt-3">
                    <input className="form-control" type="text" value={name} onChange={handleNameChange} placeholder="Name" />
                  </div>
                  <div className="col-12 mt-3">
                    <input className="form-control" type="email" value={email} onChange={handleEmailChange} placeholder="Email" />
                    {emailError && <div className="text-danger">{emailError}</div>}
                  </div>
                  <div className="col-12 mt-3">
                    <div className="input-group">
                      <span className="input-group-text">
                        <img src={qatarFlag} alt="Qatar Flag" width="20" height="20" />
                        +974
                      </span>
                      <input className="form-control" type="tel" maxLength="8" value={mobile} onChange={handlePhoneChange} placeholder="Mobile Number" />
                    </div>
                    {mobileError && <div className="text-danger">{mobileError}</div>}
                  </div>
                  <div className="col-12 mt-3">
                    <textarea className="form-control" type="text" value={remarks} onChange={handleRemarksChange} placeholder="Please enter here the specific serial number or any other requests" />
                  </div>
                  {showMapModal && (
                    <div className="map-modal">
                      <div className="map-modal-content">
                        <span className="close" onClick={toggleMapModal}>
                          &times;
                        </span>
                        <MapComponent onLocationSelect={handleLocationSelect} />
                      </div>
                    </div>
                  )}
                  {deliveryOption === 'delivery' && (
                    <div className="col-12 mt-3">
                    <input
                      className="form-control"
                      type="text"
                      value={deliveryAddress}
                      onChange={handleAddressChange}
                      placeholder="Delivery Address"
                    />
                    <button className="btn btn-link" onClick={toggleMapModal}>
                      Select Delivery Address
                    </button>
                    </div>
                  )}
                </div>
                <hr />
                <h6>Checkout Details</h6>
                <dl className="row mb-0">
                  <dt className="col-6 fw-normal">No. of items</dt>
                  <dd className="col-6 text-end">{groupedCart.reduce((total, item) => total + item.qty, 0)}</dd>
                  <dt className="col-6 fw-normal">Order Total</dt>
                  <dd className="col-6 text-end">QAR {totalOrigPrice.toFixed(2)}</dd>
                  
                  {groupedCart.some(item => accessoryCheckboxes[item.id] === "Yes") && ( 
                    <>
                    <dt className="col-6 fw-normal">OS Installation Fee</dt>
                    <dd className="col-6 text-end">QAR {groupedCart.reduce((total, item) => {
                      return total + (accessoryCheckboxes[item.id] === "Yes" ? 75 * item.qty : 0);
                    }, 0).toFixed(2)}</dd>
                    </>
                  )}
                  
                  <dt className="col-6 fw-normal">Delivery Charges</dt>
                  <dd className="col-6 text-end">
                    {deliveryCharges > 0 ? `+QAR ${deliveryCharges}` : <span className="badge bg-label-success ms-1">Free</span>}
                  </dd>
                </dl>
                <hr />
                {deliveryOption === 'pickup' && (
                  <div className="col-12 mt-3">
                    <span className="payment-option">
                      <input type="radio" id="payNow" name="paymentOption" value="payNow" checked={paymentOption === 'payNow'} onChange={handlePaymentOptionChange} />
                      <label htmlFor="payNow">Pay Now</label>
                    </span>
                    <span className="payment-option">
                      <input type="radio" id="payLater" name="paymentOption" value="payLater" checked={paymentOption === 'payLater'} onChange={handlePaymentOptionChange} />
                      <label htmlFor="payLater">Pay Later</label>
                    </span>
                  </div>
                )}
                <dl className="row mb-0">
                  <dt className="col-6">Total Amount</dt>
                  <dd className="col-6 fw-medium text-end mb-0">QAR {totalPriceWithDelivery.toFixed(2)}</dd>
                </dl>
              </div>
              <div className="d-grid">
              <button className="btn btn-warning btn-next" onClick={paymentOption === 'payNow' ? togglePaymentModal : toggleBookModal} disabled={!isFormValid}>
                {paymentOption === 'payNow' ? 'Proceed to Payment' : 'Book'}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      {showPaymentModal && (
      <div className="modal-dialog modal-lg modal-simple">
        <div className="modal-wrapper">
          <div className="modal-content">
          <button type="button" className="btn-close" onClick={togglePaymentModal}></button>
            <h2 className="modal-title">Client Purchase Acknowledgement</h2>
            <p className="modal-terms" style={{textAlign:"center", fontSize:"20px", fontWeight:"Bold", color:"Black"}}>Qatar Energy Employee Validation</p>
            <ul className="modal-list">
              <li>By ticking the checkbox and clicking continue, you are confirming yourself as an employee of Qatar Energy.</li>
            </ul>
            <label className="modal-checkbox">
              <input type="checkbox" checked={termsAccepted} onChange={handleCheckboxChange} /> I agree.
            </label>
            <button className="btn btn-warning modal-btn" onClick={proceedToPayment} disabled={!termsAccepted}>Continue</button>
            <p className="modal-description">Please review your order details before proceeding with the payment.</p>
          </div>
        </div>
      </div>
      )}

      {/* Book Modal */}
      {showBookModal && (
          <div className="modal-dialog modal-lg modal-simple">
          <div className="modal-wrapper">
              <div className="modal-content">
                  <button type="button" className="btn-close" onClick={toggleBookModal}></button>
                  <h2 className="modal-subtitle">Terms and Conditions</h2>
                  <h4 className="modal-subtitle">Reservation and Order Clauses</h4>
                  <ol className="modal-list">
                      <li>
                          <strong>Specific Laptop Reservations:</strong>
                          <ul>
                              <li>When placing an order that includes the reservation of a specific laptop, please ensure that the serial number is added in the notes field prior to booking.</li>
                              <li>Claiming of the order is subject to the availability of the specified item. Waiting time may vary.</li>
                          </ul>
                      </li>
                      <li>
                          <strong>General Orders:</strong>
                          <ul>
                              <li>For orders without a specific serial number, the booking is valid for one week from the date of confirmation.</li>
                              <li>If the booking is not completed and paid within one week, the order will be invalidated.</li>
                          </ul>
                      </li>
                  </ol>
                  <p>By ticking the checkbox and clicking continue, you are confirming yourself as an employee of Qatar Energy and you agree to the terms and conditions.</p>
                  <label className="modal-checkbox">
                      <input type="checkbox" checked={termsAccepted} onChange={handleCheckboxChange} /> I agree to the terms and conditions.
                  </label>
                  <button className="btn btn-warning modal-btn" onClick={proceedToPayment} disabled={!termsAccepted}>Continue</button>
                  <p className="modal-description">Please review your order details before proceeding with the payment.</p>
              </div>
          </div>
      </div>      
      )}
    </>
  );
};

export default Checkout;
import React from 'react'

const Dialog = (props) => {
    
    const {show, setShow, title, content} = props

    return (
        <>
            <div className={`modal fade ${show? "show" : ""}`} style={{display: show ? "block" : "none"}} tabIndex={-1} role="dialog">
                <div className="modal-dialog modal-lg modal-simple">
                    <div className="modal-content p-0">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={setShow}></button>
                            <div className="content">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-backdrop fade dialog-backdrop ${show? "show" : ""}`}></div>
        </>
    )
}

export default Dialog
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const handleLogin = async () => {
  //   try {
  //     setIsLoading(true); 
  //     const response = await fetch(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetBy_useridc?email=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`);
  //     const userData = await response.json();

  //     if (userData.status === "OK") {
  //       localStorage.setItem('loggedIn', 'true');
  //       setTimeout(() => {
  //           navigate('/dashboard', { replace: true});
  //           // In your SignIn component
  //           localStorage.setItem('accountData', JSON.stringify(userData));
  //           window.location.reload();
  //       }, 3000);
  //     } else {
  //       setError('Invalid username or password');
  //     }
  //   } catch (error) {
  //     setError('Error occurred while logging in');
  //   } finally {
  //       setTimeout(() => setIsLoading(false), 3000);    }
  // };
  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://iss.apps.taallumgroup.com:8085/api/issapi/GetBy_useridc?email=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`);
      const userData = await response.json();
  
      if (userData.length > 0) {
        const user = userData[0];
        if (user.status === "OK") {
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('accountData', JSON.stringify(user)); // Store user data here
          console.log(user)
          navigate('/dashboard', { replace: true });
          window.location.reload();
        } else {
          setError('Invalid username or password');
        }
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('Error occurred while logging in');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div style={{ maxWidth: '400px', margin: 'auto' }}>
      <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '20px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Login</h3>
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="username" style={{ width: '100px' }}>Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="password" style={{ width: '100px' }}>Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
            />
          </div>
          <button
            type="button"
            onClick={handleLogin}
            disabled={isLoading || !username || !password}
            style={{
              padding: '8px 15px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#007bff',
              color: '#fff',
              cursor: isLoading || !username || !password ? 'not-allowed' : 'pointer',
            }}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
          {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default SignIn;

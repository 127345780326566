import React from 'react'
import { Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import logo from '../assets/CA_Logo-nobg.png'
import contract1 from '../assets/contract-1.png'
import contract2 from '../assets/contract-2.png'
import { dateFullFormat, currencyFormat } from '../utils/utils'
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import { ToWords } from 'to-words';

Font.register({
  family: 'RobotoRegular',
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  headerSection:{
    textAlign: 'right',
    marginTop: '75px'
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: '0px'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
  },
  reportTitle: {
    fontSize: 14,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'RobotoRegular'
  },
  docTitle:{
    display: 'block',
    textAlign: 'center',
    marginTop: '160px',
    marginBottom: '30px',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'RobotoRegular'
  },
  image: {
    position: 'relative',
    marginTop: '-170px',
    marginLeft: '0px',
    zIndex: '10',
    width: '150px'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  billTo: {
    flex:3,
    height:20,
    fontSize : 11,
    fontFamily: 'RobotoRegular',
    fontWeight: 'bold'
  },
billToNext: {
    flex:9,
    height:20,
    fontSize : 11,
    fontFamily: 'RobotoRegular',
    fontWeight: 'bold'
  },
  billToText: {
    flex:9,
    fontSize : 10,
  },
  billToNextText: {
    flex: 12,
    textAlign: 'right',
    fontSize : 10,
    fontFamily: 'RobotoRegular',
    fontWeight: 'bold'
  },
  theader: {
    marginTop : 20,
    fontSize : 10,
    fontStyle: 'bold',
    paddingTop: 4 ,
    paddingLeft: 7 ,
    flex:4,
    height:20,
    backgroundColor : '#DEDEDE',
    borderColor : 'whitesmoke',
    borderRightWidth:1,
    borderBottomWidth:1
  },
  theader2: { 
    flex:2, 
    borderRightWidth:0, 
    borderBottomWidth:1,
    width: '20px'
  },
  desc: {
    flex: 7,
    width: 200,
  },
  amount: {
    flex: 2,
    width: 200,
  },
  tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:2, borderColor : '#fff', borderRightWidth:1, borderBottomWidth:1},

  total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 4 , flex:3.7, borderColor : '#fff', borderBottomWidth:1},

  tbody2:{ flex:3, borderRightWidth:1, },
  tbodydesc: {
    flex:11,
  },
  tbodyamount: {
    flex: 3,
  },
  notes: {
    marginTop: '10px',
    borderBottom: '2px dotted #000',
    paddingBottom: '10px',
    fontSize: '11px'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5, // Adjust as needed
  },
  boldText: {
    fontWeight: 'bold',
    marginLeft: 5, // Adjust as needed
  },
});

const stylesinv = StyleSheet.create({
  image: {
    position: 'relative',
    margin: '0px auto',
    width: 350,
    height: 90,
  },
  headerSection:{
    textAlign: 'right',
    marginTop: '15px'
  },
  reportTitle: {
    fontSize: 12,
    textAlign: 'center',
  },
  docTitle:{
    fontSize: 20,
    margin: '15px auto',
    fontFamily: 'RobotoRegular',
  },
  billto: {
    fontSize: 14,
    fontFamily: 'RobotoRegular',
  },
  theader: {
    marginTop : 20,
    fontSize : 10,
    fontStyle: 'bold',
    paddingTop: 4 ,
    paddingLeft: 7 ,
    height:20,
    backgroundColor : '#DEDEDE',
    borderRightWidth:1,
    borderBottomWidth:1
  },
  theader2: { 
    flex: 1, 
    borderRightWidth:0, 
    borderBottomWidth:1,
    width: '20px'
  },
  desc: {
    flex: 3,
    width: 200,
  },
  amount: {
    flex: 2,
    width: 200,
  },
  tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:2, borderColor : '#fff', borderRightWidth:1, borderBottomWidth:1},

  total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 4 , flex:3.7, borderColor : '#fff', borderBottomWidth:1},

  tbody2:{ flex:3, borderRightWidth:1, textAlign: 'left' },
  tbodydesc: {
    flex:11,
  },
  tbodyamount: {
    flex: 3,
  },
  invfooter: {
    position: 'absolute',
    bottom: 200,
    left: 40,
    right: 0,
    textAlign: 'left',
  },
  footertext: {
    fontSize: 12,
    fontFamily: 'RobotoRegular',
  },
  footersubtext: {
    fontSize: 11,
    fontFamily: 'RobotoRegular',
  },
  invfooter1: {
    position: 'absolute',
    bottom: 100,
    left: 40,
    right: 0,
    textAlign: 'left',
  },
})

const styleswarranty = StyleSheet.create({
  image: {
    position: 'relative',
    margin: '0px auto',
  },
  details1: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    position: 'absolute',
    bottom: 141,
    right: 110,
    width: 300,
  },
  details2: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    position: 'absolute',
    bottom: 76,
    right: 110,
    width: 300,
  },
})

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      name: 'Qatar Riyal',
      plural: 'QAR',
      symbol: 'QAR',
      fractionalUnit: {
        name: 'Qatar Riyal',
        plural: 'Qatar Riyal',
        symbol: '',
      },
    },
  },
});


const Docs = ({data, serial, invNo}) => {

    const currentDate = new Date().toDateString();
    console.log("ito ito :", data)
    let figures = toWords.convert(data.total_amount, { currency: true });

    return (
    <Document>
      {/* Receipt */}
      <Page size="A4" style={styles.body}>
        
        <View style={styles.headerSection}>
          <Text style={styles.title}>Computer Arabia</Text>
          <Text style={styles.reportTitle}>Markiya Market, P.O. Box 2750, Doha Qatar</Text>
          <Text style={styles.reportTitle}>email: info@carabia.com</Text>
          <Text style={styles.reportTitle}>www.carabia.com</Text>
          <Text style={styles.reportTitle}>tel: +(974) 4012 0826</Text>
        </View>
        <Image
          style={styles.image}
          src={logo}
        />
        <View style={styles.docTitle}>
          <Text style={{ fontSize: '20px', fontWeight: '800' }}>Receipt Voucher</Text>
          {/* <Text style={{ fontSize: '12px', textAlign: 'center', marginTop: '10px'}}>Receipt No. </Text> */}
          <Text style={{ fontSize: '12px', textAlign: 'right', marginTop: '10px'}}>Order ID: {data?.order_id}</Text>
          <Text style={{ fontSize: '12px', textAlign: 'right', marginTop: '10px'}}>Date: {dateFullFormat(currentDate)}</Text>
        </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
          <View style={{ width:'50%', flexDirection :'row'}}>
            <View style={[styles.billToNext]}>
            <Text>Sold by</Text>
            </View>
            <View style={styles.billToText}>
            <Text>: Computer Arabia  </Text>
            </View>
          </View>
          <View style={{ width:'50%', flexDirection :'row'}}>
            <View style={[styles.billToText, styles.billToNextText]}>
                <Text>Payment Mode</Text> 
            </View>
            <View style={styles.billToText}>
                <Text>:  Credit/Debit Card</Text>
            </View>
          </View>
        </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
          <View style={{ width:'50%', flexDirection :'row'}}>
            <View style={[styles.billToNext]}>
                <Text>Name</Text> 
            </View>
            <View style={styles.billToText}>
              <Text>: {data.name} </Text>
            </View>
          </View>
          <View style={{ width:'50%', flexDirection :'row'}}>
            <View style={[styles.billToText, styles.billToNextText]}>
                <Text>Payment Refr</Text> 
            </View>
            <View style={styles.billToText}>
                <Text>:  Credit/Debit Card pa</Text>
            </View>
          </View>
        </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
          <View style={{ width:'50%', flexDirection :'row'}}>
            <View style={[styles.billToNext]}>
                {/* <Text>Bill Payer</Text>  */}
            </View>
            <View style={styles.billToText}>
                {/* <Text>:  </Text> */}
            </View>
          </View>
          <View style={{ width:'50%', flexDirection :'row'}}>
            <View style={[styles.billToText, styles.billToNextText]}>
                <Text>Transaction Date</Text> 
            </View>
            <View style={styles.billToText}>
                <Text>:  {dateFullFormat(data?.transDateTime)} </Text>
            </View>
          </View>
        </View>
        
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text>Invoice No.</Text>   
            </View>
            <View style={[styles.theader, styles.desc]}>
                <Text style={{ textAlign: 'center'}}>Description</Text>   
            </View>
            <View style={[styles.theader, styles.amount]}>
                <Text>Amount</Text>   
            </View>
        </View>
        {/* {data.Items.map((item, index) => (
                    <View key={index} style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={[styles.tbody, styles.tbody2]}>
                            <Text>{invNo}</Text>
                        </View>
                        <View style={[styles.tbody, styles.tbodydesc]}>
                            <View style={styles.row}>
                                <Text style={styles.desc}>
                                    <Text style={{ fontWeight: 'bold' }}>Item Name :</Text> {item.itemname}
                                </Text>
                                <Text style={styles.amount}>
                                    <Text style={{ fontWeight: 'bold' }}>Serial Number :</Text> {item.serial_no}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.desc}>
                                    <Text style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>Quantity :</Text> {item.quantity}x
                                </Text>
                                <Text style={styles.amount}>
                                    {currencyFormat(item.amount)}
                                </Text>
                            </View>
                        </View>
                        <View style={[styles.tbody, styles.tbodyamount]}>
                            <Text>{currencyFormat(item.amount)}</Text>
                        </View>
                    </View>
                ))}
          <View> */}
                          <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                        <Text>{invNo}</Text>
                    </View>
                    <View style={[styles.tbody, styles.tbodydesc]}>
                        {data.Items.map((item, index) => (
                            <View key={index}>
                                <View style={styles.row}>
                                    <Text style={styles.desc}>
                                        <Text style={{ fontWeight: 'bold' }}>Item Name :</Text> {item.itemname}
                                    </Text>
                                    <Text style={styles.amount}>
                                        <Text style={{ fontWeight: 'bold' }}>Serial Number :</Text> {item.serial_no}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.desc}>
                                        <Text style={{ fontWeight: 'bolder', fontStyle: 'italic' }}>Quantity :</Text> {item.quantity}x
                                    </Text>
                                    <Text style={styles.amount}>
                                        {currencyFormat(item.amount)}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={[styles.tbody, styles.tbodyamount]}>
                        {data.Items.map((item, index) => (
                            <Text key={index}>{currencyFormat(item.amount)}</Text>
                        ))}
                    </View>
                </View>
                <View>
              {/* <View style={[styles.tbody, styles.tbodyamount]}>
                  <Text>{currencyFormat(data?.total_amount)}</Text>   
              </View> */}
          </View>
          <View style={{ width:'100%', flexDirection :'row'}}>
              <View style={styles.total}>
                  <Text></Text>   
              </View>
              <View style={styles.total}>
                  <Text> </Text>   
              </View>
              {/* <View>
                  <Text style={styles.total}>Total</Text>   
              </View>
              <View style={styles.tbody}>
                  <Text style={{ paddingLeft: '15px'}}>
                    {currencyFormat(data?.total_amount)}
                  </Text>  
              </View> */}
          </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
              <View style={[styles.tbody, styles.notes]}>
                  <Text>
                    {/* {data?.item.slice(0, data?.item.indexOf("(")).trim()} - ({data?.transaction_type}) - {serial} */}
                  </Text>  
              </View>
          </View>
        <View style={{ width:'100%', flexDirection :'row'}}>
              <View style={{fontSize : 11, paddingTop: 4 , paddingLeft: 4}}>
                  <Text>***** {figures} **</Text>   
              </View>
              <View style={styles.total}>
                  <Text> </Text>   
              </View>
              <View>
                  <Text style={styles.total}>Total Amount</Text>   
              </View>
              <View style={styles.tbody}>
                  <Text style={{ paddingLeft: '5px'}}>
                    {currencyFormat(data.total_amount)}
                  </Text>  
              </View>
          </View>
      </Page>
      {data?.transaction_type == 'Full payment' || data?.transaction_type == 'Downpayment Partial' && 
      <>
      <Page size="A4" style={styles.body}>
        <Image
          style={stylesinv.image}
          src={logo}
        />
        <View style={stylesinv.headerSection}>
          <Text style={stylesinv.reportTitle}>GSSG Building, Salwa Road, Doha Qatar</Text>
          <Text style={stylesinv.reportTitle}>email: info@innovatixsystems.com web: www.innovatixsystems.com</Text>
          <Text style={stylesinv.reportTitle}>tel: +974 44910-5428</Text>
        </View>
        <View style={stylesinv.docTitle}>
          <Text>INVOICE</Text>
        </View>
        <View style={stylesinv.billto}>
          <View>
            <Text>BILL TO</Text>
          </View>
          
          <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <Text style={{ fontSize: 11, flex: 2 }}>Customer ID</Text>
            <Text style={{ fontSize: 11, flex: 5 }}>: {data?.qid} </Text>
            <Text style={{ fontSize: 11, flex: 2 }}>Invoice No.</Text>
            <Text style={{ fontSize: 11, flex: 2 }}>: {invNo}</Text>
          </View>
          <View style={{ width:'100%', flexDirection :'row', marginTop:5}}>
            <Text style={{ fontSize: 11, flex: 2 }}>Name</Text>
            <Text style={{ fontSize: 11, flex: 5 }}>: {data?.name}</Text>
            <Text style={{ fontSize: 11, flex: 2 }}>Invoice Date</Text>
            <Text style={{ fontSize: 11, flex: 2 }}>: {dateFullFormat(currentDate)}</Text>
          </View>
        </View>
      
      <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[stylesinv.theader, stylesinv.theader2]}>
                <Text></Text>   
            </View>
            <View style={[stylesinv.theader, stylesinv.desc]}>
                <Text>Details</Text>   
            </View>
        	<View style={[stylesinv.theader, stylesinv.amount]}>
                <Text>Qty</Text>   
            </View>
        	<View style={[stylesinv.theader, stylesinv.amount]}>
                <Text>Unit Price</Text>   
            </View>
            <View style={[stylesinv.theader, stylesinv.amount]}>
                <Text>Amount</Text>   
            </View>
        </View>
          <View style={{ width:'100%', flexDirection :'row'}}>
              <View style={{ fontSize: 9 , marginTop: 4, flex: 2}}>
                  <Text>1</Text>   
              </View>
              <View style={{ fontSize: 9 , marginTop: 4, flex: 5}}>
                  <Text>Purchase of {data?.item.slice(0, data?.item.indexOf("(")).trim()}</Text>   
              </View>
              <View style={[stylesinv.tbody, stylesinv.tbodyamount]}>
                  <Text>{data?.qty}</Text>   
              </View>
            	<View style={[stylesinv.tbody, stylesinv.tbodyamount]}>
                  <Text>{currencyFormat(data?.amount)}</Text>   
              </View>
              <View style={[stylesinv.tbody, stylesinv.tbodyamount]}>
                  <Text>{currencyFormat(data?.amount)}</Text>   
              </View>
          </View>
          <View style={{ width:'100%', flexDirection :'row', marginTop: 10, border: '1.5px solid black'}}>
              <View style={stylesinv.total}>
                  <Text></Text>   
              </View>
              <View style={stylesinv.total}>
                  <Text> </Text>   
              </View>
              <View>
                  <Text style={stylesinv.total}>Total QAR: </Text>   
              </View>
              <View style={stylesinv.tbody}>
                  <Text style={{ paddingLeft: '5px'}}>
                  {currencyFormat(data?.amount)}
                  </Text>  
              </View>
          </View>
        	<View style={{ marginTop: 10, border: '1.5px solid black'}}>
              <View style={{fontSize : 11, paddingTop: 4 , paddingLeft: 4, textAlign: 'center'}}>
                  <Text>Amount in words *** {figures} **</Text>   
              </View>
          </View>
      <View style={stylesinv.invfooter}>
      	<Text style={stylesinv.footertext}>Please Make all cheques payable to "Innovatix Systems Services"</Text>
      	<View style={{ marginTop: 10, marginBottom: 30, border: '1.5px solid black', padding: '10px 10px', width: '300px'}}>
        	<Text style={stylesinv.footertext}>For Bank Transfers</Text>
          	<Text style={stylesinv.footersubtext}>Account Name: Innvotix System Services</Text>
            <Text style={stylesinv.footersubtext}>Bank Name: Commercial Bank of Qatar</Text>
            <Text style={stylesinv.footersubtext}>IBAN: QA96 CBQA 0000 0000 4810 4456 8500 1</Text>
            <Text style={stylesinv.footersubtext}>Currency: QAR</Text>
        </View>
      </View>
      <View style={stylesinv.invfooter}>
        <Text style={stylesinv.footertext}>If you have any questions concerning this invoice, please contact us on 44915414</Text>
      </View>
      <View style={stylesinv.invfooter1}>
        <View>
          <Text style={stylesinv.footertext}>THANK YOU FOR YOUR BUSINESS!</Text>
        </View>
        <View>
          <Text style={{fontSize: 11, marginTop: 10}}>This is a system generated invoice & Requires no signature</Text>
        </View>
        <View>
          <Text style={{fontSize: 11, marginTop: 25}}>Finance Department</Text>
        </View>
      </View>
      </Page>
      <Page size="A4" style={styles.body}>
        <Image
          style={stylesinv.image}
          src={logo}
        />
      <View style={{ width:'100%', flexDirection :'row', marginTop:50}}>
      	<Image
          style={styleswarranty.image}
          src={contract1}
        />
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      <Page size="A4" style={styles.body}>
        <Image
            style={stylesinv.image}
            src={logo}
          />
        <View style={{ width:'100%', flexDirection :'row', marginTop:50}}>
          <Image
            style={styleswarranty.image}
            src={contract2}
          />
        </View>
        <View style={styleswarranty.details1}>
          <Text style={{flex: 4}}>{dateFullFormat(currentDate)}</Text>
          <Text style={{flex: 5}}>{data?.student_name}</Text>
        </View>
        <View style={styleswarranty.details2}>
          <Text style={{flex: 4}}>{dateFullFormat(currentDate)}</Text>
          <Text style={{flex: 5}}>{data?.name}</Text>
        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
      </Page>
      </>}
      
    </Document>
    );
}

export default Docs
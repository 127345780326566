import React from 'react'

const NotFound = () => {
  return (
    <div className="container-xxl container-p-y ">
      <div className="misc-wrapper m-auto text-center">
        <h2 className="mb-2 mx-2">404</h2>
        <p className="mb-4 mx-2 h3">
          Page not found <i className='bx bx-error'></i>
        </p>
      </div>
    </div>

  )
}

export default NotFound
import React, {useState, useEffect} from 'react'

const FilterTransactions = (props) => {

    const {counts, onFilter, filterKey} = props;
    // const sum = counts.reduce((total, current) => total + current, 0);
    // const sum = counts.slice(0,5).reduce((total, current) => total + current, 0);
    const sum = counts[4]+counts[5];

    return (
        <div className="card mx-4 mb-4">
            <div className="card-widget-separator-wrapper">
                <div className="card-body card-widget-separator py-3">
                    <div className="row gy-4 gy-sm-1 gx-6">
                    <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey == '' ? 'active' : '' }`} onClick={() => onFilter('')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{sum}<i className='bx bx-x'></i></h3>
                                <p className="mb-0">All Transactions</p>
                            </div>
                            <div className="avatar me-sm-4">
                                <span className="avatar-initial rounded bg-success">
                                <i className='bx bx-package bx-sm text-label-success'></i>
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none me-4" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey == 'Order Received' ? 'active' : '' }`} onClick={() => onFilter('Order Received')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[0]}<i className='bx bx-x'></i></h3>
                                <p className="mb-0">Order Recieved</p>
                            </div>
                            <div className="avatar me-sm-4">
                                <span className="avatar-initial rounded bg-label-dark">
                                <i className='bx bx-credit-card-alt bx-sm text-dark'></i>
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none me-4" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey == 'Preparing Item' ? 'active' : '' }`} onClick={() => onFilter('Preparing Item')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[1]}<i className='bx bx-x'></i></h3>
                                <p className="mb-0">Preparing Orders</p>
                            </div>
                            <div className="avatar me-lg-4">
                                <span className="avatar-initial rounded bg-label-warning">
                                <i className='bx bx-box bx-sm text-warning'></i>
                                
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey == 'Delivering' ? 'active' : '' }`} onClick={() => onFilter('Delivering')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[2]}<i className='bx bx-x'></i></h3>
                                <p className="mb-0">Delivering Items</p>
                            </div>
                            <div className="avatar me-lg-4">
                                <span className="avatar-initial rounded bg-label-primary">
                                <i className='bx bxs-truck bx-sm text-primary' ></i>
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey == 'Picked up' ? 'active' : '' }`} onClick={() => onFilter('Picked up')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[6]}<i className='bx bx-x'></i></h3>
                                <p className="mb-0">Completed Pick up Transaction</p>
                            </div>
                            <div className="avatar me-lg-4">
                                <span className="avatar-initial rounded bg-label-success">
                                <i className='bx bx-check bx-sm text-success'></i>
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey == 'Tablet Received by Owner' ? 'active' : '' }`} onClick={() => onFilter('Tablet Received by Owner')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 pb-3 pb-sm-0">
                            <div>
                                <h3 className="mb-2">{counts[3]}<i className='bx bx-x'></i></h3>
                                <p className="mb-0">Completed Delivery Transaction</p>
                            </div>
                            <div className="avatar me-lg-4">
                                <span className="avatar-initial rounded bg-label-success">
                                <i className='bx bx-check bx-sm text-success'></i>
                                </span>
                            </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey === 'Pay in Cash' ? 'active' : ''}`} onClick={() => onFilter('Pay in Cash')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                                <div>
                                    <h3 className="mb-2">{counts[4]}<i className='bx bx-x'></i></h3>
                                    <p className="mb-0">Onsite Payment</p>
                                </div>
                                <div className="avatar me-lg-4">
                                    <span className="avatar-initial rounded bg-label-danger">
                                        <i className='bx bx-building bx-sm text-danger'></i>
                                    </span>
                                </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                        <div className={`col-2 p-2 me-3 filter-item-notif cursor-pointer ${filterKey === 'Online Payment' ? 'active' : ''}`} onClick={() => onFilter('Online Payment')}>
                            <div className="d-flex justify-content-between align-items-start card-widget-2 pb-3 pb-sm-0">
                                <div>
                                    <h3 className="mb-2">{counts[5]}<i className='bx bx-x'></i></h3>
                                    <p className="mb-0">Online Payment</p>
                                </div>
                                <div className="avatar me-lg-4">
                                    <span className="avatar-initial rounded bg-label-warning text-primary">
                                        <i className='bx bx-globe bx-sm'></i>
                                    </span>
                                </div>
                            </div>
                            <hr className="d-none d-sm-block d-lg-none" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterTransactions
import React, { useState, useEffect } from 'react';
import '../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css';
import '../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css';
import Badge from '../components/Badge';
import { dateFullFormat, currencyFormat } from '../utils/utils';
import Button from '../components/Button';

const ProductComponent = ({ data, updateProduct, isBtnLoading, updateProductQty, updateProductPrice, isAddProduct, formik, updateProductSpecs, updateProductImage }) => {
    const [newProductQty, setNewProductQty] = useState(data?.qty);
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [newProductPrice, setNewProductPrice] = useState(data?.price);
    const [editedSpecs, setEditedSpecs] = useState(data?.specs);
    const [newSpecs, setNewSpecs] = useState(data?.specs);
    const [imageFile, setImageFile] = useState(null);

    const handleProductQtyChange = (val) => {
        const qty = val;
        setNewProductQty(qty);
        updateProductQty(qty);
    };

    const handleProductPriceChange = (val) => {
        const prc = val;
        setNewProductPrice(prc);
        updateProductPrice(prc);
    };

    const handleSpecsChange = (e) => {
        setEditedSpecs(e.target.value);
    };

    const handleSpecsBlur = () => {
        updateProductSpecs(data.id, editedSpecs);
    };

    const handleUpdateSpecs = () => {
        setNewSpecs(editedSpecs);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = (event) => {
                formik.setFieldValue('image', event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateImage = () => {
        if (imageFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateProductImage(data.id, reader.result); // Call the function with product ID and base64 image
            };
            reader.readAsDataURL(imageFile);
        }
    };

    const category_label = [
        {
            label: 'Laptop',
            value: 'Accessories',
        },
        // {
        //     label: 'Tablet',
        //     value: 'Tablet',
        // },
        {
            label: 'Others',
            value: 'Others',
        },
    ];

    return (
        <>
            {!isAddProduct ? (
                <>
                    <div className="row mb-3">
                        <div className="col-12 col-lg-8">
                            <h4 className="mt-2">PRODUCT ID: {data?.id}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group mb-3">
                                <li className="list-group-item p-4">
                                    <div className="d-flex gap-3">
                                        <div className="flex-shrink-0 d-flex align-items-center">
                                            <img src={data?.image} alt={data?.Name} className="w-px-150" />
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label className="form-label">
                                                    <span className="text-xs text-danger">*</span> Image
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="file"
                                                    onChange={handleImageChange}
                                                    accept="image/*"
                                                />
                                                {formik.touched.image && formik.errors.image ? (
                                                    <div className="text-danger">{formik.errors.image}</div>
                                                ) : null}
                                                <Button
                                                    customclass="btn btn-primary mt-2"
                                                    title="Update Image"
                                                    action={handleUpdateImage}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <p className="me-3">
                                                        <a href="#" className="text-body">
                                                            {data?.Name}
                                                        </a>
                                                    </p>
                                                    <div className="text-muted mb-2 d-flex flex-wrap">
                                                        <span className="me-1">Category:</span>
                                                        <a href="#" className="me-3">
                                                            {data?.category === 'Accessories' ? 'Laptop' : data?.category}
                                                        </a>
                                                    </div>
                                                    <div className="d-flex">
                                                        <label className="me-2">Stocks:</label>
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-sm w-px-100"
                                                            value={newProductQty}
                                                            onChange={(e) => handleProductQtyChange(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="text-end">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <label className="text-primary me-2">Price: </label>
                                                            <input
                                                                type="number"
                                                                className="form-control form-control-sm w-px-100"
                                                                value={newProductPrice}
                                                                onChange={(e) => handleProductPriceChange(e.target.value)}
                                                            />
                                                        </div>
                                                        <Button
                                                            title={
                                                                newProductQty === 0
                                                                    ? 'Out of Stock'
                                                                    : newProductQty < 10
                                                                    ? 'Low Stock'
                                                                    : 'In Stock'
                                                            }
                                                            customclass={`btn btn-sm mt-md-3 ${
                                                                newProductQty === 0
                                                                    ? 'btn-label-danger'
                                                                    : newProductQty < 10
                                                                    ? 'btn-label-warning'
                                                                    : 'btn-label-success'
                                                            }`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="list-group-item p-4">
                                    <div className="d-flex justify-content-between w-100">
                                        <h6>Description</h6>
                                    </div>
                                    <p className="mb-1">{data?.desc}</p>
                                </li>
                            </ul>
                            <div className="accordion mt-3">
                                <div className={`card accordion-item ${accordionOpen ? 'active' : ''}`}>
                                    <h2 className="accordion-header">
                                        <button
                                            type="button"
                                            className={`accordion-button ${accordionOpen ? '' : 'collapsed'}`}
                                            onClick={() => setAccordionOpen(!accordionOpen)}
                                        >
                                            Show other details
                                        </button>
                                    </h2>
                                    <div className={`accordion-collapse collapse ${accordionOpen ? 'show' : ''}`}>
                                        <div className="accordion-body">
                                            <div className="py-3">
                                                <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <h6>Specifications</h6>
                                                        <small>Date Added: {dateFullFormat(data?.createdOn)}</small>
                                                    </div>
                                                    <textarea
                                                        className="mb-1 w-100"
                                                        value={editedSpecs}
                                                        onBlur={handleSpecsBlur}
                                                        onChange={handleSpecsChange}
                                                    ></textarea>
                                                    <div className="d-flex justify-content-between w-100 mt-4">
                                                        <h6>Warranty</h6>
                                                    </div>
                                                    <p className="mb-1">{data?.warranty}</p>
                                                    <small>Package: {data?.package}</small>
                                                    <br />
                                                    <small>Inclusion: {data?.inclusion}</small>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-end">
                            <div className="mt-3 ms-3">
                                <Button
                                    customclass="btn btn-primary me-2"
                                    title="Update Price"
                                    icon="bx bx-save me-2"
                                    action={() => updateProduct('prc')}
                                />
                                <Button
                                    customclass="btn btn-primary me-2"
                                    title="Update Stocks"
                                    icon="bx bx-save me-2"
                                    action={() => updateProduct('qty')}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="row mb-3">
                    <div className="col-12 col-lg-8">
                        <h4 className="mt-2">Add New Product</h4>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="col-12">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h5 className="card-tile mb-0">Product information</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <label className="form-label">
                                                <span className="text-xs text-danger">*</span> Product ID
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Product ID"
                                                {...formik.getFieldProps('id')}
                                            />
                                            {formik.touched.id && formik.errors.id ? (
                                                <div className="text-danger">{formik.errors.id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">
                                                <span className="text-xs text-danger">*</span> Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Product name"
                                                {...formik.getFieldProps('name')}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className="text-danger">{formik.errors.name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <label className="form-label">
                                                <span className="text-xs text-danger">*</span> Price
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Product Price"
                                                {...formik.getFieldProps('price')}
                                            />
                                            {formik.touched.price && formik.errors.price ? (
                                                <div className="text-danger">{formik.errors.price}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">
                                                <span className="text-xs text-danger">*</span> Stocks
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Product Stocks"
                                                {...formik.getFieldProps('qty')}
                                            />
                                            {formik.touched.qty && formik.errors.qty ? (
                                                <div className="text-danger">{formik.errors.qty}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label">
                                                <span className="text-xs text-danger">*</span> Category
                                            </label>
                                            <select className="form-control" {...formik.getFieldProps('category')}>
                                                {category_label.map((item, index) => (
                                                    <option key={index} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                                {formik.touched.category && formik.errors.category ? (
                                                    <div className="text-danger">{formik.errors.category}</div>
                                                ) : null}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label">
                                                <span className="text-xs text-danger">*</span> Description{' '}
                                                <span className="text-muted"></span>
                                            </label>
                                            <textarea className="form-control" {...formik.getFieldProps('desc')}></textarea>
                                            {formik.touched.desc && formik.errors.desc ? (
                                                <div className="text-danger">{formik.errors.desc}</div>
                                            ) : null}
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label className="form-label">Specs</label>
                                                <textarea className="form-control " {...formik.getFieldProps('specs')}></textarea>
                                                {formik.touched.specs && formik.errors.specs ? (
                                                    <div className="text-danger">{formik.errors.specs}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label">Package</label>
                                            <textarea className="form-control " {...formik.getFieldProps('package')}></textarea>
                                            {formik.touched.package && formik.errors.package ? (
                                                <div className="text-danger">{formik.errors.package}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label">Inclusion</label>
                                            <textarea className="form-control " {...formik.getFieldProps('inclusion')}></textarea>
                                            {formik.touched.inclusion && formik.errors.inclusion ? (
                                                <div className="text-danger">{formik.errors.inclusion}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label">Warranty</label>
                                            <textarea className="form-control" {...formik.getFieldProps('warranty')}></textarea>
                                            {formik.touched.warranty && formik.errors.warranty ? (
                                                <div className="text-danger">{formik.errors.warranty}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="form-label">Image</label>
                                            <input className="form-control" type="file" onChange={handleImageChange} accept="image/*" />
                                            {formik.touched.image && formik.errors.image ? (
                                                <div className="text-danger">{formik.errors.image}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex align-items-center justify-content-end">
                            <div className="mt-3 ms-3">
                                <Button
                                    customclass="btn btn-primary me-2"
                                    title="Submit"
                                    type="submit"
                                    icon="bx bx-save me-2"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default ProductComponent;

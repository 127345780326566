import React from 'react'
import companyLogo from '../assets/newcalogo.jpg';
import avatar from '../assets/logo_big.png';

const Nav = (props) => {
    const {showToggleNotifMenu, showToggleUserMenu, toggleSideMenu, toggleUserMenu, toggleNotifMenu, logout, accountData,accountsData, notifications, updateNotif, avatarUrl} = props

    return (
        <nav className="navbar navbar-expand-xl align-items-center bg-navbar-theme dashboard-navbar">
            <div className="container-xxl">
                <div className="navbar-brand app-brand d-none d-xl-flex py-0 me-4">
                    <a href="#" className="app-brand-link gap-2">
                        <span className="app-brand-logo">
                        <img src={companyLogo} alt="logo" width={200}/>
                        </span>
                        {/* <span className="app-brand-text menu-text fw-bold">Computer Arabia</span> */}
                    </a>
                    <a href="#" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                        <i className="bx bx-chevron-left bx-sm align-middle" />
                    </a>
                </div>
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                <a
                    className="nav-item nav-link px-0 me-xl-4"
                    href="#"
                    onClick={toggleSideMenu}
                >
                    <i className="bx bx-menu bx-sm" />
                </a>
                </div>
                <div className="navbar-nav-right d-flex align-items-center">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                        <a
                            className={`nav-link dropdown-toggle hide-arrow ${showToggleNotifMenu? 'show' : ''}`}
                            href="#"
                            onClick={toggleNotifMenu}
                            aria-expanded="false"
                        >
                            <i className="bx bx-bell bx-sm notif-icon" />
                            {notifications.length > 0 && (
                                <span className="badge bg-danger rounded-pill badge-notifications">
                                    {notifications.length}
                                </span>
                            )}
                            
                        </a>
                        <ul className={`dropdown-menu dropdown-menu-end py-0 ${showToggleNotifMenu? 'show' : ''} w-30vh`}>
                            <li className="dropdown-menu-header border-bottom">
                            <div className="dropdown-header d-flex align-items-center py-3">
                                <h5 className="text-body mb-0 me-auto">Notifications</h5>
                                <a
                                    href="#"
                                    className="dropdown-notifications-all text-body"
                                    title="Mark all as read"
                                >
                                <i className="bx fs-4 bx-envelope-open" />
                                </a>
                            </div>
                            </li>
                            <li className="dropdown-notifications-list scrollable-container">
                            <ul className="list-group list-group-flush">
                                {notifications.map((notification, index) => (
                                <li className="list-group-item list-group-item-action dropdown-notifications-item cursor-pointer" key={index} onClick={() => updateNotif(notification.orderId)}>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                        <div className="avatar">
                                            <span className="avatar-initial rounded-circle bg-label-success">
                                            <i className='bx bx-bell'></i>
                                            </span>
                                        </div>
                                        </div>
                                        <div className="flex-grow-1">
                                        <h6 className="mb-1">{notification?.title}</h6>
                                        <p className="mb-0">
                                            Order Id: {notification?.orderId}
                                        </p>
                                        <small className="text-muted">{notification?.dateTime}</small>
                                        </div>
                                        {!notification?.read && (
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <a href="#" className="dropdown-notifications-read">
                                                    <span className="badge badge-dot" />
                                                </a>
                                            </div>
                                        )}
                                        
                                    </div>
                                </li>
                                ))}
                            </ul>
                            </li>
                        </ul>
                        </li>
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                        <a href="#" className={`nav-link dropdown-toggle hide-arrow ${showToggleUserMenu ? 'show' : ''}` }
                            onClick={toggleUserMenu}
                        >
                            <div className="avatar avatar-online">
                            <img
                                src={avatarUrl == '' ? avatar : avatar}
                                alt="avatar"
                                className="w-px-40 h-auto rounded-circle"
                            />
                            </div>
                        </a>
                        <ul className={`dropdown-menu dropdown-menu-end ${showToggleUserMenu ?'show' : ''}`}>
                            <li>
                            <a
                                className="dropdown-item"
                                href="pages-account-settings-account.html"
                            >
                                <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                    <div className="avatar avatar-online">
                                    <img
                                        src={avatarUrl == '' ? avatar : avatar}
                                        alt="avatar"
                                        className="w-px-40 h-auto rounded-circle"
                                    />
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <span className="fw-medium d-block">{accountData.name}</span>
                                    <small className="text-muted">Computer Arabia</small>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <div className="dropdown-divider" />
                            </li>
                            <li>
                            <a className="dropdown-item" href="pages-profile-user.html">
                                <i className="bx bx-user me-2" />
                                <span className="align-middle">My Profile</span>
                            </a>
                            </li>
                            <li>
                            <a
                                className="dropdown-item"
                                href="pages-account-settings-account.html"
                            >
                                <i className="bx bx-cog me-2" />
                                <span className="align-middle">Settings</span>
                            </a>
                            </li>
                            <li>
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={logout}
                            >
                                <i className="bx bx-power-off me-2" />
                                <span className="align-middle">Log Out</span>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Nav
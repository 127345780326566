import React, { useState, useEffect } from 'react';

const MapComponent = ({ onLocationSelect }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [building, setBuilding] = useState('');
  const [floor, setFloor] = useState('');
  const [unit, setUnit] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDKRA-PNQGDkWne5FmnUsaY9N9Sed5OGag&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 25.276987, lng: 51.524834 },
        zoom: 10,
      });

      const markerInstance = new window.google.maps.Marker({ map: mapInstance });
      setMap(mapInstance);
      setMarker(markerInstance);

      const input = document.getElementById('search-input');
      const searchBoxInstance = new window.google.maps.places.SearchBox(input);
      setSearchBox(searchBoxInstance);

      searchBoxInstance.addListener('places_changed', () => {
        const places = searchBoxInstance.getPlaces();
        if (places.length === 0) {
          return;
        }

        const place = places[0];
        setSelectedLocation({
          formattedAddress: place.formatted_address,
          geometry: place.geometry.location
        });
        markerInstance.setPosition(place.geometry.location);
        mapInstance.panTo(place.geometry.location);
      });

      mapInstance.addListener('click', (event) => {
        const clickedLocation = event.latLng.toJSON();
        setSelectedLocation(null);
        markerInstance.setPosition(clickedLocation);
        reverseGeocode(clickedLocation);
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const reverseGeocode = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        const formattedAddress = addressComponents
          .map(component => component.long_name)
          .join(', ');
        setSelectedLocation({
          formattedAddress,
          geometry: location
        });
      }
    });
  };

  const handleConfirmLocation = () => {
    let concatenatedAddress = selectedLocation ? selectedLocation.formattedAddress : '';
    if (building) {
      concatenatedAddress += `, Building ${building}`;
    }
    if (floor) {
      concatenatedAddress += `, Floor ${floor}`;
    }
    if (unit) {
      concatenatedAddress += `, Unit ${unit}`;
    }
    onLocationSelect(concatenatedAddress, selectedLocation ? selectedLocation.geometry : null);
  };

  const isConfirmButtonDisabled = !building || !floor || !unit;

  return (
    <div>
      <input id="search-input" type="text" placeholder="Search for a location" style={{ width: '100%', marginBottom: '10px' }} />
      <div id="map" style={{ height: '400px', width: '100%' }}></div>
      {selectedLocation && (
        <>
          <div className="col-12 mt-3">
            <input
              className="form-control"
              type="text"
              value={building}
              onChange={(e) => setBuilding(e.target.value)}
              placeholder="Building"
              required
            />
          </div>
          <div className="col-12 mt-3">
            <input
              className="form-control"
              type="text"
              value={floor}
              onChange={(e) => setFloor(e.target.value)}
              placeholder="Floor"
              required
            />
          </div>
          <div className="col-12 mt-3">
            <input
              className="form-control"
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              placeholder="Unit"
              required
            />
          </div>
          <div className="d-grid">
            <button
              className="btn btn-secondary btn-next col-12 mt-3"
              onClick={handleConfirmLocation}
              disabled={isConfirmButtonDisabled}
            >
              Confirm Location
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MapComponent;

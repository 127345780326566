import React from 'react'
import footerbg from '../assets/footer-bg-light.png'
import logo from '../assets/CA_Logo-nobg.png'

const Footer = () => {
  return (
    <footer >
      <div className="footer-top position-relative overflow-hidden z-1 products-container" >
        {/* <img
          src={footerbg}
          alt="footer bg"
          className="footer-bg banner-bg-img z-n1"
          data-app-light-img="front-pages/backgrounds/footer-bg-light.png"
          data-app-dark-img="front-pages/backgrounds/footer-bg-dark.png"
        /> */}
        <div className="container">
            <div className="row gx-0 gy-4 g-md-5">
              <div className="col-lg-4 d-flex">
                <div className="py-3 rounded text-center w-100">
                  <span className="badge bg-label-white rounded-2 my-3">
                    <i className="bx bx-building bx-md border border-white rounded-circle p-2" />
                  </span>
                  <p className='text-white'>
                            Markiya Market <br />
                            P.O. Box 2750 <br />
                            Doha, Qatar <br />
                            Sun - Thu: 7:00AM - 4:30PM
                        </p>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="py-3 rounded text-center w-100">
                  <span className="badge bg-label-white rounded-2 my-3">
                    <i className="bx bx-phone bx-md border border-white rounded-circle p-2" />
                  </span>
                  <h4 className="mb-2">
                    <a className="h4 text-white" href="tel:+97440120826">
                      +(974) 4012 0826 
                    </a>
                  </h4>
                  <p className='text-white'>We are always happy to help</p>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="py-3 rounded text-center w-100">
                  <span className="badge bg-label-white rounded-2 my-3">
                    <i className="bx bx-envelope bx-md border border-white rounded-circle p-2" />
                  </span>
                  <h4 className="mb-2">
                    <a className="h4 text-white" href="mailto:info@innovatixsystems.com">
                      info@carabia.com
                    </a>
                  </h4>
                  <p className='text-white'>Best way to get a quick answer</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom py-3">
        <div className="container d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">
          <div className="mb-2 mb-md-0">
            <span className="footer-text pe-2"  style={{ color: 'black', fontWeight: 'bold' }}>© 2024</span>
            <a
              href="https://innovatixsystems.com"
              target="_blank"
              className="fw-medium footer-link"
              style={{ color: 'black' }}
            >
              Innovatix Systems Services
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/InnovatixSystems"
              className="footer-link me-3"
              target="_blank"
              style={{ color: 'black' }}
            >
              <i className='bx bxl-facebook-circle'></i> 
            </a>
            <a
              href="https://twitter.com/InnovatixSystem"
              className="footer-link me-3"
              target="_blank"
              style={{ color: 'black' }}
            >
              <i className='bx bxl-twitter' ></i> 
            </a>
            <a
              href="https://www.instagram.com/innovatixsystems"
              className="footer-link me-3"
              target="_blank"
              style={{ color: 'black' }}
            >
              <i className='bx bxl-instagram' ></i> 
            </a>
            <a
              href="https://www.linkedin.com/company/innovatixsystems"
              className="footer-link me-3"
              target="_blank"
              style={{ color: 'black' }}
            >
              <i className='bx bxl-linkedin' ></i> 
            </a>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer
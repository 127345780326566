// import React, {useState, useMemo, useEffect} from 'react'
// import { Welcome } from '../Dashboard/Welcome'
// import OrderChart from '../Dashboard/OrderChart'
// import PieChart from '../Dashboard/PieChart'
// import { currencyFormat } from '../utils/utils';
// import moment from 'moment';

// const DashboardContainer = ({accountsData}) => {

//     const [transactions, setTransactions] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [counts, setCounts] = useState({
//         orderReceived: 0,
//         preparingItem: 0,
//         delivering: 0,
//         tabletReceived: 0,
//         pickedUp: 0,
//     });
//     const [paidTransaction, setPaidTransaction] = useState({
//         counts: 0,
//         total_amount: 0,
//     })
//     const [todaySales, setTodaySales] = useState(0);

//     const [filterKey, setFilterKey] = useState(moment().format('MM'));
//     const filterMonth = [
//         { label: 'January', value: '01' },
//         { label: 'February', value: '02' },
//         { label: 'March', value: '03' },
//         { label: 'April', value: '04' },
//         { label: 'May', value: '05' },
//         { label: 'June', value: '06' },
//         { label: 'July', value: '07' },
//         { label: 'August', value: '08' },
//         { label: 'September', value: '09' },
//         { label: 'October', value: '10' },
//         { label: 'November', value: '11' },
//         { label: 'December', value: '12' },
//     ];

//     const [selectedLabel, setSelectedLabel] = useState(moment().format('MMMM'));

//     const [isDropdownMonthOpen, setIsDropdownMonthOpen] = useState(false);
//     const [products, setProducts] = useState([]);

//     const getTransactions = async () => {
//         try {
//             setLoading(true);
//             const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetAll_transactionsc`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }}
//             )
//             if (!response.ok) {
//                 throw new Error(`An error has occured: ${response.status}`);
//             }
//             const data = await response.json();
//             const paidTransactions = data.filter(item => item.transaction_status == "Paid" && item.qid !== '12345678906');
//             // console.log(paidTransactions)
//             setTransactions(paidTransactions);
//             setLoading(false);
//         } catch (error) {
//             console.error(error);
//             setLoading(false);
//         }
//     }

//     useEffect(() => {
//         getTransactions();
//     }, []);

//     const getProducts = async () => {
//         try {
//             setLoading(true);
//             const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetAll_itemc`, {
//                 method: 'GET'
//             })
//             if (!response.ok) {
//                 throw new Error(`An error has occured: ${response.status}`);
//             }
//             const data = await response.json();
//             setProducts(data);
//             setLoading(false);
//         } catch (error) {
//             console.error(error);
//             setLoading(false);
//         }
//     }

//     useEffect(() => {
//         getProducts();
//     }, []);
    
//     useEffect(() => {
//         const shipmentStatusCounts = {
//             orderReceived: 0,
//             preparingItem: 0,
//             delivering: 0,
//             tabletReceived: 0,
//             pickedUp:0
//         };
        
//         transactions.forEach((transaction) => {
//             switch (transaction.shipment_status) {
//                 case 'Order Received':
//                     shipmentStatusCounts.orderReceived++;
//                     break;
//                 case 'Preparing Item':
//                     shipmentStatusCounts.preparingItem++;
//                     break;
//                 case 'Delivering':
//                     shipmentStatusCounts.delivering++;
//                     break;
//                 case 'Tablet Received by Owner':
//                     shipmentStatusCounts.tabletReceived++;
//                     break;
//                 case 'Picked up':
//                     shipmentStatusCounts.pickedUp++;
//                     break;
//                 default:
//                     break;
//             }
//         });
//         setCounts(shipmentStatusCounts);
//     }, [transactions]);

//     useEffect(() => {
//         const currentDate = moment().format('YYYY-MM-DD');
    
//         const orderReceivedCount = transactions.reduce((count, transaction) => {
//         const transactionDate = moment(transaction.transDateTime).format('YYYY-MM-DD');
    
//         if (
//             transaction.shipment_status === 'Order Received' &&
//             transactionDate === currentDate && transaction.transaction_status === 'Paid' && transaction.qid !== '12345678906'
//         ) {
//             return count + 1;
//         }
//         return count;
//         }, 0);

//         setTodaySales(orderReceivedCount);

//     }, [transactions]);

//     useEffect(() => {
//         const currentYear = moment().year();
    
//         const paidTransactions = transactions.filter(transaction =>
//           transaction.transaction_status === 'Paid' && transaction.qid !== '12345678906' &&
//           moment(transaction.transDateTime).year() === currentYear
//         );
    
//         const paidCount = paidTransactions.length;
//         const totalAmount = paidTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
    
//         setPaidTransaction({
//           counts: paidCount,
//           total_amount: totalAmount,
//         });
//     }, [transactions, setPaidTransaction]);

//     const handleDropDownMonthChange = (val, label) => {
//         setFilterKey(val);
//         setIsDropdownMonthOpen(false);
//         setSelectedLabel(label);
//     }

//     // console.log(counts)

//     return (
//         <div className="container-fluid">
//           <div className="row mb-4">
//             {/* Welcome and Total Transactions */}
//             <div className="col-lg-8 mb-4 order-0">
//               <Welcome accountsData={accountsData} counts={todaySales} />
//             </div>
//             <div className="col-lg-4 mb-4">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="d-flex justify-content-between flex-sm-row flex-column gap-3">
//                     <div className="d-flex flex-sm-column flex-row align-items-start justify-content-between">
//                       <div className="card-title">
//                         <h5 className="text-nowrap mb-2">Total Transactions</h5>
//                         <span className="badge bg-label-warning rounded-pill">
//                           Year {moment().format('YYYY')}
//                         </span>
//                       </div>
//                       <div className="mt-sm-auto">
//                         <small className="text-success text-nowrap fw-medium">
//                           <i className="bx bx-chevron-up" /> + {paidTransaction.counts}<i className='bx bx-x'></i>
//                         </small>
//                         <h3 className="mb-0">{currencyFormat(paidTransaction.total_amount)}</h3>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
    
//           {/* Charts */}
//           <div className="row mb-4">
//             <div className="col-lg-8 mb-4">
//               <div className="card h-80">
//                 <div className="card-header d-flex align-items-center justify-content-between">
//                   <div className="card-title mb-0">
//                     <h5 className="m-0 me-2">Transactions statistics</h5>
//                     <small className="text-muted">Total number of transactions for the month of <strong>{selectedLabel || ''}</strong></small>
//                   </div>
//                   <div className="dropdown">
//                     <button
//                       type="button"
//                       className={`btn btn-label-primary dropdown-toggle ${isDropdownMonthOpen ? 'show' : ''}`}
//                       onClick={() => setIsDropdownMonthOpen(!isDropdownMonthOpen)}
//                     >
//                       {selectedLabel || 'Select Month'}
//                     </button>
//                     <ul
//                       className={`dropdown-menu ${isDropdownMonthOpen ? 'show overflow-y-auto' : ''}`}
//                       style={isDropdownMonthOpen ? { position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 40px)' } : {}}
//                     >
//                       {filterMonth.map((month, index) => (
//                         <li key={index}>
//                           <a
//                             className="dropdown-item"
//                             href="#"
//                             onClick={() => handleDropDownMonthChange(month.value, month.label)}
//                           >
//                             {month.label}
//                           </a>
//                         </li>
//                       ))}
//                     </ul>
//                   </div>
//                 </div>
//                 <div className="card-body h-50">
//                   <OrderChart data={transactions} isLoading={loading} filterKey={filterKey} filterMonth={selectedLabel} />
//                 </div>
//               </div>
//             </div>
    
//             {/* Pie Chart and Smaller Cards */}
//             <div className="col-lg-4 mb-4">
//               <div className="row">
//                 {/* Pie Chart */}
//                 <div className="col-12 mb-4">
//                   <div className="card h-100" style={{ height: '50px' }}>
//                     <div className="card-header d-flex align-items-center justify-content-between">
//                       <div className="card-title mb-0">
//                         <h5 className="m-0 me-2">Product Sales Percentage</h5>
//                         <small className="text-muted">Percentage of sales for each product</small>
//                       </div>
//                     </div>
//                     <div className="card-body">
//                       <PieChart data={transactions} isLoading={loading} filterKey={filterKey} filterMonth={selectedLabel} />
//                     </div>
//                   </div>
//                 </div>
    
//         {/* Smaller Cards */}
//          <div className="col-6 mb-4">
//               <div className="card" style={{ height: '125px' }}>
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-2 pb-1">
//                     <div className="avatar me-2">
//                       <span className="avatar-initial rounded bg-label-primary">
//                         <i className='bx bxs-devices'></i>
//                       </span>
//                     </div>
//                     <h4 className="ms-1 mb-0">{products.length}<i className='bx bx-x'></i></h4>
//                   </div>
//                   <p className="mb-1">No. of Products</p>
//                   <p className="mb-0">
//                     <small className="text-muted">&nbsp;</small>
//                   </p>
//                 </div>
//               </div>
//             </div>
    
//             <div className="col-6 mb-4">
//               <div className="card" style={{ height: '125px' }}>
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-2 pb-1">
//                     <div className="avatar me-2">
//                       <span className="avatar-initial rounded bg-label-success">
//                         <i className='bx bx-check-circle'></i>
//                       </span>
//                     </div>
//                     <h4 className="ms-1 mb-0">{counts.tabletReceived + counts.pickedUp}<i className='bx bx-x'></i></h4>
//                   </div>
//                   <p className="mb-1">Completed Transactions</p>
//                   <p className="mb-0">
//                     <small className="text-muted">&nbsp;</small>
//                   </p>
//                 </div>
//               </div>
//             </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
// }

// export default DashboardContainer


import React, {useState, useMemo, useEffect} from 'react'
import { Welcome } from '../Dashboard/Welcome'
import OrderChart from '../Dashboard/OrderChart'
import PieChart from '../Dashboard/PieChart'
import { currencyFormat } from '../utils/utils';
import moment from 'moment';

const DashboardContainer = ({accountsData}) => {

    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [counts, setCounts] = useState({
        orderReceived: 0,
        preparingItem: 0,
        delivering: 0,
        tabletReceived: 0,
        pickedUp: 0,
    });
    const [paidTransaction, setPaidTransaction] = useState({
        counts: 0,
        total_amount: 0,
    })
    const [todaySales, setTodaySales] = useState(0);

    const [filterKey, setFilterKey] = useState(moment().format('MM'));
    const filterMonth = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];

    const [selectedLabel, setSelectedLabel] = useState(moment().format('MMMM'));

    const [isDropdownMonthOpen, setIsDropdownMonthOpen] = useState(false);
    const [products, setProducts] = useState([]);

    const getTransactions = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetCarabia_transactions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }}
            )
            if (!response.ok) {
                throw new Error(`An error has occured: ${response.status}`);
            }
            const data = await response.json();
            const paidTransactions = data.filter(item => item.transaction_status == "Paid" && item.qid !== '12345678906');
            // console.log(paidTransactions)
            setTransactions(paidTransactions);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getTransactions();
    }, []);

    const getProducts = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_ISS_API_URL}/api/issapi/GetAll_itemc`, {
                method: 'GET'
            })
            if (!response.ok) {
                throw new Error(`An error has occured: ${response.status}`);
            }
            const data = await response.json();
            setProducts(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getProducts();
    }, []);
    
    useEffect(() => {
        const shipmentStatusCounts = {
            orderReceived: 0,
            preparingItem: 0,
            delivering: 0,
            tabletReceived: 0,
            pickedUp:0
        };
        
        transactions.forEach((transaction) => {
            switch (transaction.shipment_status) {
                case 'Order Received':
                    shipmentStatusCounts.orderReceived++;
                    break;
                case 'Preparing Item':
                    shipmentStatusCounts.preparingItem++;
                    break;
                case 'Delivering':
                    shipmentStatusCounts.delivering++;
                    break;
                case 'Tablet Received by Owner':
                    shipmentStatusCounts.tabletReceived++;
                    break;
                case 'Picked up':
                    shipmentStatusCounts.pickedUp++;
                    break;
                default:
                    break;
            }
        });
        setCounts(shipmentStatusCounts);
    }, [transactions]);

    useEffect(() => {
        const currentDate = moment().format('YYYY-MM-DD');
    
        const orderReceivedCount = transactions.reduce((count, transaction) => {
        const transactionDate = moment(transaction.transDateTime).format('YYYY-MM-DD');
    
        if (
            transaction.shipment_status === 'Order Received' &&
            transactionDate === currentDate && transaction.transaction_status === 'Paid' && transaction.qid !== '12345678906'
        ) {
            return count + 1;
        }
        return count;
        }, 0);

        setTodaySales(orderReceivedCount);

    }, [transactions]);

    useEffect(() => {
        const currentYear = moment().year();
    
        const paidTransactions = transactions.filter(transaction =>
          transaction.transaction_status === 'Paid' && transaction.qid !== '12345678906' &&
          moment(transaction.transDateTime).year() === currentYear
        );
    
        const paidCount = paidTransactions.length;
        const totalAmount = paidTransactions.reduce((acc, transaction) => acc + transaction.total_amount, 0);
    
        setPaidTransaction({
          counts: paidCount,
          total_amount: totalAmount,
        });
    }, [transactions, setPaidTransaction]);

    const handleDropDownMonthChange = (val, label) => {
        setFilterKey(val);
        setIsDropdownMonthOpen(false);
        setSelectedLabel(label);
    }

    // console.log(counts)

    return (
        <div className="container-fluid">
          <div className="row mb-4">
            {/* Welcome and Total Transactions */}
            <div className="col-lg-8 mb-4 order-0">
              <Welcome accountsData={accountsData} counts={todaySales} />
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between flex-sm-row flex-column gap-3">
                    <div className="d-flex flex-sm-column flex-row align-items-start justify-content-between">
                      <div className="card-title">
                        <h5 className="text-nowrap mb-2">Total Transactions</h5>
                        <span className="badge bg-label-warning rounded-pill">
                          Year {moment().format('YYYY')}
                        </span>
                      </div>
                      <div className="mt-sm-auto">
                        <small className="text-success text-nowrap fw-medium">
                          <i className="bx bx-chevron-up" /> + {paidTransaction.counts}<i className='bx bx-x'></i>
                        </small>
                        <h3 className="mb-0">{currencyFormat(paidTransaction.total_amount)}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          {/* Charts */}
          <div className="row mb-4">
            <div className="col-lg-8 mb-4">
              <div className="card h-80">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <div className="card-title mb-0">
                    <h5 className="m-0 me-2">Transactions statistics</h5>
                    <small className="text-muted">Total number of transactions for the month of <strong>{selectedLabel || ''}</strong></small>
                  </div>
                  <div className="dropdown">
                    <button
                      type="button"
                      className={`btn btn-label-primary dropdown-toggle ${isDropdownMonthOpen ? 'show' : ''}`}
                      onClick={() => setIsDropdownMonthOpen(!isDropdownMonthOpen)}
                    >
                      {selectedLabel || 'Select Month'}
                    </button>
                    <ul
                      className={`dropdown-menu ${isDropdownMonthOpen ? 'show overflow-y-auto' : ''}`}
                      style={isDropdownMonthOpen ? { position: 'absolute', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(0px, 40px)' } : {}}
                    >
                      {filterMonth.map((month, index) => (
                        <li key={index}>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => handleDropDownMonthChange(month.value, month.label)}
                          >
                            {month.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="card-body h-50">
                  <OrderChart data={transactions} isLoading={loading} filterKey={filterKey} filterMonth={selectedLabel} />
                </div>
              </div>
            </div>
    
            {/* Pie Chart and Smaller Cards */}
            <div className="col-lg-4 mb-4">
              <div className="row">
                {/* Pie Chart */}
                <div className="col-12 mb-4">
                  <div className="card h-100" style={{ height: '50px' }}>
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="card-title mb-0">
                        <h5 className="m-0 me-2">Product Sales Percentage</h5>
                        <small className="text-muted">Percentage of sales for each product</small>
                      </div>
                    </div>
                    <div className="card-body">
                      <PieChart data={transactions} isLoading={loading} filterKey={filterKey} filterMonth={selectedLabel} />
                    </div>
                  </div>
                </div>
    
        {/* Smaller Cards */}
         <div className="col-6 mb-4">
              <div className="card" style={{ height: '125px' }}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2 pb-1">
                    <div className="avatar me-2">
                      <span className="avatar-initial rounded bg-label-primary">
                        <i className='bx bxs-devices'></i>
                      </span>
                    </div>
                    <h4 className="ms-1 mb-0">{products.length}<i className='bx bx-x'></i></h4>
                  </div>
                  <p className="mb-1">No. of Products</p>
                  <p className="mb-0">
                    <small className="text-muted">&nbsp;</small>
                  </p>
                </div>
              </div>
            </div>
    
            <div className="col-6 mb-4">
              <div className="card" style={{ height: '125px' }}>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-2 pb-1">
                    <div className="avatar me-2">
                      <span className="avatar-initial rounded bg-label-success">
                        <i className='bx bx-check-circle'></i>
                      </span>
                    </div>
                    <h4 className="ms-1 mb-0">{counts.tabletReceived + counts.pickedUp}<i className='bx bx-x'></i></h4>
                  </div>
                  <p className="mb-1">Completed Transactions</p>
                  <p className="mb-0">
                    <small className="text-muted">&nbsp;</small>
                  </p>
                </div>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default DashboardContainer
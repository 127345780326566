import React from 'react';
import DataTable from 'react-data-table-component';
import Spinner from './Spinner';

const Table = ({ cols, data, classN, subHeader, subHeaderComponent, customRow, isLoading }) => {
    return (
        <div>
            <DataTable
                columns={cols.map(col => ({...col, sortable: true}))} // Enable sorting for all columns
                data={data}
                className={classN}
                pagination
                subHeader={subHeader}
                subHeaderComponent={subHeaderComponent}
                highlightOnHover
                onRowClicked={customRow}
                progressComponent={isLoading ? <Spinner /> : null}
            />
        </div>
    );
};

export default Table;
import React from 'react';
import translations from '../translations/translations.json';
import '../Product.css';

const Faqs = (props) => {
  const { ar: arTranslations, en: enTranslations } = translations;
  const { language, handleLanguageChange } = props;
  return (
    <section className="section-py">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 text-center">
            <h3>{language === 'en' ? enTranslations['Need assistance for your purchase?'] : arTranslations['Need assistance for your purchase?']}</h3>
            <p className="mb-3">
            {language === 'en' ? enTranslations['Our specialists are always happy to help.'] : arTranslations['Our specialists are always happy to help.']}<br />{language === 'en' ? enTranslations[`Contact us during standard business hours or email us 24/7 and we'll get back to you.`] : arTranslations[`Contact us during standard business hours or email us 24/7 and we'll get back to you.`]}
            </p>
            <div className="d-flex justify-content-center flex-wrap gap-3">
              <a href="https://maps.app.goo.gl/Fq2dJYwzF18avwdM8" className="btn btn-danger" target='_blank' rel="noreferrer"> {language === 'en' ? enTranslations['Visit our Office'] : arTranslations['Visit our Office']}</a>
                <a 
                  className="btn btn-danger custom-orange-btn" 
                  href="tel:+97440120826"
                >
                  {language === 'en' ? enTranslations['Call Us Now'] : arTranslations['Call Us Now']}
                </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
